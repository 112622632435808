import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { PSCInspection } from "../../types/psc";

export const PSCReleasedBody = (item: PSCInspection) => {
    const { t } = useTranslation("locale");
    
    if (item.detained === "Y" && !item.released){
        return null
    }

    if (item.detained === "N"){
        return <p className="psc-not-applicable-label">{t("psc.notApplicable")}</p>
    }

    const date = dayjs(Number(item.released) * 1000).format("DD/MM/YYYY");

    return <p>{date}</p>;
}