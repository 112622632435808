import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectPSCInspectionData } from "../../store/selectors/pscSelector";
import { uniq } from "lodash";

export const PSCMOUFilter = (options: ColumnFilterElementTemplateOptions) => {
    const inspectionData = useAppSelector(selectPSCInspectionData);

    const mouOptions = useMemo(() => {
        const mous: string[] = [];

        inspectionData.forEach((inspection) => {
            if (inspection.mou) {
                mous.push(inspection.mou)
            }
        })

        return uniq(mous).map((mou) => ({ label: mou, value: mou}));
    }, [inspectionData]);

    const onChange = useCallback((e: MultiSelectChangeEvent) => {
        options.filterApplyCallback(e.value)
    }, [options])

    return (
        <MultiSelect
            value={options.value}
            options={mouOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};

