import { PlotData } from "plotly.js";
import { DefectStatus } from "../types/DefectStatus";
import { DefectDataItem } from "../types/DefectsDataItem";
import {
    addCustomFieldDefectsFiltersToData,
    getGradingAreaFilterValues,
} from "../utils/DefectsTableDataHelper";
import { groupBy, sum } from "lodash";
import {SubgradeGraphFilterType} from "../components/GraphFilterSelector/GraphFilterSelector";
import {DefectPriority} from "../types/DefectPriority";

const defectGraphColours = ["#EF5A14", "#E5940E", "#F8D311"];
const defectGraphNames = ["High", "Medium", "Low"];

export const getDefectsGraphData = (
    defectDataItems: DefectDataItem[],
    selectedVessel?: string,
    defectGraphContextFilter?: number
) => {
    const filterDefects = (additionalFilter?: (defect: DefectDataItem) => boolean) =>
        defectDataItems.filter(
            (defect) =>
                defect.status !== DefectStatus.closed &&
                (!additionalFilter || additionalFilter(defect))
        );

    const getDefectsMaxValue = (defectsByArea: Record<string, number>[]) =>
        Math.max(...defectsByArea.map((area) => sum(Object.values(area))));

    let defects = filterDefects(
        selectedVessel ? (defect) => defect.vesselName === selectedVessel : undefined
    );

    if (defectGraphContextFilter) {
        const area = SubgradeGraphFilterType[defectGraphContextFilter];
        defects = filterDefects(
            selectedVessel
                ? (defect) => defect.vesselName === selectedVessel
                : (defect) => defect.gradingAreaField === area
        );

        const areaLabel = "<span style=\"font-weight: bold\">" + area + "</span>"
        let defectFilterValues = [areaLabel];
        const technicalManagersDefectList = {
            [DefectPriority.high]: {},
            [DefectPriority.medium]: {},
            [DefectPriority.low]: {},
        } as Record<DefectPriority, Record<string, number>>;

        technicalManagersDefectList[DefectPriority.high][areaLabel] = 0;
        technicalManagersDefectList[DefectPriority.medium][areaLabel] = 0;
        technicalManagersDefectList[DefectPriority.low][areaLabel] = 0;

        defects.forEach((defect) => {
            if (defect.priority in technicalManagersDefectList) {
                const priorityList = technicalManagersDefectList[defect.priority];
                defectFilterValues.push(defect.technicalManager);
                priorityList[defect.technicalManager] = (priorityList[defect.technicalManager] || 0) + 1;
                technicalManagersDefectList[defect.priority][areaLabel] = (technicalManagersDefectList[defect.priority][areaLabel] || 0) + 1;
            }
        });

        defectFilterValues = defectFilterValues.reverse();
        const defectsByGradingAreaAndPriority = Object.values(technicalManagersDefectList);
        const defectsGraphData = mapDefectsToPlotData(defectsByGradingAreaAndPriority);
        const defectsMaxValue = getDefectsMaxValue(defectsByGradingAreaAndPriority);

        if(technicalManagersDefectList[DefectPriority.low][areaLabel] == 0 &&
            technicalManagersDefectList[DefectPriority.medium][areaLabel] == 0 &&
            technicalManagersDefectList[DefectPriority.high][areaLabel] == 0) {
            return {
                defectFilterValues: [],
                defectsGraphData: [],
                defectsMaxValue: 0,
            };
        }



        return {
            defectFilterValues,
            defectsGraphData,
            defectsMaxValue,
        };
    }

    const defectFilterValues = getGradingAreaFilterValues(defects).reverse();
    const defectWithGradingAreas = addCustomFieldDefectsFiltersToData(defects);
    const defectsByGradingAreaAndPriority = getDefectsByGradingAreaAndPriority(defectWithGradingAreas, defectFilterValues);
    const defectsGraphData = mapDefectsToPlotData(defectsByGradingAreaAndPriority);
    const defectsMaxValue = getDefectsMaxValue(defectsByGradingAreaAndPriority);

    return {
        defectFilterValues,
        defectsGraphData,
        defectsMaxValue,
    };
};


const getDefectsByGradingArea = (defectDataItems: DefectDataItem[], defectFilterValues: string[]) => {
    const defectsByGradingArea: Record<string, number> = {};

    defectDataItems.forEach((defect) => {
        const gradingAreas = defect.gradingAreaField?.split(", ");

        gradingAreas?.forEach((value) =>{
            if (defectFilterValues.includes(value)) {
                if (defectsByGradingArea[value]) {
                    defectsByGradingArea[value]++;
                } else {
                    defectsByGradingArea[value] = 1;
                }
            }
        })
    });

    return defectsByGradingArea;
}

const getDefectsByGradingAreaAndPriority = (defectDataItems: DefectDataItem[], gradingAreaOrder: string[]) => {
    const defectsByPriority = groupBy(defectDataItems, "priority");
    const sortedPriorities = Object.keys(defectsByPriority).sort((a, b) => Number(a) - Number(b));

    const defectByGradingAreaAndPriority = sortedPriorities.map((defectPriority) =>
        getDefectsByGradingArea(defectsByPriority[defectPriority], gradingAreaOrder));

    return defectByGradingAreaAndPriority;
}

const mapDefectsToPlotData = (defectsByGradingAreaAndPriority: Record<string, number>[]) => {
    const defectsGraphData: Partial<PlotData>[] = defectsByGradingAreaAndPriority.map((defectArea, index) => ({
        type: "bar",
        x: Object.values(defectArea),
        y: Object.keys(defectArea),
        orientation: "h",
        marker: {
            color: defectGraphColours[index],
        },
        name: defectGraphNames[index]
    }));

    return defectsGraphData;
}