import { useTranslation } from "react-i18next";
import { DefectsTable } from "../components/DefectsTable/DefectsTable";
import { TabPanel, TabView, TabViewTabChangeEvent } from "primereact/tabview";
import { DefectsGraph } from "../components/DefectsGraph/DefectsGraph";
import { errorToast } from "@idwal/idwal-react-components";
import { ANALYTICS_TAB_CLICK, DEFECTS_FLEET_DEFECTS_BY_GRADING_AREA_CLICK, DEFECTS_INSIGHTS, DEFECTS_INSIGHTS_TAB, DEFECTS_SUMMARY, DEFECTS_SUMMARY_TAB } from "../constants/analytics";
import { sendGAEvent } from "../AnalyticsService";
import { DefectsSummary } from "../components/DefectsSummary/DefectsSummary";
import { DefectTabs } from "../types/DefectTabs";
import { DefectInsights } from "../components/DefectInsights/DefectInsights";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { selectActiveTab, selectDefect } from "../store/selectors/defectSelectors";
import { setActiveTab, setSelectedVessel } from "../store/slices/defectSlice";
import { selectGlobalFiltersLoading } from "../store/selectors/filtersSelectors";

const Defects = () => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector(selectDefect);
    const activeTab = useAppSelector(selectActiveTab);

    const globalFilterLoading = useAppSelector(selectGlobalFiltersLoading);

    if (error) {
        errorToast(t("defectsTable.apiFail"));
        return <div />;
    };

    const onTabChange = (e: TabViewTabChangeEvent) => {
        // Trigger window resize event to invoke plotly resize handler on tab change
        window.dispatchEvent(new Event("resize"));
        dispatch(setActiveTab(e.index));

        if (activeTab !== e.index) {
            dispatch(setSelectedVessel(""));
        }

        switch (e.index) {
            case DefectTabs.summary:
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_TAB);
                break;
            case DefectTabs.gradingArea:
                sendGAEvent(ANALYTICS_TAB_CLICK, DEFECTS_FLEET_DEFECTS_BY_GRADING_AREA_CLICK);
                break;
            case DefectTabs.insights:
                sendGAEvent(DEFECTS_INSIGHTS, DEFECTS_INSIGHTS_TAB);
                break;
            default:
                break;
        }
    };

    const isLoading = loading || globalFilterLoading;
    
    return (
        <TabView renderActiveOnly activeIndex={activeTab} onTabChange={onTabChange} className={"tab-container"}>
            <TabPanel header={t("defectsSummary.defectsSummary")}>
                <DefectsSummary isLoading={isLoading} />
            </TabPanel>
            <TabPanel header={t("defectsTable.defectsOverview")}>
                <DefectsTable isLoading={isLoading} />
            </TabPanel>
            <TabPanel header={t("defectsGraph.gradingArea")}>
                <DefectsGraph isLoading={isLoading} />
            </TabPanel>
            <TabPanel header={t("defectsGraph.insights")}>
                <DefectInsights />
            </TabPanel>
        </TabView>
    ) 
};

export default Defects;
