import { useEffect, useState } from "react";
import { ApplicationWrapper, buildNavigationMenus } from "@idwal/idwal-react-components";
import { initializeAnalytics, sendGAEvent } from "./AnalyticsService";
import { Slide, ToastContainer } from "react-toastify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { initHotJar } from "./utils/HotJar";
import { FleetFilter } from "./components/Filter/FleetFilter";
import { MainRoutes } from "./components/Routes/MainRoutes";
import { AUTH_TIMEOUT, AUTH_URL_PROTOCOL } from "./cognito/authConfig";
import { FilterDialogStateAdapter as FilterDialogModal } from "./componentAdapters/FilterDialogAdapter";
import { useCookies } from "react-cookie";
import { COOKIE_CONSENT } from "@idwal/idwal-utilities/lib/constants/cookies";
import { cookiePreferences } from "@idwal/idwal-utilities";
import { useCapabilities } from "./hooks/useCapabilities";
import { Capability } from "./types/Capability";
import "./App.css";
import {useAppDispatch, useAppSelector} from "./hooks/storeHooks";
import { initFetchData, initFetchTokenData } from "./store/thunks/initThunks";
import { useLocation, useNavigate } from "react-router-dom";
import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import { ANALYTICS_TAB_CLICK, DEFECTS_CLICK, GRADE_CLICK, HOME_CLICK, PSC_CLICK, SCREENING_CLICK, SUB_GRADE_CLICK } from "./constants/analytics";
import { Notifications } from "./utils/Notifications/Notifications";
import { PageHeaderWrapper } from "./components/PageHeaderWrapper/PageHeaderWrapper";
import { VesselViewAdapter } from "./componentAdapters/VesselViewAdapter";
import { RequestInspectionDialog } from "./components/Shared/RequestInspectionDialog";
import { getUserSessionStorageItem } from "./utils/UserSessionStorage";
import { setShowStatsTilesPanel } from "./store/slices/fleetSlice";
import {selectHasFleetAccess} from "./store/selectors/userSelectors";

let hotjarInitialised = false;

function App() {
    const { user } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator();
    const dispatch = useAppDispatch();
    const hasFleetAccess = useAppSelector(selectHasFleetAccess);

    const {
        showInspectionsMyReports,
        showInspectionsQuotes,
        showMonitoringDefects,
        showMonitoringGrades,
        showInspectionsOrders,
        showMonitoringMyVessels,
        showMonitoringSubGrades,
        showMonitoringPortStateControl,
        showScreeningMyPortfolio,
        showScreeningVetting
    } = useFlags();

    const ldClient = useLDClient();

    const [cookies, setCookie] = useCookies([COOKIE_CONSENT]);
    const [idwalSideBarOpen, setIdwalSideBarOpen] = useState(false);
    const cookieUtils = cookiePreferences(setCookie, cookies);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_DEFECTS,
        Capability.CAP_FLEETAPP_VIEW_FLEETS,
        Capability.CAP_FLEETAPP_VIEW_GRADES,
        Capability.CAP_FLEETAPP_VIEW_SUBGRADES,
        Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS,
        Capability.CAP_FLEETAPP_NOTIFICATIONS,
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING,
    ]);

    const redirectToSSO = () => {
        //resetSessionStorage();
        window.location.assign(
            `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`
        );
    };

    const location = useLocation();  

    // If we don't get authenticated within a specific amount of time then send
    // the user out to the SSO login screen.
    useEffect(() => {
        const timer = setTimeout(() => {
            if (authStatus !== "authenticated") {
                redirectToSSO();
            }
        }, AUTH_TIMEOUT);
        return () => clearTimeout(timer);
    }, [authStatus, user]);

    useEffect(() => {
        if (user && ldClient) {
            initializeAnalytics(
                {
                    timestamp: new Date(),
                },
                user.username,
                user.attributes?.email
            );

            if (
                !hotjarInitialised &&
                user &&
                (cookies[COOKIE_CONSENT]?.all === true || cookies[COOKIE_CONSENT]?.analytics === true)
            ) {
                console.info("Initialising Hotjar");
                hotjarInitialised = initHotJar(user);
            }

            const localStorageToggleStatsTiles = 
                user.username ? getUserSessionStorageItem(user.attributes!["name"], "toggleStatsTiles") : "";
            dispatch(setShowStatsTilesPanel(
                localStorageToggleStatsTiles ? localStorageToggleStatsTiles === "true" : true)
            );
            
            dispatch(initFetchData(user));
            dispatch(initFetchTokenData());
        }
    }, [user, ldClient]);

    const navigate = useNavigate();

    const {
        loading,
        mappedNotifications,
        markAllNotificationsAsRead
    } = Notifications()

    const routePath = '/' + location.pathname.split('/')[1];

    let { appSidebarItems, idwalSidebarIdItems, idwalSidebarOtherItems, idwalSidebarScreeningItems } = buildNavigationMenus(
        capabilities,
        {
            links: {
                myVesselsLink: "/",
                defectsLink: "/defects",
                gradesLink: "/grade",
                subgradesLink: "/subgrades",
                rectificationsLink: "/rectifications",
                pscLink: "/psc",
                screeningLink: "/screening",
            },
            flags: {
                showMonitoringDefects,
                showMonitoringGrades,
                showMonitoringMyVessels,
                showMonitoringSubGrades,
                showMonitoringPortStateControl,
                showScreeningMyPortfolio,
                showScreeningVetting
            },
            callbacks: {
                clickMonitoringMyVessels: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, HOME_CLICK);
                    navigate(to);
                },
                clickMonitoringDefects: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, DEFECTS_CLICK);
                    navigate(to);
                },
                clickMonitoringGrades: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, GRADE_CLICK);
                    navigate(to);
                },
                clickMonitoringSubGrades: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, SUB_GRADE_CLICK);
                    navigate(to);
                },
                clickMonitoringRetifications: (to: string) => {
                    setIdwalSideBarOpen(false);
                    navigate(to);
                },
                clickMonitoringPSC: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, PSC_CLICK);
                    navigate(to);
                },
                clickMonitoringScreening: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, SCREENING_CLICK);
                    navigate(to);
                },
            }
        },
        {
            links: {
                myReportsLink: `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/inspections`,
                inspectionOrdersLink: `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/orders`,
                inspectionQuotesLink: `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/quotations`
            },
            flags: {
                showInspectionsMyReports,
                showOrders: showInspectionsOrders,
                showInspectionsQuotes
            },
            callbacks: {
                clickInspectionsMyReports: (to: string) => {
                    window.location.replace(to);
                },
                clickInspectionsOrders: (to: string) => {
                    window.location.replace(to);
                },
                clickInspectionsQuotes: (to: string) => {
                    window.location.replace(to);
                },
            }
        },
        {
            links: {
                myPortfolioLink: "/screening",
                screeningLink: "/screening-placeholder"
            },
            flags: {
                showScreeningMyPortfolio,
                showScreeningVetting
            },
            callbacks: {
                clickScreeningMyPortfolio: (to: string) => {
                    setIdwalSideBarOpen(false);
                    sendGAEvent(ANALYTICS_TAB_CLICK, SCREENING_CLICK);
                    navigate(to);
                },
                clickScreeningVetting: () => {}
            }
        },
        routePath
    );

    const isTechManager = Object.values(capabilities).filter(capability => capability).length < 3 && capabilities.CAP_FLEETAPP_VIEW_RECTIFICATIONS && capabilities.CAP_FLEETAPP_NOTIFICATIONS;

    if (isTechManager) {
        appSidebarItems = [];
        idwalSidebarOtherItems = [];
        idwalSidebarIdItems = [];
    }

    return (
        <ApplicationWrapper 
            showNotifications={
                Boolean(capabilities[Capability.CAP_FLEETAPP_NOTIFICATIONS])
            }
            loadingNotifications={loading}
            mappedNotifications={mappedNotifications}
            burgerMenuLogo={"main"}
            logoutLink={`${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}/logout?redirect=${window.location.href}`}
            idwalSidebarIdItems={idwalSidebarIdItems}
            idwalSidebarOtherItems={idwalSidebarOtherItems}
            idwalSidebarScreeningItems={idwalSidebarScreeningItems}
            cookieUtils={cookieUtils}
            appSidebarItems={appSidebarItems}
            activeAppSidebarItem={routePath}
            markAllNotificationsAsRead={() => markAllNotificationsAsRead(mappedNotifications)} 
            idwalSidebarOpen={idwalSideBarOpen} 
            setIdwalSideBarOpen={(open: boolean) => setIdwalSideBarOpen(open)}            
        >
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
                <VesselViewAdapter/>
                <FilterDialogModal />
                <RequestInspectionDialog />
                {isTechManager ? <></> : <PageHeaderWrapper />}
                {hasFleetAccess ? <FleetFilter/> : <></>}
                <MainRoutes/>
            </>
        </ApplicationWrapper>
    );
}

export default App;
