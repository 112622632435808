import { RootState } from "../store";

export const selectFleet = (state: RootState) => state.fleet;

export const selectFleetData = (state: RootState) => state.fleet.data;

export const selectFleetDataItems = (state: RootState) => state.fleet.fleetDataItems;

export const selectFleetLoading = (state: RootState) => state.fleet.loading;

export const selectFleetError = (state: RootState) => state.fleet.error;

export const selectInspectionAndGradeData = (state: RootState) => state.fleet.inspectionAndGradeData;

export const selectInternalGradeData = (state: RootState) => state.fleet.internalGradeData;

export const selectInspectionAndGradeDataLoading = (state: RootState) => state.fleet.inspectionAndGradeDataLoading;

export const selectRequestInspectionDialog = (state: RootState) => state.fleet.requestInspectionDialog;

export const selectStatTileConfigs = (state: RootState) => state.fleet.statTileConfigs;

export const selectShowStatsTilesPanel = (state: RootState) => state.fleet.showStatsTilesPanel;
