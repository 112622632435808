import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectScreening } from "../../store/selectors/screeningSelectors";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { setFilters, setPage } from "../../store/slices/screeningSlice";
import { FilterMatchMode } from "primereact/api";
import { screeningColumns, screeningGlobalFilterFields } from "../../utils/ScreeningTableHelper";
import { ScreeningTableHeader } from "./ScreeningTableHeader";
import "./ScreeningTable.css";

export const ScreeningTable = () => {
    const dispatch = useAppDispatch();
    
    const {
        data,
        loading,
        globalFilterValue,
        page,
        filters,
    } = useAppSelector(selectScreening);

    const onPage = useCallback((event: DataTableStateEvent) => {
        dispatch(setPage({ row: event.rows, first: event.first}))
    }, []);

    const onFilter = useCallback((event: DataTableStateEvent) => {
        dispatch(setFilters(event.filters));
    }, []);

    const renderHeader = useMemo(() => (
        <ScreeningTableHeader />
    ), [globalFilterValue]);

    const columns = useMemo(() => (
        screeningColumns()
    ), []);

    const rowOptions = useMemo(() => 
        [5, 10, 25, 50]
    , []);

    const globalFilterFields = useMemo(() => 
        screeningGlobalFilterFields
    , []);
    
    return (
        <DataTable 
            value={data}
            loading={loading}
            globalFilter={globalFilterValue}
            globalFilterFields={globalFilterFields}
            globalFilterMatchMode={FilterMatchMode.CONTAINS}
            data-cy="screening-table"
            className="screening-table"
            scrollHeight="55vh"
            filterDisplay="row"
            filters={filters}
            onFilter={onFilter}
            paginator
            scrollable
            removableSort
            stripedRows
            first={page.first}
            rows={page.row}
            rowsPerPageOptions={rowOptions}
            onPage={onPage}
            header={renderHeader}
        >
            {columns}
        </DataTable>
    )
};
