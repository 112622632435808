import { sendGAEvent } from "../../AnalyticsService";
import { ADVANCED_FILTER_APPLY, ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_MARKET_DATA_TOGGLE_CLICK } from "../../constants/analytics";
import { BACKGROUND_FLEET_DATA, BACKGROUND_MARKET_DATA } from "../../constants/backgroundFilterIndex";
import { getTranslationObject } from "../../services/Translation";
import { AdvancedFilterSelectionState, BackgroundFilters } from "../../types/AdvancedFilters";
import { InspectionAndGradeDataResponse } from "../../types/InspectionAndGradeDataResponse";
import { MarketDataFilteringOptions } from "../../types/MarketDataFilteringOptions";
import { arrayIntersection, getAdvancedFilterCount, getBackgroundFilterIndex, getDefaultFleetDwtState, getDefaultFleetInspectionDateState, getDefaultFleetTeuState, getDefaultMarketDwtState, getDefaultMarketTeuState, getDwtValues, getFleetDwtRange, getFleetTeuRange, getTeuValues, hasBackgroundFilters } from "../../utils/advancedFilter/helpers";
import { defaultBackgroundState, defaultForegroundState, parseStoredState, storeAppliedState } from "../../utils/AdvancedFilterHelper";
import { selectAdvancedFilters, selectFitlerState } from "../selectors/advancedFiltersSelectors";
import { selectUserDetails } from "../selectors/userSelectors";
import { initialState, setAppliedFilters, setFilterState, setLoading, setOpen, setSelectedSection, setShowWarningMessage, setTotalBackgroundFilterCount, setTotalForegroundFilterCount } from "../slices/advancedFiltersSlice";
import { AppThunk } from "../store";
import { getUserSessionStorageItem } from "../../utils/UserSessionStorage";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { cloneDeep } from "lodash";
import { fetchFleetGraph, fetchSubgradeBackingData } from "./fleetGraphThunks";
import { selectFleetGraph } from "../selectors/fleetGraphSelectors";
import { selectFleet } from "../selectors/fleetSelectors";

const defaultDwtMinValue = 1000;
const defaultTeuMinValue = 0;
const defaultMaxTeuValue = 4000;

export const clearForShowAllModal = (): AppThunk => async (dispatch, getState) => {
    const userDetails = selectUserDetails(getState());
    
    dispatch(setAppliedFilters(initialState.filterState));
    dispatch(setFilterState(initialState.filterState));
    dispatch(setTotalForegroundFilterCount(0));
    dispatch(setTotalBackgroundFilterCount(0));

    storeAppliedState(userDetails?.userName, initialState.filterState);
};

const updateCounts = (newState: AdvancedFilterSelectionState): AppThunk => async (dispatch) => {
    const filterIndex = getBackgroundFilterIndex(newState.marketDataToggleSelected);
    const totalForegroundCount = getAdvancedFilterCount(newState.foreground);
    const totalBackgroundCount = getAdvancedFilterCount(newState.background[filterIndex]);

    dispatch(setTotalForegroundFilterCount(totalForegroundCount));
    dispatch(setTotalBackgroundFilterCount(totalBackgroundCount));
}

const applyNewState = (newState: AdvancedFilterSelectionState): AppThunk => async (dispatch, getState) => {
    const userDetails = selectUserDetails(getState());

    dispatch(updateCounts(newState));
    dispatch(setFilterState(newState));
    dispatch(setAppliedFilters(newState));

    storeAppliedState(userDetails?.userName, newState);
}

export const updateAppliedVesselAges = (vesselAges: string[]): AppThunk => async (dispatch, getState) => {
    const { filterState, appliedFilters } = selectAdvancedFilters(getState());

    const newState = {
        ...filterState,
        foreground: {
            ...appliedFilters.foreground,
            vesselAge: {
                ...appliedFilters.foreground.vesselAge,
                data: vesselAges
            }
        },
    };

    dispatch(applyNewState(newState));
}

export const updateAppliedVessels = (vessels: string[]): AppThunk => async (dispatch, getState) => {
    const { appliedFilters } = selectAdvancedFilters(getState());

    const newState = {
        ...appliedFilters,
        foreground: {
            ...appliedFilters.foreground,
            selectedVessels: {
                ...appliedFilters.foreground.selectedVessels,
                data: vessels
            }
        },
    };

    dispatch(applyNewState(newState));
}

export const updateAppliedTechnicalManagers = (technicalManagers: string[]): AppThunk => async (dispatch, getState) => {
    const { appliedFilters } = selectAdvancedFilters(getState());

    const newState = {
        ...appliedFilters,
        foreground: {
            ...appliedFilters.foreground,
            technicalManager: {
                ...appliedFilters.foreground.technicalManager,
                data: technicalManagers
            }
        },
    };

    dispatch(applyNewState(newState));
}

export const updateAppliedVesselTypes = (vesselTypes: string[]): AppThunk => async (dispatch, getState) => {
    const { appliedFilters } = selectAdvancedFilters(getState());

    const newState = {
        ...appliedFilters,
        foreground: {
            ...appliedFilters.foreground,
            vesselType: {
                ...appliedFilters.foreground.vesselType,
                data: vesselTypes
            }
        },
    };

    dispatch(applyNewState(newState));
}

export const setupAdvancedFilters = (
    inspectionAndGradeData: InspectionAndGradeDataResponse | undefined,
    marketDataFilteringOptions: MarketDataFilteringOptions | undefined,
): AppThunk => async (dispatch, getState) => {
    const filterState = selectFitlerState(getState());
    const t = getTranslationObject();

    if (inspectionAndGradeData && marketDataFilteringOptions) {
        dispatch(setSelectedSection(t("fleetGraph.vessels")));

        const newForegroundState = {
            ...defaultForegroundState,
            dwt: getDefaultFleetDwtState(
                filterState.foreground.dwt,
                inspectionAndGradeData,
                defaultDwtMinValue
            ),
            teu: getDefaultFleetTeuState(
                filterState.foreground.teu,
                inspectionAndGradeData,
                defaultTeuMinValue,
                getFleetTeuRange(inspectionAndGradeData, defaultTeuMinValue, defaultMaxTeuValue)[1],
            ),
            inspectionDateRange: getDefaultFleetInspectionDateState(filterState.foreground.inspectionDateRange, t)
        };

        const newBackgroundState: [BackgroundFilters, BackgroundFilters] = [
            {
                ...defaultBackgroundState,
                dwt: getDefaultFleetDwtState(
                    filterState.background[BACKGROUND_FLEET_DATA].dwt,
                    inspectionAndGradeData,
                    defaultDwtMinValue,
                ),
                teu: getDefaultFleetTeuState(
                    filterState.background[BACKGROUND_FLEET_DATA].teu,
                    inspectionAndGradeData,
                    defaultTeuMinValue,
                    getFleetTeuRange(inspectionAndGradeData, defaultTeuMinValue, defaultMaxTeuValue)[1],
                )
            },
            {
                ...defaultBackgroundState,
                dwt: getDefaultMarketDwtState(
                    filterState.background[BACKGROUND_MARKET_DATA].dwt,
                    inspectionAndGradeData,
                    defaultDwtMinValue,
                    getFleetDwtRange(inspectionAndGradeData, defaultDwtMinValue)[1],
                    marketDataFilteringOptions,
                ),
                teu: getDefaultMarketTeuState(
                    filterState.background[BACKGROUND_MARKET_DATA].teu,
                    defaultTeuMinValue,
                    defaultMaxTeuValue,
                    marketDataFilteringOptions,
                )
            }
        ];
        dispatch(setFilterState({
            ...filterState,
            foreground: newForegroundState,
            background: newBackgroundState,
        }))
    }
}

export const toggleOpenState = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { inspectionAndGradeData, marketDataFilteringOptions } = selectFleet(state);
    const { appliedFilters, filterState, open } = selectAdvancedFilters(state);
    const t = getTranslationObject();

    const toggleState = {
        foreground: {
            ...appliedFilters?.foreground,
            dwt: appliedFilters?.foreground.dwt.changed 
                ? appliedFilters?.foreground.dwt
                : getDefaultFleetDwtState(
                    filterState.foreground.dwt,
                    inspectionAndGradeData,
                    defaultDwtMinValue
                ),
            teu: appliedFilters?.foreground.teu.changed
                ? appliedFilters?.foreground.teu
                : getDefaultFleetTeuState(
                    filterState.foreground.teu,
                    inspectionAndGradeData,
                    defaultTeuMinValue,
                    getFleetTeuRange(inspectionAndGradeData, defaultTeuMinValue, defaultMaxTeuValue)[1],
                ),
            inspectionDateRange: appliedFilters?.foreground.inspectionDateRange.data.length > 0
                ? appliedFilters?.foreground.inspectionDateRange
                : getDefaultFleetInspectionDateState(filterState.foreground.inspectionDateRange, t)
        },
        background: [
            {
                ...filterState.background[BACKGROUND_FLEET_DATA],
                dwt: appliedFilters?.background[BACKGROUND_FLEET_DATA].dwt.changed 
                    ? appliedFilters?.background[BACKGROUND_FLEET_DATA].dwt
                    : getDefaultFleetDwtState(
                        filterState.background[BACKGROUND_FLEET_DATA].dwt,
                        inspectionAndGradeData,
                        defaultDwtMinValue
                    ),
                teu: appliedFilters?.background[BACKGROUND_FLEET_DATA].teu.changed
                    ? appliedFilters?.background[BACKGROUND_FLEET_DATA].teu
                    : getDefaultFleetTeuState(
                        filterState.background[BACKGROUND_FLEET_DATA].teu,
                        inspectionAndGradeData,
                        defaultTeuMinValue,
                        getFleetTeuRange(inspectionAndGradeData, defaultTeuMinValue, defaultMaxTeuValue)[1],
                    )
            },
            {
                ...filterState.background[BACKGROUND_MARKET_DATA],
                dwt: appliedFilters?.background[BACKGROUND_MARKET_DATA].dwt.changed 
                    ? appliedFilters?.background[BACKGROUND_MARKET_DATA].dwt
                    : getDefaultMarketDwtState(
                        filterState.background[BACKGROUND_MARKET_DATA].dwt,
                        inspectionAndGradeData,
                        defaultDwtMinValue,
                        getFleetDwtRange(inspectionAndGradeData, defaultDwtMinValue)[BACKGROUND_MARKET_DATA],
                        marketDataFilteringOptions,
                    ),
                teu: appliedFilters?.background[BACKGROUND_MARKET_DATA].teu.changed
                    ? appliedFilters?.background[BACKGROUND_MARKET_DATA].teu
                    : getDefaultMarketTeuState(
                        filterState.background[BACKGROUND_MARKET_DATA].teu,
                        defaultTeuMinValue,
                        defaultMaxTeuValue,
                        marketDataFilteringOptions,
                    )
            }
        ],
        marketDataToggleSelected: appliedFilters?.marketDataToggleSelected
    };

    dispatch(setFilterState(toggleState as AdvancedFilterSelectionState));
    dispatch(setOpen(!open));
}

export const copyBackgroundBetweenMarketAndFleet = (
    marketDataSelected: boolean,
    marketDataFilteringOptions: MarketDataFilteringOptions,
    distinctCountriesOfBuild: string[],
): AppThunk => async (dispatch, getState) => {
    const filterState = selectFitlerState(getState());

    const copiedFilterState = cloneDeep(filterState);

    let countryOfBuildIntersection;
    let targetDwtValues;
    let targetTeuValues;
    let targetToCopyTo;
    let targetCurrentCountryOfBuild;
    let deselectedCountries:  string[];

    if (!marketDataSelected) {
        countryOfBuildIntersection = arrayIntersection(distinctCountriesOfBuild, copiedFilterState.background[BACKGROUND_MARKET_DATA].countryOfBuild.data);
        targetCurrentCountryOfBuild = copiedFilterState.background[BACKGROUND_FLEET_DATA].countryOfBuild.data;

        const sourceCountryOfBuild = copiedFilterState.background[BACKGROUND_MARKET_DATA].countryOfBuild.data;
        const optionsIntersect = arrayIntersection(marketDataFilteringOptions?.buildCountries || [], distinctCountriesOfBuild);

        deselectedCountries = optionsIntersect.filter(item => !sourceCountryOfBuild.includes(item));
        targetDwtValues = getDwtValues(BACKGROUND_FLEET_DATA, BACKGROUND_MARKET_DATA, copiedFilterState);
        targetTeuValues = getTeuValues(BACKGROUND_FLEET_DATA, BACKGROUND_MARKET_DATA, copiedFilterState);
        targetToCopyTo = BACKGROUND_FLEET_DATA;
    } else {
        countryOfBuildIntersection = arrayIntersection(copiedFilterState.background[BACKGROUND_FLEET_DATA].countryOfBuild.data, marketDataFilteringOptions?.buildCountries || []);
        targetCurrentCountryOfBuild = copiedFilterState.background[BACKGROUND_MARKET_DATA].countryOfBuild.data;

        const sourceCountryOfBuild = copiedFilterState.background[BACKGROUND_FLEET_DATA].countryOfBuild.data;

        const optionsIntersect = arrayIntersection(marketDataFilteringOptions?.buildCountries || [], distinctCountriesOfBuild);
        deselectedCountries = optionsIntersect.filter(item => !sourceCountryOfBuild.includes(item));

        targetDwtValues = getDwtValues(BACKGROUND_MARKET_DATA, BACKGROUND_FLEET_DATA, copiedFilterState);
        targetTeuValues = getTeuValues(BACKGROUND_MARKET_DATA, BACKGROUND_FLEET_DATA, copiedFilterState);
        targetToCopyTo = BACKGROUND_MARKET_DATA;
    }

    countryOfBuildIntersection.push(...targetCurrentCountryOfBuild);

    countryOfBuildIntersection = countryOfBuildIntersection.filter((item: string) => !deselectedCountries.includes(item))

    const newState = {
        ...copiedFilterState.background[targetToCopyTo],
        countryOfBuild: {
            ...copiedFilterState.background[targetToCopyTo].countryOfBuild,
            data: [...new Set(countryOfBuildIntersection)]
        },
        dwt: {
            ...copiedFilterState.background[targetToCopyTo].dwt,
            ...targetDwtValues
        },
        teu: {
            ...copiedFilterState.background[targetToCopyTo].teu,
            ...targetTeuValues
        }
    };

    const newBackgroundState: [BackgroundFilters, BackgroundFilters] = marketDataSelected ? [
        copiedFilterState.background[BACKGROUND_FLEET_DATA],
        newState
    ] : [
        newState,
        copiedFilterState.background[1],
    ];

    dispatch(setFilterState({
        ...filterState,
        marketDataToggleSelected: marketDataSelected,
        background: newBackgroundState,
    }));
}

export const onMarketDataToggle = (
    marketDataFilteringOptions: MarketDataFilteringOptions | undefined,
    distinctCountriesOfBuild: string[],
): AppThunk => async (dispatch, getState) => {
    if (marketDataFilteringOptions) {
        const filterState = selectFitlerState(getState());

        sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_MARKET_DATA_TOGGLE_CLICK);
        const marketDataSelected = !filterState.marketDataToggleSelected;
    
        dispatch(copyBackgroundBetweenMarketAndFleet(
            marketDataSelected, marketDataFilteringOptions, distinctCountriesOfBuild
        ));
    }
};

export const applyFilters = (): AppThunk => async (dispatch, getState) => {
    const filterState = selectFitlerState(getState());
    const userDetails = selectUserDetails(getState());

    const newState = filterState ?? initialState.filterState;

    dispatch(updateCounts(newState));
    dispatch(setAppliedFilters(newState));
    storeAppliedState(userDetails?.userName, newState);
}


export const onApply = (): AppThunk => async (dispatch, getState) => {
    const filterState = selectFitlerState(getState());

    dispatch(setLoading(true));
    sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_APPLY);

    if (!hasBackgroundFilters(filterState.background, filterState.marketDataToggleSelected)) {
        dispatch(applyFilters());
        dispatch(setOpen(false));
        dispatch(setLoading(false));
    }

    await dispatch(fetchFleetGraph());
    await dispatch(fetchSubgradeBackingData());
    dispatch(applyFilters());
    dispatch(setOpen(false));
    dispatch(setLoading(false));

    const { error } = selectFleetGraph(getState());

    if (error) {
        dispatch(setShowWarningMessage(true));
        dispatch(setOpen(true));
    } else {
        dispatch(setShowWarningMessage(false));
    }
};

export const getStoredAdvancedFiltersState = (): AppThunk => async (dispatch, getState) => {
    const userDetails = selectUserDetails(getState());

    if (userDetails?.userName) {
        const storedAppliedState = getUserSessionStorageItem(
            userDetails.userName,
            LOCAL_TABLE_CONFIG_KEY.ADVANCED_FILTER_APPLIED_STATE
        );
    
        const parsedState = parseStoredState(storedAppliedState);
    
        if (!Array.isArray(parsedState?.background)) {
            return;
        }
    
        const defaultState = {
            background: [
                {
                    ...defaultBackgroundState,
                    ...(parsedState && {...parsedState.background[0]}),
                },
                {
                    ...defaultBackgroundState,
                    ...(parsedState && {...parsedState.background[1]}),
                }
            ],
            foreground: {
                ...defaultForegroundState,
                ...(parsedState && {...parsedState.foreground}),
            },
            marketDataToggleSelected: 
                Boolean(parsedState?.marketDataToggleSelected)
        }

        if (parsedState) {
            dispatch(setAppliedFilters(defaultState as AdvancedFilterSelectionState));
            dispatch(setFilterState(defaultState as AdvancedFilterSelectionState));
            dispatch(updateCounts(defaultState as AdvancedFilterSelectionState));   
        }
    }
}
