const userGroupKey = (userName: string, groupName: string, key: any) => `${userName}_${groupName}_${key}`;

const userKey = (userName: string, key: any) => {
    const storageKey = `${userName}_${key}`;
    return storageKey;
};

export const storeGroupSessionStorageItem = (
    userName: string,
    groupName: string,
    key: string,
    storageItem: any
) => sessionStorage.setItem(
    userGroupKey(userName, groupName, key), JSON.stringify(storageItem)
);

export const getGroupSessionStorageItem = 
    (userName: string, groupName: string, key: string): string | null => 
        sessionStorage.getItem(
            userGroupKey(userName, groupName, key)
        );

export const removeGroupSessionStorageItem = 
    (userName: string, groupName: string, key: string) => 
        sessionStorage.removeItem(
            userGroupKey(userName, groupName, key)
        );

export const storeUserSessionStorageItem = (
        userName: string,
        key: string,
        storageItem: any
    ) => sessionStorage.setItem(
        userKey(userName, key), JSON.stringify(storageItem)
    );

export const getUserSessionStorageItem = (
    userName: string,
    key: string,
) => sessionStorage.getItem(
        userKey(userName, key)
);
