export interface ClosedRatioData {
    closed: number;
    date: string;
    technical_manager: string;
    open: number;
    ratio: number;
    vessel: string;
    vessel_age: number;
    vessel_name: string;
    vessel_type: string;
    [key: string]: string | number;
}

export interface CumulativeDefectData {
    closed: number;
    date: string;
    technical_manager: string;
    open: number;
    vessel: string;
    vessel_age: number;
    vessel_name: string;
    vessel_type: string;
    [key: string]: string | number;
}
export interface AgeOfOpenDefectsData {
    high: number;
    medium: number;
    low: number;
    technical_manager: string;
    period: number;
    total: number;
    vessel: string;
    vessel_age: number;
    vessel_name: string;
    vessel_type: string;
    [key: string]: string | number;
}

export enum DefectInsightFilterBy {
    TECHNICAL_MANAGERS = "technicalManagers",
    VESSELS = "vessels"
}