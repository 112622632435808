import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSpecialCharacters } from "@idwal/idwal-utilities/lib/helpers";
import { selectUser, selectUserId } from "../../store/selectors/userSelectors";

import { validate } from 'email-validator';
import { errorToast, successToast } from "@idwal/idwal-react-components";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_SHARE_CLICK, DEFECTS_SHARE_ADD_USER_CLICK } from "../../constants/analytics";
import { Messages } from "primereact/messages";
import { Fieldset } from 'primereact/fieldset';
import { Chips, ChipsRemoveEvent } from "primereact/chips";
import { Tooltip } from "primereact/tooltip";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import {
    selectFilteredDefects,
    selectSelectedVessel,
    selectShowShareDefectsDialog,
} from "../../store/selectors/defectSelectors";
import {setSelectedVessel, setShowShareDefectsDialog} from "../../store/slices/defectSlice";
import {sendShareReportEvents, shareDefects} from "../../services/SharedDefectsService";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { MultiSelect } from "primereact/multiselect";
import { selectPreviousRecipients } from "../../store/selectors/sharedDefectsSelectors";
import { mapDefectsToShare } from "../../utils/DefectsTableDataHelper";
import {Checkbox} from "primereact/checkbox";
import {useFlags} from "launchdarkly-react-client-sdk";
import { ShareableDefect } from "../../types/ShareableDefect";
import { getCurrentUserName } from "../../queries/AuthToken";

type ShareDefectModalProps = {
    filteredData?: DefectDataItem[];
}

export const ShareDefectModal = (props: ShareDefectModalProps) => {
    const { filteredData } = props;

    const dispatch = useAppDispatch();
    const filteredDefects = useAppSelector(selectFilteredDefects);
    const selectedVessel = useAppSelector(selectSelectedVessel);
    const showShareDefectsDialog = useAppSelector(selectShowShareDefectsDialog);
    const previousRecipients = useAppSelector(selectPreviousRecipients);
    const userId = useAppSelector(selectUserId);

    const [recipientName, setRecipientName] = useState<string>("");
    const [recipientNameError, setRecipientNameError] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [emailAddressError, setEmailAddressError] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [messageError, setMessageError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [newRecipient, setNewRecipient] = useState(false);

    const { user } = useAuthenticator((context) => [context.user]);

    const { t } = useTranslation("locale");

    const shareModalMessages = useRef<Messages>(null);

    const [shareRecipients, setShareRecipients] = useState<any[]>([]);
    const [shareReport, setShareReport] = useState<boolean>(true);

    const {
        releaseReportSharing
    } = useFlags();

    const defectsToShare: ShareableDefect[] = useMemo(() => (
        mapDefectsToShare(filteredDefects, filteredData, selectedVessel)
    ), [filteredDefects, filteredData, selectedVessel]);

    useEffect(() => {
        if (showShareDefectsDialog) {
            setRecipientName("");
            setRecipientNameError("");
            setEmailAddress("");
            setEmailAddressError("");
            setMessage("");
            setMessageError("");
            setShareReport(true);
            setShareRecipients([])
        }
    }, [showShareDefectsDialog]);

    const closeDialog = () => {
        dispatch(setShowShareDefectsDialog(false));

        if (selectedVessel) {
            dispatch(setSelectedVessel(""));
        }
    }
    
    /**
     * Validates the add recipient form
     * @returns 
     */
    const validateForm = () => {
        let validForm = true;
        
        const recipientNameContainsSpecialCharacters = getSpecialCharacters().test(recipientName);
        const emailAddressIsInvalid = !validate(emailAddress);
        const emailAsName = validate(recipientName);

        if (!emailAddress) {
            setEmailAddressError(t("defectShareActionPlan.emptyField"))
            validForm = false;
        } else if (emailAddressIsInvalid) {
            setEmailAddressError(t("defectShareActionPlan.invalidEmail"));
            validForm = false;
        } else if (emailAddress === user.attributes?.email) {
            setEmailAddressError(t("defectShareActionPlan.ownEmail"));
            validForm = false;
        }  else {
            setEmailAddressError("");
        }

        if (!recipientName) {
            setRecipientNameError(t("defectShareActionPlan.emptyField"));
            validForm = false;
        } else if (recipientNameContainsSpecialCharacters) {
            setRecipientNameError(t("defectShareActionPlan.invalidRecipientName"));
            validForm = false;
        } else if (emailAsName) {
            setRecipientNameError(t("defectShareActionPlan.invalidRecipientName"));
            validForm = false;
        } else {
            setRecipientNameError("");
        }

        return validForm;
    }

    /**
     * Submit share button after users added
     * Calls defect share endpoint
     */
    const submitShareForm = async () => {
        setLoading(true);

        // For error handling
        let currentRequest;

        try {

            if (shareRecipients && shareRecipients.length > 0) {
                for (const recipient in shareRecipients) {                           
                    currentRequest = shareRecipients[recipient];
                    await shareDefects(defectsToShare, shareRecipients[recipient].name, shareRecipients[recipient].email, message);

                    if (releaseReportSharing && shareReport && userId) {
                        const customerOrganization = shareRecipients[recipient].email.split("@")[1].split(".")[0];
                        const userName = await getCurrentUserName()
                        try {
                            sendShareReportEvents(
                                defectsToShare, 
                                shareRecipients[recipient].email, 
                                shareRecipients[recipient].name, 
                                userId,
                                userName,
                                customerOrganization
                            )
                        } catch (error) {
                            console.error(error)
                            errorToast(t("defectShareActionPlan.reportShareFailed"));
                        }

                    }
                }


                successToast(t("defectShareActionPlan.shareDefectsSuccessMessage"));
                setLoading(false);
                closeDialog();
                sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_SHARE_CLICK);
            }

        } catch (error) {
            console.error(error)
            errorToast(t("defectShareActionPlan.shareDefectsErrorMessage") + currentRequest.email);
        } finally {
            setLoading(false);
        }
    }

    /**
     * Removes recipient from the Chips list
     * @param removeEvent 
     */
    const removeRecipient = (removeEvent: any) => {
        const removedRecipient = removeEvent.value[0];
        setShareRecipients(shareRecipients.filter(recipient => recipient.email !== removedRecipient.email));
    }

    /**
     * Called when the dialog model opens to add the context messages
     */
    const onDialogShow = () => {
       shareModalMessages.current?.clear();
            shareModalMessages.current?.show({ id: '1', sticky: true, severity: 'info', detail: <ul>
          <li>{t("defectShareActionPlan.list1a")}<b>{t("defectShareActionPlan.list1b")}</b>{t("defectShareActionPlan.list1c")}</li>
          <li>{t("defectShareActionPlan.list2a")}<b>{t("defectShareActionPlan.list2b")}</b>{t("defectShareActionPlan.list2c")}</li>
        </ul>, closable: false });
    };

    /**
     * Template for the recipient chips
     * @param item 
     * @returns 
     */
    const customRecipientChip = (item: any) => {
        const className = `custom-target-icon-${item.name.replace(/\s+/g, '')}`;
        const target = `.${className}`;
        return (
            <div>
                <span data-pr-tooltip={item.email} data-pr-position="right" className={className}>{item.name}</span>
                <Tooltip target={target} />
            </div>
        );
    };

    /**
     * Add recipient to chips list
     */
    const addShareUserClick = () => {
        if (validateForm()) {
            if (!shareRecipients.some(r => r.email === emailAddress)) {
                setShareRecipients([...shareRecipients, { name: recipientName, email: emailAddress }]);
                sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_SHARE_ADD_USER_CLICK);
                setRecipientName('');
                setEmailAddress('');
            } else {
                setEmailAddressError(t("defectShareActionPlan.recipientAlreadyAdded") + shareRecipients.find(r => r.email === emailAddress).name);
            }
        }
    };

    /**
     * Template for the recipient options
     * @param option 
     * @returns 
     */
    const recipientTemplate = (option: any) => {
        return (
            <div>
                <div className="pb-1">{option.name}</div>
                <div style={{ color: '#777777' }}>{option.email}</div>
            </div>
        );
    };

    /**
     * Template for the selected recipient item
     */
    const selectedItemTemplate = () => {
        return (
            // Filter essentially means nothing can be entered, its an impossible expression positive lookahead, this is as we are making it half readonly due to remove option
            <Chips style={{width: "100%"}} onRemove={(e: ChipsRemoveEvent) => removeRecipient(e)} keyfilter={/^(?!.*$)./} value={shareRecipients} itemTemplate={customRecipientChip} />
        );
    };

    /**
     * Invoked when all recipient options are selected or deselected
     */
    const onSelectAll = () => {
        const allSelected = previousRecipients.every(user => shareRecipients.some(selectedUser => selectedUser.name === user.name));

        if (allSelected) {
            const updatedSelection = shareRecipients.filter(
                selectedUser => !previousRecipients.some(user => user.name === selectedUser.name)
            );
            setShareRecipients(updatedSelection);
        } else {
            const combinedSelection = [...shareRecipients];

            previousRecipients.forEach(user => {
                if (!combinedSelection.some(selectedUser => selectedUser.email === user.email)) {
                    combinedSelection.push(user);
                }
            });

            setShareRecipients(combinedSelection);
        }
    };

    const numberOfDefects = defectsToShare.length;

    return (
      <Dialog
        header={t("defectShareActionPlan.dialogTitle")}
        blockScroll={true}
        style={{maxWidth: 800}}
        className="shareDefectsModal"
        onShow={onDialogShow}
        visible={showShareDefectsDialog}
        onHide={closeDialog}
        draggable={false}
      >
        <Messages ref={shareModalMessages} />

        <p className="mt-4 mb-4" data-cy="shareDefectsText">
            {t("defectShareActionPlan.mainBody", 
                { numberOfDefects, defect: numberOfDefects === 1 ? t("defectShareActionPlan.defect") : t("defectShareActionPlan.defects") }
            )}
        </p>

        {newRecipient && 
        <Fieldset legend={t("defectShareActionPlan.recipientDetailsTitle")}>
            <div className="grid">
                <div className="col-12">
                    <div className="grid pb-3">
                        <div className="pt-3 col-3">
                            <label>{t("defectShareActionPlan.recipientNameLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <InputText 
                                data-cy="shareNameInput"
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                                style={{ maxWidth: "100%" }}
                                className={`col-12 ${recipientNameError.length > 1 ? "p-invalid" : ""}`} 
                            />
                            <small className="p-error block">{recipientNameError}</small>
                        </div>
                    </div>

                    <div className="grid pt-2 pb-0">
                        <div className="pt-3 col-3">
                            <label>{t("defectShareActionPlan.emailAddressLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <InputText
                                data-cy="shareEmailInput"
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value.trim())}
                                style={{maxWidth: "100%"}}
                                className={`col-12 ${emailAddressError.length > 1 ? "p-invalid" : ""}`} 
                            />
                            <small className="p-error block">{emailAddressError}</small>
                        </div>
                    </div>
                </div>

                <div className="col-12 flex justify-content-center p-0">
                    <Button size="small" onClick={addShareUserClick} label={t("defectShareActionPlan.addToList")} aria-label={t("defectShareActionPlan.addToList")} data-cy="addShareUserToList" />
                </div>

            </div>
        </Fieldset>
        }

        <div className="col-12 mt-5">
            <div className="grid pb-3">
                <div className="pt-3 col-3">
                    <label>{t("defectShareActionPlan.recipientsChipsLabel")}</label>
                </div>
                <div className="pt-0 col-9">
                    <MultiSelect value={shareRecipients} onChange={(e) => setShareRecipients(e.value)} options={previousRecipients} itemTemplate={recipientTemplate} className="w-full" optionLabel="name" data-cy="previous-recipient-dropdown" filter selectedItemTemplate={selectedItemTemplate} onSelectAll={onSelectAll}/>
                </div>
            </div>

            {!newRecipient &&
            <div className="grid pb-3">
                <div className="pt-3 col-3"></div>
                <div className="pt-0 col-9">
                    <span
                        data-cy="new-recipient-btn"
                        className="font-semibold underline cursor-pointer read-more-btn"
                        onClick={() => setNewRecipient(true)}
                    >
                    {t("defectShareActionPlan.newRecipient")}
                    </span>
                </div>
            </div>
            }

            <div className="grid pb-3">
                <div className="pt-3 col-3">
                    <label>{t("defectShareActionPlan.messageLabel")}</label>
                </div>
                <div className="pt-0 col-9">
                    <InputTextarea 
                        data-cy="shareMessageInput"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{maxWidth: "100%", minHeight: 162}}
                        rows={8}
                        className={`col-12 ${messageError.length > 1 ? "p-invalid" : ""}`} 
                    />
                    <small className="p-error block">{messageError}</small>
                </div>
            </div>

            {
                releaseReportSharing ?
                    <div className="grid pb-3">
                        <div className="pt-3 col-3">
                            <label>{t("defectShareActionPlan.shareReportLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <div className={"flex justify-content-start"}>
                                <Checkbox className={"mt-3"} inputId="shareDefect"
                                          onChange={() => setShareReport(!shareReport)} checked={shareReport}/>
                                <p className="ml-2 mt-3">{t("defectShareActionPlan.shareReportCheckbox")}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>


          <div className="mt-0 w-full flex align-items-center justify-content-center gap-4">
              <Button
                  className="p-button p-component p-button-text"
                  label={t("defectShareActionPlan.cancel")}
                  data-cy="cancel"
                  onClick={closeDialog}
              />
              <Button
                  className="p-button p-button-primary"
                  label={t("defectShareActionPlan.share")}
                  onClick={submitShareForm}
                  disabled={!shareRecipients || shareRecipients.length === 0}
                  style={{width: 125}}
                  loading={loading}
            />
        </div>
      </Dialog>
    );
}
