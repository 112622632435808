import { PageHeader } from "@idwal/idwal-react-components"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { pathMapper } from "../../utils/pathMapper"
import { AiToggle } from "../AiToggle/AiToggle"
import { GroupDropDown } from "../GroupDropdown/GroupDropDown"
import "./PageHeaderWrapper.css"
import { formatDefectId } from "@idwal/idwal-utilities/lib/helpers"
import { useAppSelector } from "../../hooks/storeHooks"
import { selectRectification } from "../../store/selectors/rectificationSelector"
import { Capability } from "../../types/Capability"
import { useCapabilities } from "../../hooks/useCapabilities"

export const PageHeaderWrapper = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING
    ]);

    let currentPage = pathname.split("/").pop() ?? "";
    currentPage = currentPage === "" ? t("pageHeader.homePage") : currentPage;
    const pathCrumbs = [...pathname.split("/").slice(1, -1)];

    let mappedCurrentPage = pathMapper(currentPage, t) ?? "";
    let mappedPaths = pathCrumbs.map((path) => pathMapper(path, t)) as string[];

    const { defect } = useAppSelector(selectRectification);

    if (currentPage === t("pageHeader.rectifications").toLowerCase()) {
        mappedPaths = defect?.defectId ? [`${t("pageHeader.defects")}`] : [];
        mappedCurrentPage = defect?.defectId ? `${formatDefectId(defect.defectId)}` : '';
    }

    return (
        <PageHeader 
            currentPageCrumb={mappedCurrentPage} 
            pathCrumbs={mappedPaths} 
            rhsInteractiveElements={
                [<AiToggle/>, 
                <GroupDropDown/>]
            }
            trial={capabilities.CAP_FLEETAPP_VIEW_PSC || capabilities.CAP_FLEETAPP_VIEW_SCREENING ? true : false}
        />
    )
}