import {FleetDataItem} from "../types/FleetDataItem";
import {StatTileConfig} from "../types/StatTileConfig";
import {DefectDataItem} from "../types/DefectsDataItem";
import {VesselSubgradeResponse} from "../types/VesselSubgradeResponse";
import {SubgradeBackingData} from "../types/SubgradeBackingData";
import {mergeGradesWithDefects} from "./DefectsTableDataHelper";
import {VesselsOverdueInspectionTile} from "./stat_tiles/VesselsOverdueInspectionTile";
import {InspectionsDueIn30DaysTile} from "./stat_tiles/InspectionsDueIn30DaysTile";
import {AverageIdwalGradeTile} from "./stat_tiles/AverageIdwalGradeTile";
import {LowestIdwalGradeVesselsTile} from "./stat_tiles/LowestIdwalGradeVesselsTile";
import {SubgradeWithWorstDeviationAcrossFleetTile} from "./stat_tiles/SubgradeWithWorstDeviationAcrossFleetTile";
import {AverageDefectsPerVesselAcrossFleetTile,} from "./stat_tiles/AverageDefectsPerVesselAcrossFleetTile";
import {VesselWithMostHighPriorityOpenDefectsTile} from "./stat_tiles/VesselWithMostHighPriorityOpenDefectsTile";
import {INSPECTION_STATUS_FULL_REPORT, DEFAULT_STAT_TILES_ORDER} from "./Constants";
import {WorstPerformingTechnicalManagerTile} from "./stat_tiles/WorstPerformingTechnicalManagerTile";
import {WorstPerformingClassSocietyTile} from "./stat_tiles/WorstPerformingClassSocietyTile";
import {VesselWithLowestCrewWelfareTile} from "./stat_tiles/VesselWithLowestCrewWelfareTile";
import {VesselWithLowestPscPerformanceTile} from "./stat_tiles/VesselWithLowestPscPerformanceTile";

export const calculateFleetStatistics = (
  inspectionData : FleetDataItem[], 
  subgradeData: VesselSubgradeResponse, 
  subgradeBackingData: SubgradeBackingData, 
  defectData: DefectDataItem[], 
  statTileOrder?: any,
) => {
  if (!statTileOrder) {
    statTileOrder = DEFAULT_STAT_TILES_ORDER;
  }
 
  if (!inspectionData || !subgradeData || !subgradeBackingData || !defectData) {
    throw new Error("calculateFleetStatistics requires valid data.");
  }

  const vesselsWithInspection : FleetDataItem[] = inspectionData.filter((entry) => entry.inspection);

  let tiles : StatTileConfig[] = [];

  const mergedDefectData = mergeGradesWithDefects(defectData, inspectionData);

  //Vessel With Most High Priority Open Defects
  const vesselWithMostHighPriorityOpenDefectsTile = new VesselWithMostHighPriorityOpenDefectsTile(mergedDefectData).getTileData();
  if (vesselWithMostHighPriorityOpenDefectsTile) {
    tiles.push(vesselWithMostHighPriorityOpenDefectsTile);
  }

  //Average Defects Per Vessel Across Fleet
  const averageDefectsPerVesselAcrossFleetTile = new AverageDefectsPerVesselAcrossFleetTile(mergedDefectData).getTileData();
  if (averageDefectsPerVesselAcrossFleetTile) {
    tiles.push(averageDefectsPerVesselAcrossFleetTile);
  }

  //Vessel with lowest crew welfare
  const vesselWithLowestCrewWelfareTile = new VesselWithLowestCrewWelfareTile(subgradeData, inspectionData).getTileData();
  if (vesselWithLowestCrewWelfareTile) {
    tiles.push(vesselWithLowestCrewWelfareTile);
  }

  //Vessel with lowest PSC performance
  const vesselWithLowestPscPerformanceTile = new VesselWithLowestPscPerformanceTile(subgradeData, inspectionData).getTileData();
  if (vesselWithLowestPscPerformanceTile) {
    tiles.push(vesselWithLowestPscPerformanceTile);
  }

  //Worst subgrade deviation across fleet
  const subgradeWithWorstDeviationAcrossFleetTile = new SubgradeWithWorstDeviationAcrossFleetTile(subgradeData, subgradeBackingData).getTileData();
  if (subgradeWithWorstDeviationAcrossFleetTile) {
    tiles.push(subgradeWithWorstDeviationAcrossFleetTile);
  }

  const filteredInspections =
      vesselsWithInspection.filter((entry) =>
          entry.inspection.status === INSPECTION_STATUS_FULL_REPORT &&
          entry.inspection.idwalGrade);

  //Lowest Idwal Grade Vessels
  const lowestIdwalGradeVesselsTile = new LowestIdwalGradeVesselsTile(filteredInspections).getTileData();
  if (lowestIdwalGradeVesselsTile) {
    tiles.push(lowestIdwalGradeVesselsTile);
  }

  //Average Idwal Grade
  const averageIdwalGradeTile = new AverageIdwalGradeTile(filteredInspections).getTileData();
  if (averageIdwalGradeTile) {
    tiles.push(averageIdwalGradeTile);
  }

  //Vessels with inspection due in 30 days
  const vesselsWithInspectionDueIn30DaysTile = new InspectionsDueIn30DaysTile(inspectionData).getTileData();
  if (vesselsWithInspectionDueIn30DaysTile) {
    tiles.push(vesselsWithInspectionDueIn30DaysTile);
  }

  //Vessels overdue for inspection
  const vesselsOverdueForInspectionTile = new VesselsOverdueInspectionTile(inspectionData).getTileData();
  if (vesselsOverdueForInspectionTile) {
    tiles.push(vesselsOverdueForInspectionTile);
  }

  // Worst performing technical manager
  const worstPerformingTechnicalManagerTile = new WorstPerformingTechnicalManagerTile(filteredInspections).getTileData();
  if (worstPerformingTechnicalManagerTile) {
    tiles.push(worstPerformingTechnicalManagerTile);
  }

  // Worst performing class society
  const worstPerformingClassSocietyTile = new WorstPerformingClassSocietyTile(filteredInspections).getTileData();
  if (worstPerformingClassSocietyTile) {
    tiles.push(worstPerformingClassSocietyTile);
  }

  const sortTiles = (a: StatTileConfig, b: StatTileConfig) => {
    const aOrder = statTileOrder[a.tileKey]
    const bOrder = statTileOrder[b.tileKey]

    return aOrder - bOrder;
  }

  // Set colours based on index - cycle through 4 colour options
  let counter = 1;
  const orderedTiles = tiles.sort(sortTiles);
  orderedTiles.forEach(x => {
    switch(counter) {
      case 1:
        x.colour = "light-blue";
        break;
      case 2:
        x.colour = "teal-blue";
        break;
      case 3:
        x.colour = "mid-blue";
        break;
      case 4:
        x.colour = "purple";
        break;
    }
    if (counter == 4) {
      counter = 1;
    } else {
      counter++;
    }
  })

  return orderedTiles;
};



