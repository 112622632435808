import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../hooks/storeHooks";
import { uniq } from "lodash";
import { selectScreeningData } from "../../store/selectors/screeningSelectors";

export const ScreeningVesselTypeFilter = (
    options: ColumnFilterElementTemplateOptions
) => {
    const data = useAppSelector(selectScreeningData);

    const vesselTypeOptions = useMemo(() => {
        const vesselTypes: string[] = [];

        data.forEach((screeningItem) => {
            if (screeningItem.vessel_type) {
                vesselTypes.push(screeningItem.vessel_type);
            }
        });

        return uniq(vesselTypes).map((vesselType) => ({
            label: vesselType,
            value: vesselType
        }));
    }, [data]);

    const onChange = useCallback(
        (e: MultiSelectChangeEvent) => {
            options.filterApplyCallback(e.value);
        },
        [options]
    );

    return (
        <MultiSelect
            value={options.value}
            options={vesselTypeOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};
