import { Column } from "primereact/column"
import { PSCVesselBody } from "../components/PSC/PSCVesselBody";
import { FilterMatchMode } from "primereact/api";
import { ScreeningNumberBody } from "../components/Screening/ScreeningNumberBody";
import { ScreeningItem } from "../types/screening";
import { ScreeningVesselTypeFilter } from "../components/Screening/ScreeningVesselTypeFilter";
import { ScreeningGradeTypeFilter } from "../components/Screening/ScreeningGradeTypeFilter";
import { ScreeningGradeBody } from "../components/Screening/ScreeningGradeBody";
import { ScreeningAgeBody } from "../components/Screening/ScreeningAgeBody";
import { ScreeningBuilderBody } from "../components/Screening/ScreeningBuilderBody";
import { ScreeningClassBody } from "../components/Screening/ScreeningClassBody";
import { ScreeningFlagBody } from "../components/Screening/ScreeningFlagBody";
import { ScreeningActionMenu } from "../components/Screening/ScreeningActionMenu";
import { ScreeningVesselBody } from "../components/Screening/ScreeningVesselBody";

export const screeningGlobalFilterFields = ["vessel_imo", "vessel_name"];

export const screeningFilters = ({
    vessel_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    vessel_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    dwt: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    teu: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    estimated_grade: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    grade_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    grade_date: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    flag_state: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    class_society: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    builder: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    age_of_vessel: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
})

export const screeningColumns = () => [
    <Column
        key="vessel_name"
        field="vessel_name"
        header="Vessel"
        frozen
        sortable
        filter
        filterPlaceholder="Search by vessel"
        className="table-large-column"
        body={ScreeningVesselBody}
    />,
    <Column
        key="actions"
        header="Actions"
        className="table-small-column"
        body={ScreeningActionMenu}
    />,
    <Column
        key="vessel_type"
        field="vessel_type"
        header="Vessel type"
        sortable
        filter
        filterElement={ScreeningVesselTypeFilter}
        showFilterMenu={false}
        className="table-small-column"
    />, 
    <Column
        key="dwt"
        field="dwt"
        header="DWT"
        sortable
        filter
        filterPlaceholder="Search by DWT"
        showFilterMenu={false}
        className="table-small-column"
        body={(item: ScreeningItem) => ScreeningNumberBody(item.dwt)}
    />,
    <Column
        key="teu"
        field="teu"
        header="TEU"
        sortable
        filter
        filterPlaceholder="Search by TEU"
        showFilterMenu={false}
        className="table-small-column table-right-border"
        body={(item: ScreeningItem) => ScreeningNumberBody(item.teu)}
    />,
    <Column
        key="estimated_grade"
        field="estimated_grade"
        header="Idwal Grade"
        className="table-small-column"
        sortable
        filter
        filterPlaceholder="Search by grade"
        showFilterMenu={false}
        body={ScreeningGradeBody}
    />,
    <Column
        key="grade_type"
        field="grade_type"
        header="Grade type"
        className="table-small-column"
        sortable
        filter
        filterElement={ScreeningGradeTypeFilter}
        showFilterMenu={false}
    />,
    <Column
        key="grade_date"
        field="grade_date"
        header="Grade date"
        className="table-small-column table-right-border"
        sortable
        filter
        filterPlaceholder="Search by date"
        showFilterMenu={false}
    />,
    <Column
        key="flag_state"
        field="flag_state"
        header="Flag state"
        className="table-large-column"
        sortable
        filter
        filterPlaceholder="Search by flag"
        showFilterMenu={false}
        body={ScreeningFlagBody}
    />,
    <Column
        key="class_society"
        field="class_society"
        header="Class society"
        className="table-large-column table-right-border"
        sortable
        filter
        filterPlaceholder="Search by class"
        showFilterMenu={false}
        body={ScreeningClassBody}
    />,
    <Column
        key="builder"
        field="builder"
        header="Shipbuilder"
        className="table-large-column"
        sortable
        filter
        filterPlaceholder="Search by builder"
        showFilterMenu={false}
        body={ScreeningBuilderBody}
    />,
    <Column
        key="age_of_vessel"
        field="age_of_vessel"
        header="Vessel age"
        className="table-small-column"
        sortable
        filter
        filterPlaceholder="Search by age"
        showFilterMenu={false}
        body={ScreeningAgeBody}
    />,
];

export const getScreeningGrade = (estimated_grade: string) => {
    const grades = estimated_grade.split("-");

    if (grades.length > 1) {
        return {
            gradeType: 1,
            gradeMin: Number(grades[0]),
            gradeMax: Number(grades[1]),
        }
    }

    return {
        gradeType: 1,
        grade: Number(estimated_grade),
    }
}

export const getFlagUrl = (flagCode: string) => {
    return `https://quotation.idwalmarine.com/images/flags/${flagCode.toLowerCase()}.svg`;
};