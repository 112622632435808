import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectPSCDeficienciesData } from "../../store/selectors/pscSelector";
import { uniq } from "lodash";

export const PSCNatureOfDeficiencyFilter = (options: ColumnFilterElementTemplateOptions) => {
    const deficienciesData = useAppSelector(selectPSCDeficienciesData);

    const natureOfDeficiencyOptions = useMemo(() => {
        const natureDecodes: string[] = [];

        deficienciesData.forEach((deficiency) => {
            if (deficiency.nature_of_defect_decode) {
                natureDecodes.push(deficiency.nature_of_defect_decode)
            }
        })

        return uniq(natureDecodes).map((natureDecode) => ({ label: natureDecode, value: natureDecode }));
    }, [deficienciesData]);

    const onChange = useCallback((e: MultiSelectChangeEvent) => {
        options.filterApplyCallback(e.value)
    }, [options])

    return (
        <MultiSelect
            value={options.value}
            options={natureOfDeficiencyOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};

