import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

export enum GraphFilterType {
    VESSELS = 0,
    TECHNICAL_MANAGERS = 1
}

export enum SubgradeGraphFilterType {
    "All Areas" = 0,
    "Bridge and Navigation Equipment" = 1,
    "Accommodation" = 2,
    "Lifesaving Appliances" = 3,
    "Mooring Decks" = 4,
    "Engine Room and Machinery" = 5,
    "Vessel Capabilities and Cargo Systems" = 6,
    "Ballast Tanks and Systems" = 7,
    "Weather Decks and Fittings" = 8,
    "Hull" = 9,
    "Pollution Control" = 10,
    "Onboard Management" = 11,
    "Safe Working Environment" = 12,
    "Forthcoming Regulatory Compliance" = 13,
    "Crew Welfare" = 14,
    "Crew Performance" = 15,
    "Safety Management" = 16,
    "Planned Maintenance System (PMS)" = 17,
    "Classification and Certification" = 18,
    "PSC Performance" = 19,
    "Fire Fighting Equipment and Systems" = 20,
    "Design and Construction" = 21
}

export interface GraphFilterSelectorProps {
    options: { name: string, code: number }[];
    onFilterChange: (e: any) => void;
    selectedValue: number;
}

export const GraphFilterSelector = (props: GraphFilterSelectorProps) => {
    const { options, onFilterChange, selectedValue } = props;
    const {t} = useTranslation("locale");

    return (
        <div className="mr-4">
            <div className="flex align-items-center">
                <span>{t("fleetGraph.graphFilterSelector.show")}</span>
                <Dropdown
                    data-cy="graph-filter-selector"
                    className="ml-2 md:w-15rem"
                    options={options}
                    value={selectedValue}
                    optionLabel="name"
                    optionValue="code"
                    onChange={onFilterChange}
                />
            </div>
        </div>
    )
}