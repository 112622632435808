import { getScreeningData, getScreeningProspects } from "../../services/ScreeningService";
import { setData, setError, setLoading, setProspects } from "../slices/screeningSlice";
import { AppThunk } from "../store";

export const fetchScreeningData =
    (): AppThunk => async (dispatch) => {

        try {
            const prospects = await getScreeningProspects();
            
            if (prospects?.length) {
                dispatch(setProspects(prospects));

                const response = await getScreeningData(prospects);

                if (response?.length) {
                    dispatch(setData(response));
                } 
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }

        dispatch(setLoading(false));
    };
