import { useMemo } from "react";
import { ScreeningItem } from "../../types/screening";
import { getFlagUrl } from "../../utils/ScreeningTableHelper";

export const ScreeningFlagBody = (item: ScreeningItem) => {
    const flagUrl = useMemo(
        () => (item.flag_code ? getFlagUrl(item.flag_code) : getFlagUrl("unk")),
        [item]
    );

    return (
        <div className="flex align-items-center">
            <img className="screening-table-flag mr-2" alt={item.flag_code} src={flagUrl} />
            <p>{`${item.flag_state} (${item.flag_code})`}</p>
        </div>
    );
};
