import { Data } from "plotly.js";
import { graphFontSize } from "../constants/graphFontSize";
import {getTranslationObject} from "../services/Translation";
import { FleetGraphDataItem } from "../types/FleetGraphDataItem";
import { FleetModeGraph } from "../types/FleetGraphMode";
import { 
    getDefaultGraphXAxisMaxValue, 
    getScaledMaxXAxisValueBasedOnFleetData,
    getScaledMinXAxisValueBasedOnFleetData
} from "./getFleetGraphXAxisValue";
import { getGrade } from "../components/FleetGraph/FleetGraphGradeFunctions";
import { GroupedItem } from "../types/GroupedItem";
import { groupByNumberRange } from "./GroupByNumberRange";
import { splitGroupedGraphData } from "./SplitGroupedData";
import { buildGraphDataForStepAverage } from "./BuildGraphDataForStepAverage";

const t = getTranslationObject();

export type SetGraphLayoutProps = {
    defaultXAxisMin: number, 
    xAxisThreshold: number, 
    xAxisStepAmount: number,
    yAxisMin: number, 
    yAxisMax: number, 
    mode : 'grade' | 'condition' | 'management',
    fleetdata: Array<FleetGraphDataItem>,
    backgroundData: Array<FleetGraphDataItem>,
    filtersApplied: boolean,
}

const axisOffset = 0.5;

export const setGraphLayout = ({
    defaultXAxisMin, 
    xAxisThreshold, 
    xAxisStepAmount,
    yAxisMin, 
    yAxisMax, 
    mode,
    fleetdata,
    backgroundData,
    filtersApplied,
}: SetGraphLayoutProps) => {
    const defaultXAxisMaxValue = getDefaultGraphXAxisMaxValue(
        fleetdata, 
        xAxisThreshold,
        xAxisStepAmount
    );

    const scaledXAxisMaxValue = getScaledMaxXAxisValueBasedOnFleetData(
        fleetdata, 
        backgroundData,
        xAxisThreshold, 
        xAxisStepAmount
    );

    const scaledXAxisMinValue = getScaledMinXAxisValueBasedOnFleetData(
        fleetdata, 
        backgroundData,
        xAxisStepAmount
    );

    const layout = {
        autosize:true,
        dragmode: "pan",
        plot_bgcolor: "#FCFCFC",
        margin: {
            t: 0,
            r: 1,
            l: 50
        },
        hoverlabel: {
            bgcolor: "#555555",
            font: {
                family: "Open Sans",
                size: graphFontSize
            }
        },
        legend: {
            xanchor: "left",
            x: 1.01,
            y: 0.95,
            orientation: "v" as any,
            font: {
                family: 'Open Sans',
                size: graphFontSize,
                color: '#555555'
            },
        },
        xaxis: {
            range: [
                (filtersApplied ? scaledXAxisMinValue : defaultXAxisMin),
                (filtersApplied ? scaledXAxisMaxValue : defaultXAxisMaxValue) + axisOffset
            ],
            showline: false,
            zeroline: false,
            showgrid: false,
            title: {
                text: t('fleetGraph.vesselAgeTitle'),
                font: {
                    family: 'Open Sans',
                    size: graphFontSize,
                    color: '#555555'
                }
            }

        },
        yaxis: {
            range: [yAxisMin - 1, Math.max(yAxisMax, 100) + 1],
            showline: false,
            zeroline: false,
            gridcolor: '#B0E2E4',
            title: {
                text: '',
                font: {
                    family: 'Open Sans',
                    size: graphFontSize,
                    color: '#555555'
                }
            }

        }};

    switch (mode) {
        case 'grade':
            layout.yaxis.title.text = t('fleetGraph.idwalGrade');
            break;
        case 'condition':
            layout.yaxis.title.text = t('fleetGraph.conditionGrade');
            break;
        case 'management':
            layout.yaxis.title.text = t('fleetGraph.managementGrade');
            break;
    }

    return layout;
};

export const getHoverTextForItem = (data: FleetGraphDataItem, mode: FleetModeGraph) => {
    let text = `${data.vessel_name}` +
    '<br>' +
    `${data.imo_number}` +
    '<br>' +
    `${data.technical_manager}` +
    '<br>' +
    `${data.vessel_type}` +
    '<br>';

    switch (mode) {
        case 'grade':
            text += `${t("fleetTable.idwalGrade")}: ` + (data.grade ? `${data.grade}` : `${data.grade_min}-${data.grade_max}`);
            break;
        case 'condition':
            text += `${t("fleetTable.conditionGrade")}: ${data.condition_score}`;
            break;
        case 'management':
            text += `${t("fleetTable.managementGrade")}: ${data.management_score}`;
            break;
    }

    return text;
}

export const getHoverText = (data : FleetGraphDataItem[], mode: FleetModeGraph) => 
   data.map(data => getHoverTextForItem(data, mode));

export const getVesselGradeScatterPlotData = (
    data: FleetGraphDataItem[], 
    mode: FleetModeGraph,
    plotName: string
) => {
    const vesselGradeScatterPlot: Data = {
        x: data.map(x => x.ship_age),
        y: data.map(x => getGrade(x, mode)),
        hovertemplate: '%{text}',
        text: getHoverText(data, mode),
        name: plotName,
        type: 'scatter',
        mode: 'markers',
        marker: {
            color: '#28235b',
            opacity: 0.75,
            symbol: 'square',
            size: graphFontSize
        }
    }

    return vesselGradeScatterPlot;
};

export const getVesselGradeScatterPlotDataByVessel = (
    data: FleetGraphDataItem[], 
    mode: FleetModeGraph
) => data.map((dataItem: FleetGraphDataItem) => ({
    x: [dataItem.ship_age],
    y: [getGrade(dataItem, mode)],
    hovertemplate: ['%{text}'],
    text: [getHoverTextForItem(dataItem, mode)],
    name: dataItem.vessel_name,
    type: 'scatter',
    mode: 'markers',
    marker: {
        symbol: 'square',
        size: graphFontSize
    }
}))

export const getBackgroundScatterPlotData = (data: FleetGraphDataItem[], mode: FleetModeGraph, plotName: string) => {
    const backgroundGradeScatterPlot: Data = {
        x: data.map(x => x.ship_age),
        y: data.map(x => getGrade(x, mode)),
        hoverinfo: 'skip',
        name: plotName,
        type: 'scatter',
        mode: 'markers',
        visible: true,
        marker: {
            color: 'rgba(194,194,194, 0.5)',
            symbol: 'circle',
            size: graphFontSize
        }
    };

    return backgroundGradeScatterPlot;
};

interface SteppedAverage {
    x: Array<number>;
    y: Array<number>;
}
export const getSteppedBackgroundAverageData = (
    data: FleetGraphDataItem[],
    mode: FleetModeGraph) => {
    const shipAge = (ship: FleetGraphDataItem) => ship.ship_age;
    const shipGrade = (ship: FleetGraphDataItem) => getGrade(ship, mode);
    const splitFunction = (range: string) => range.split("/");
    const groupedItemRange = (item: GroupedItem<FleetGraphDataItem>) => item.range;
    const stepAmount = 5;

    const groupedBackgroundData = groupByNumberRange<FleetGraphDataItem>(data.filter(dataItem => dataItem.ship_age > 0), stepAmount, shipAge, splitFunction);

    const splitSteppedAverageData = splitGroupedGraphData<GroupedItem<FleetGraphDataItem>>(groupedBackgroundData, groupedItemRange, splitFunction);

    const steppedAverages = splitSteppedAverageData.map((group) => {
        return buildGraphDataForStepAverage<FleetGraphDataItem>(group, shipGrade)
    });

    const steppedAverageGraphData = steppedAverages.map((steppedAverage, index) => {
        return {
                x: steppedAverage.x,
                y: steppedAverage.y,
                name: t("fleetGraph.benchmarkAverage"),
                mode: 'text+lines',
                visible: true,
                legendgroup: "bg",
                line: {
                    dash: 'dash',
                    width: 2,
                    color: '#777777'
                },
                showlegend: index === 0
            }
        }
    );

    return steppedAverageGraphData;
}

export const getGradeAverageData = (
    data: FleetGraphDataItem[], backgroundData: FleetGraphDataItem[], gradeAverage: number) => {
    const averageMaxXAxis = Math.max(...data.map(x => x.ship_age), ...backgroundData.map(x => x.ship_age)) + 1;

    const fleetAverage : Data = {
        x: [0, averageMaxXAxis+1],
        y: [gradeAverage, gradeAverage],
        mode: 'lines',
        legendgroup: "fleet",
        name: t("fleetGraph.fleetAverage"),
        line: {
            color: '#28235B'
        }
    };

    return fleetAverage;
}

export const resetGraphIcon = {
    'width':  1000,
    'height': 1000,
    'path': 'm250 850l-187 0-63 0 0-62 0-188 63 0 0 188 187 0 0 62z m688 0l-188 0 0-62 188 0 0-188 62 0 0 188 0 62-62 0z m-875-938l0 188-63 0 0-188 0-62 63 0 187 0 0 62-187 0z m875 188l0-188-188 0 0-62 188 0 62 0 0 62 0 188-62 0z m-125 188l-1 0-93-94-156 156 156 156 92-93 2 0 0 250-250 0 0-2 93-92-156-156-156 156 94 92 0 2-250 0 0-250 0 0 93 93 157-156-157-156-93 94 0 0 0-250 250 0 0 0-94 93 156 157 156-157-93-93 0 0 250 0 0 250z',
    'transform': 'matrix(1 0 0 -1 0 850)'
};

export const hasNoGradeData = (dataItem: FleetGraphDataItem, mode: FleetModeGraph) => {
    let noGradeData = false;

    switch (mode) {
        case "grade":
            noGradeData = !dataItem.grade && !dataItem.grade_min && !dataItem.grade_max;
            break;
        case "condition":
            noGradeData = !dataItem.condition_score;
            break;
        case "management":
            noGradeData = !dataItem.management_score;
            break;
    }

    return noGradeData;
}

export function groupByTechnicalManager(items: FleetGraphDataItem[]): Record<string, FleetGraphDataItem[]> {
    return items.reduce((acc, item) => {
        const manager = item.technical_manager;
        if (!acc[manager]) {
            acc[manager] = [];
        }
        acc[manager].push(item);
        return acc;
    }, {} as Record<string, FleetGraphDataItem[]>);
}