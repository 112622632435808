import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeywordSearch } from '../../types/KeywordSearch';
import { DataTableFilterData } from '../../types/DataTableFilterData';
import { defaultFleetFiltersConfig } from '../../components/FleetTable/FilterTemplates';
import { defaultSubgradeFiltersConfig } from '../../components/SubGrades/FilterTemplates';
import { defaultDefectsSummaryFilterConfig } from '../../components/DefectsSummary/DefectsSummaryConfig';
import { defaultDefectsFiltersConfig, defaultSharedDefectsFiltersConfig } from '../../components/DefectsTable/Filters/FilterTemplates';
import { GlobalFilterInspectionDateRange, GlobalFilterValue } from '../../types/GlobalFilterValue';
import { GlobalDateRange } from '../../components/Filter/GlobalDateRange';
import { transformDateRangeCode } from '../../utils/transformDateRangeCode';
import { DataTableFilterMeta } from 'primereact/datatable';
import { HeaderType } from '../../types/HeaderType';
import { GraphFilterType, SubgradeGraphFilterType } from "../../components/GraphFilterSelector/GraphFilterSelector";

export const defaultGlobalInspectionDateRange: GlobalFilterInspectionDateRange = {
    code: GlobalDateRange.ALL_TIME,
    dateRange: transformDateRangeCode(GlobalDateRange.ALL_TIME)
}

export interface FiltersState {
    globalFilters: GlobalFilterValue;
    globalFiltersLoading: boolean;
    keywordSearch: KeywordSearch;
    selectedVesselTypes: string[];
    selectedVesselAgeBands: string[];
    selectedTechnicalManagers: string[];
    selectedCountries: string[];
    selectedVessels: string[];
    selectedMOUs: string[];
    dataTableFilterData: DataTableFilterData;
    graphContextFilter: GraphFilterType;
    subgradeGraphContextFilter: SubgradeGraphFilterType;
    defectGraphContextFilter : SubgradeGraphFilterType;
}

export const initialState: FiltersState = {
    globalFilters: {
        vessels: [],
        vesselTypes: [],
        vesselAgeBands: [],
        technicalManagers: [],
    },
    globalFiltersLoading: true,
    keywordSearch: { table: "", defects: "", subGrade: "", defectsSummary: "", sharedDefects: "" },
    selectedVesselTypes: [],
    selectedTechnicalManagers: [],
    selectedVesselAgeBands: [],
    selectedCountries: [],
    selectedVessels: [],
    selectedMOUs: [],
    dataTableFilterData: {
        table: defaultFleetFiltersConfig,
        subGrade: defaultSubgradeFiltersConfig,
        defects: defaultDefectsFiltersConfig,
        defectsSummary: defaultDefectsSummaryFilterConfig,
        sharedDefects: defaultSharedDefectsFiltersConfig
    },
    graphContextFilter: GraphFilterType.VESSELS,
    subgradeGraphContextFilter: SubgradeGraphFilterType["All Areas"],
    defectGraphContextFilter: SubgradeGraphFilterType["All Areas"]
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setGlobalFilters: (state, action: PayloadAction<GlobalFilterValue>) => {
            state.globalFilters = action.payload;
        },
        setGlobalFiltersLoading: (state, action: PayloadAction<boolean>) => {
            state.globalFiltersLoading = action.payload;
        },
        setKeywordSearch: (state, action: PayloadAction<KeywordSearch>) => {
            state.keywordSearch = action.payload;
        },
        setKeywordSearchViaHeaderType: (state, action: PayloadAction<{ keyword: string, headerType: HeaderType }>) => {
            const { keyword, headerType } = action.payload;
            let updated = {};
    
            if (headerType == HeaderType.TABLE) {
                updated = {
                    table: keyword
                };
            }
            if (headerType == HeaderType.DEFECTS) {
                updated = {
                    defects: keyword
                };
    
            }
            if (headerType == HeaderType.SUB_GRADE) {
                updated = {
                    subGrade: keyword
                };
            }
    
            if (headerType == HeaderType.DEFECT_SUMMARY) {
                updated = {
                    defectsSummary: keyword
                }
            }

            if (headerType == HeaderType.SHARED_DEFECTS) {
                updated = {
                    sharedDefects: keyword
                }
            }

            state.keywordSearch = {
                ...state.keywordSearch,
                ...updated
            };
        },
        setSelectedVesselTypes: (state, action: PayloadAction<string[]>) => {
            state.selectedVesselTypes = action.payload;
        },
        setSelectedTechnicalManagers: (state, action: PayloadAction<string[]>) => {
            state.selectedTechnicalManagers = action.payload;
        },
        setSelectedVesselAgeBands: (state, action: PayloadAction<string[]>) => {
            state.selectedVesselAgeBands = action.payload;
        },
        setSelectedCountries: (state, action: PayloadAction<string[]>) => {
            state.selectedCountries = action.payload;
        },
        setSelectedVessels: (state, action: PayloadAction<string[]>) => {
            state.selectedVessels = action.payload;
        },
        setSelectedMOUs: (state, action: PayloadAction<string[]>) => {
            state.selectedMOUs = action.payload;
        },
        setDataTableFilterData: (state, action: PayloadAction<DataTableFilterData>) => {
            state.dataTableFilterData = action.payload;
        },
        setFleetTableFilterData: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.dataTableFilterData = {
                ...state.dataTableFilterData,
                table: action.payload
            };
        },
        setDefectsTableFilterData: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.dataTableFilterData = {
                ...state.dataTableFilterData,
                defects: action.payload
            };
        },
        setDefectsSummaryTableFilterData: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.dataTableFilterData = {
                ...state.dataTableFilterData,
                defectsSummary: action.payload
            };
        },
        setSubGradeTableFilterData: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.dataTableFilterData = {
                ...state.dataTableFilterData,
                subGrade: action.payload
            };
        },
        setSharedDefectsTableFilterData: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.dataTableFilterData = {
                ...state.dataTableFilterData,
                sharedDefects: action.payload
            }
        },
        setGraphContextFilter: (state, action: PayloadAction<number>) => {
            state.graphContextFilter = action.payload
        },
        setSubGradeGraphContextFilter: (state, action: PayloadAction<number>) => {
            state.subgradeGraphContextFilter = action.payload
        },
        setDefectGraphContextFilter: (state, action: PayloadAction<number>) => {
            state.defectGraphContextFilter = action.payload
        }

    }
});

export const {
    setGlobalFilters,
    setGlobalFiltersLoading,
    setKeywordSearch,
    setKeywordSearchViaHeaderType,
    setSelectedVesselTypes,
    setSelectedTechnicalManagers,
    setSelectedVesselAgeBands,
    setSelectedCountries,
    setSelectedVessels,
    setSelectedMOUs,
    setDataTableFilterData,
    setFleetTableFilterData,
    setDefectsTableFilterData,
    setDefectsSummaryTableFilterData,
    setSharedDefectsTableFilterData,
    setSubGradeTableFilterData,
    setGraphContextFilter,
    setSubGradeGraphContextFilter,
    setDefectGraphContextFilter
} = filtersSlice.actions;

export default filtersSlice.reducer;
