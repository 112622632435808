import { useTranslation } from "react-i18next";
import { ScreeningVesselFinder } from "../components/Screening/ScreeningVesselFinder";
import { useAppSelector } from "../hooks/storeHooks";
import { selectScreeningData, selectScreeningLoading } from "../store/selectors/screeningSelectors";
import { ScreeningTable } from "../components/Screening/ScreeningTable";
import { Card } from "primereact/card";

const Screening = () => {
    const { t } = useTranslation("locale");
    
    const data = useAppSelector(selectScreeningData);
    const loading = useAppSelector(selectScreeningLoading);

    if (!data.length && !loading) {

        return (
            <Card>
                <div className="flex flex-column justify-content-center align-items-center p-5">
                    <h2 className="font-semibold">{t("screening.noVesselsAdded")}</h2>
                    <p className="p-3">{t("screening.searchVesselsOfInterest")}</p>
                    <ScreeningVesselFinder />
                </div>
            </Card>
        )
    }

    return (
        <div className="p-5 bg-white">
            <ScreeningTable />
        </div>
    );
};

export default Screening;
