import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";

export const PSCDetainedFilter = (options: ColumnFilterElementTemplateOptions) => {
    const detainedOptions = useMemo(() => ([
        { label: "Yes", value: "Y" },
        { label: "No", value: "N" },
    ]), []);

    const onChange = useCallback((e: MultiSelectChangeEvent) => {
        options.filterApplyCallback(e.value)
    }, [options])

    return (
        <MultiSelect
            value={options.value}
            options={detainedOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};

