import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { PSCTabs } from "../../types/psc";
import { getSummaryAggregates } from "../../utils/PSCDataHelper";
import { classNames } from "primereact/utils";
import { useCallback, useMemo } from "react";
import { setActiveTab, setDeficienciesFilters, setGlobalFilterValue, setInspectionsFilters } from "../../store/slices/pscSlice";
import { pscInspectionFilters } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";

export const PSCPerformanceAggregates = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("locale");
    const { summaryData, activeTab } = useAppSelector(selectPSC);

    const aggregates = useMemo(() => 
        getSummaryAggregates(summaryData)
    , [summaryData]);

    const onClickInspections = useCallback(() => {
    if (aggregates.inspectionsTotal) {
            dispatch(setInspectionsFilters(pscInspectionFilters));
            dispatch(setActiveTab(PSCTabs.inspections));
            dispatch(setGlobalFilterValue(""))
        }
    }, [aggregates.inspectionsTotal]);

    const onClickDeficiencies = useCallback(() => {
        if (aggregates.deficienciesTotal) {
            dispatch(setDeficienciesFilters(pscInspectionFilters));
            dispatch(setActiveTab(PSCTabs.deficiencies));
            dispatch(setGlobalFilterValue(""))
        }
    }, [aggregates.deficienciesTotal]);

    const onClickDetentions = useCallback(() => {
        if (aggregates.detentionsTotal) {
            dispatch(setInspectionsFilters({
                ...pscInspectionFilters,
                detained: {
                    value: ["Y"],
                    matchMode: FilterMatchMode.IN,
                }
            }));
            dispatch(setActiveTab(PSCTabs.inspections));
            dispatch(setGlobalFilterValue(""))
        }
    }, [aggregates.detentionsTotal]);

    if (activeTab !== PSCTabs.performance) {
        return null;
    }
    
    return (
        <div className="grid flex mt-3 mr-0 ml-0 mb-3 gap-3">
            <div className="col-4 md:col p-0">
                <span className="psc-aggregate-label">{t("psc.inspectionsTotal")}</span>
                <span 
                    className={classNames(aggregates.inspectionsTotal ? "cursor-pointer read-more-btn underline" : "font-normal", "psc-aggregate-count mt-1")}
                    onClick={onClickInspections}
                >
                    {aggregates.inspectionsTotal}
                </span>
            </div>
            <div className="col-4 md:col p-0">
                <span className="psc-aggregate-label">{t("psc.deficienciesTotal")}</span>
                <span 
                    className={classNames(aggregates.deficienciesTotal ? "cursor-pointer read-more-btn underline" : "font-normal", "psc-aggregate-count mt-1")}
                    onClick={onClickDeficiencies}
                >
                    {aggregates.deficienciesTotal}
                </span>
            </div>
            <div className="col-4 md:col p-0">
                <span className="psc-aggregate-label">{t("psc.detentionsTotal")}</span>
                <span
                    className={classNames(aggregates.detentionsTotal ? "cursor-pointer read-more-btn underline" : "font-normal", "psc-aggregate-count mt-1")}
                    onClick={onClickDetentions}
                >
                    {aggregates.detentionsTotal}
                </span>
            </div>
            <div className="col-4 md:col p-0">
                <span className="psc-aggregate-label">{t("psc.deficiencyPerInspection")}</span>
                <span className={classNames(aggregates.deficiencyPerInspection ? "font-semibold" : "font-normal", "psc-aggregate-count mt-1")}>{aggregates.deficiencyPerInspection}</span>
            </div>
            <div className="col-4 md:col p-0">
                <span className="psc-aggregate-label">{t("psc.detentionRate")}</span>
                <span className={classNames(aggregates.detentionRate ? "font-semibold" : "font-normal", "psc-aggregate-count mt-1")}>{aggregates.detentionRate}%</span>
            </div>
        </div>
    )
};