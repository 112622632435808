import { useTranslation } from "react-i18next";
import { PSCInspection } from "../../types/psc";
import { classNames } from "primereact/utils";

export const PSCDetainedBody = (item: PSCInspection) => {
    const { t } = useTranslation("locale");

    if (item.detained === undefined) {
        return null;
    }

    const isDetained = item.detained === "Y";

    return (
        <span 
            className={classNames({ "psc-detained-yes": isDetained})}>
                {isDetained ? t("psc.yes") : t("psc.no")}
        </span>
    );
}