import { VesselViewDetails } from "../components/VesselView/VesselViewSidebar";
import { VesselViewState } from "../store/slices/vesselViewSlice";
import { ShipParticulars } from "../types/Paraticulars";

export const toVesselViewProps = (
    vesselViewState: VesselViewState
): VesselViewDetails => {
    const { 
        selectedVessel,
        vesselParticulars,
        vesselParticularsLoading,
        vesselImage,
        vesselImageLoading,
        vesselImageError,
    } = vesselViewState;
    
    if (selectedVessel) {
        return {
            vesselName: selectedVessel.vessel.vesselName,
            vesselType: selectedVessel.vessel.type,
            technicalManager: selectedVessel.vessel.technicalManager,
            latestInspection:  selectedVessel.inspection.lastInspected ?
                new Date(selectedVessel.inspection.lastInspected).toLocaleDateString("en-gb") : undefined,
            idwalGrade: selectedVessel.inspection.idwalGrade,
            imoNumber: selectedVessel.vessel.imo,
            scoreHistory: selectedVessel.scoreHistory,
            particularsData: vesselParticulars ? Object.keys(vesselParticulars).map((key: string) => {
                    return [key, vesselParticulars[key as keyof ShipParticulars]]
            }) : undefined,
            particularsLoading: vesselParticularsLoading,
            vesselImageUrl: vesselImage,
            vesselImageLoading: Boolean(vesselImage && vesselImageLoading),
            noVesselImageAvailable: Boolean(!vesselImage || vesselImageError),
            nextInspectionDetails: {
                isScheduled: selectedVessel.inspection.isScheduled,
                status: selectedVessel.inspection.scheduledInspectionStatus,
                dateFrom: selectedVessel.inspection.dateFrom,
                dateTo: selectedVessel.inspection.dateTo,
                inspectionDue: selectedVessel.inspection.inspectionDue,
                hasUpcoming: selectedVessel.inspection.hasUpcoming
            }
        }
    }
    
    return {
        vesselName: "",
        vesselType: "",
        technicalManager: "",
        imoNumber: "",
        particularsData: undefined,
        particularsLoading: false,
        vesselImageLoading: false,
        noVesselImageAvailable: false,
        nextInspectionDetails: {}
    }
}