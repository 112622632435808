import { StatsCardProps } from "@idwal/idwal-react-components/dist/types/StatsCard";
import { getTranslationObject } from "../services/Translation";
import { StatTileConfig } from "../types/StatTileConfig";
import { MAX_VISIBLE_TILES, STAT_TILE_DUE_INSPECTION, STAT_TILE_ID_INSPECTIONS_COMPLETED, STAT_TILE_OVERDUE } from "./Constants";
import { StatsCard } from "@idwal/idwal-react-components";
import { FleetDataItem } from "../types/FleetDataItem";
import { getFleetStats } from "../services/FleetDataService";

const t = getTranslationObject();

export const getInspectionsCompletedInContractYear = async () =>  {
    try {
        const stats = await getFleetStats();

        const { inspectionYTD } = stats;

        if (inspectionYTD) {
            return {
                data: inspectionYTD,
                tileKey: STAT_TILE_ID_INSPECTIONS_COMPLETED,
                statistic: "x" + inspectionYTD,
                details: t("statsCards.contractYear"),
                icon: "inspections-completed-icon",
                additionalData: "details",
            }
        }
    } catch (e) {
        console.error(e)
    }
}

export const mapToStatsTiles = (
    statTileConfigs: StatTileConfig[] | undefined,
    onRequestInspectionDialog: (vessels: FleetDataItem[]) => void,
) => {
    if (statTileConfigs?.length) {
        const colSize = 12 / Math.min(MAX_VISIBLE_TILES, statTileConfigs.length);

        return statTileConfigs.map((tile: any, index: number) => {
            let onClick;

            if (tile.tileKey == STAT_TILE_OVERDUE || tile.tileKey == STAT_TILE_DUE_INSPECTION) {
                onClick = (props: StatsCardProps)=> {
                    if (props.data) {
                        onRequestInspectionDialog(props.data);
                    }
                }
            }
        
            return buildStatsTile({
                tileKey: tile.tileKey,
                statistic: tile.statistic,
                statisticTitle: t(`statsCards.${tile.tileKey}`), statisticDetails: t(tile.details),
                statisticSubText: tile.statisticSubText ? t(`statsCards.${tile.statisticSubText}`) : undefined,
                statisticFontSize: tile.statisticFontSize,
                icon: tile.icon,
                color: tile.colour,
                onClick: onClick,
                data: tile.additionalData
            }, index, colSize);
        })
    }

    return [];
};

export const buildStatsTile = (props: StatsCardProps, index: number, colSize: number) => {
    return (
        <div key={props.tileKey} className={`stats-tile col-12 md:col-6 lg:col-${colSize}`} data-cy={`stats-tile-${index}`}>
            <StatsCard
                key={props.tileKey}
                statistic={props.statistic}
                statisticFontSize={props.statisticFontSize}
                statisticSubText={props.statisticSubText}
                statisticTitle={props.statisticTitle}
                statisticDetails={props.statisticDetails}
                icon={props.icon}
                color={props.color}
                data={props.data}
                onClick={props.onClick}
            />
        </div>
    );  
};