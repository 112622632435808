import { TabPanel, TabView, TabViewTabChangeEvent } from "primereact/tabview";
import { useAppSelector } from "../hooks/storeHooks";
import { selectPSCActiveTab } from "../store/selectors/pscSelector";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../store/slices/pscSlice";
import { sendGAEvent } from "../AnalyticsService";
import { PSC, PSC_DEFICIENCIES_TAB_CLICK, PSC_INSPECTIONS_TAB_CLICK, PSC_PERFORMANCE_TAB_CLICK } from "../constants/analytics";
import { PSCTabs } from "../types/psc";
import { PSCPerformanceTable } from "../components/PSC/PSCPerformanceTable";
import { PSCInspectionsTable } from "../components/PSC/PSCInspectionsTable";
import { PSCDeficienciesTable } from "../components/PSC/PSCDeficienciesTable";

const PortStateControl = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("locale");

    const activeTab = useAppSelector(selectPSCActiveTab);

    const onTabChange = (e: TabViewTabChangeEvent) => {
        dispatch(setActiveTab(e.index));

        switch (e.index) {
            case PSCTabs.performance:
                sendGAEvent(PSC, PSC_PERFORMANCE_TAB_CLICK);
                break;
            case PSCTabs.inspections:
                sendGAEvent(PSC, PSC_INSPECTIONS_TAB_CLICK);
                break;
            case PSCTabs.deficiencies:
                sendGAEvent(PSC, PSC_DEFICIENCIES_TAB_CLICK);
                break;
            default:
                break;
        }
    };

    return (
        <TabView renderActiveOnly activeIndex={activeTab} onTabChange={onTabChange} className="tab-container">
            <TabPanel header={t("psc.pscPerformance")}>
                <PSCPerformanceTable />
            </TabPanel>
            <TabPanel header={t("psc.pscInspections")}>
                <PSCInspectionsTable />
            </TabPanel>
            <TabPanel header={t("psc.pscDeficiencies")}>
                <PSCDeficienciesTable />
            </TabPanel>
        </TabView>
    )
};

export default PortStateControl;