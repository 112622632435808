import { getTranslationObject } from "../../services/Translation";
import { GraphFilterType, SubgradeGraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";

const t = getTranslationObject();

export const graphFilterDropdownOptions = () => {
    return [
        {
            name: t("fleetGraph.graphFilterSelector.vessels"),
            code: GraphFilterType.VESSELS
        },
        {
            name: t("fleetGraph.graphFilterSelector.technicalManagers"),
            code: GraphFilterType.TECHNICAL_MANAGERS
        }
    ];
};

export const subgradeGraphFilteringOptions = () => {
    return [
        {
            name: t("subgrades.areas.allAreas"),
            code: SubgradeGraphFilterType["All Areas"]
        },
        {
            name: t("subgrades.areas.bridgeAndNavigationEquipment"),
            code: SubgradeGraphFilterType["Bridge and Navigation Equipment"]
        },
        {
            name: t("subgrades.areas.accomodation"),
            code: SubgradeGraphFilterType.Accommodation
        },
        {
            name: t("subgrades.areas.lifesavingAppliances"),
            code: SubgradeGraphFilterType["Lifesaving Appliances"]
        },
        {
            name: t("subgrades.areas.mooringDecks"),
            code: SubgradeGraphFilterType["Mooring Decks"]
        },
        {
            name: t("subgrades.areas.engineRoomAndMachinery"),
            code: SubgradeGraphFilterType["Engine Room and Machinery"]
        },
        {
            name: t("subgrades.areas.vesselCapabilitiesAndCargoSystems"),
            code: SubgradeGraphFilterType["Vessel Capabilities and Cargo Systems"]
        },
        {
            name: t("subgrades.areas.ballastTankAndSystems"),
            code: SubgradeGraphFilterType["Ballast Tanks and Systems"]
        },
        {
            name: t("subgrades.areas.weatherDecksAndFittings"),
            code: SubgradeGraphFilterType["Weather Decks and Fittings"]
        },
        {
            name: t("subgrades.areas.hull"),
            code: SubgradeGraphFilterType.Hull
        },
        {
            name: t("subgrades.areas.pollutionControl"),
            code: SubgradeGraphFilterType["Pollution Control"]
        },
        {
            name: t("subgrades.areas.onboardManagement"),
            code: SubgradeGraphFilterType["Onboard Management"]
        },
        {
            name: t("subgrades.areas.safeWorkingEnvironment"),
            code: SubgradeGraphFilterType["Safe Working Environment"]
        },
        {
            name: t("subgrades.areas.forthcomingRegulatoryCompliance"),
            code: SubgradeGraphFilterType["Forthcoming Regulatory Compliance"]
        },
        {
            name: t("subgrades.areas.crewWelfare"),
            code: SubgradeGraphFilterType["Crew Welfare"]
        },
        {
            name: t("subgrades.areas.crewPerformance"),
            code: SubgradeGraphFilterType["Crew Performance"]
        },
        {
            name: t("subgrades.areas.safetyManagement"),
            code: SubgradeGraphFilterType["Safety Management"]
        },
        {
            name: t("subgrades.areas.plannedMaintenanceSystem"),
            code: SubgradeGraphFilterType["Planned Maintenance System (PMS)"]
        },
        {
            name: t("subgrades.areas.classificationAndCertification"),
            code: SubgradeGraphFilterType["Classification and Certification"]
        },
        {
            name: t("subgrades.areas.pscPerformance"),
            code: SubgradeGraphFilterType["PSC Performance"]
        },
        {
            name: t("subgrades.areas.fireFightingEquipmentAndSystems"),
            code: SubgradeGraphFilterType["Fire Fighting Equipment and Systems"]
        },
        {
            name: t("subgrades.areas.designAndConstruction"),
            code: SubgradeGraphFilterType["Design and Construction"]
        }
    ];
};

export const mapToFilteringOptions = (
    names: Array<string>,
    filteringOptions: Array<{ name: string; code: SubgradeGraphFilterType }>
): Array<{name: string, code: number}> => {
    const enumMap = Object.entries(SubgradeGraphFilterType).reduce((map, [key, value]) => {
        if (typeof value === "number") {
            map[key.toLowerCase()] = value;
        }
        return map;
    }, {} as Record<string, SubgradeGraphFilterType>);

    return names.map(name => {
        const filterCode = enumMap[name.toLowerCase()];
        return filteringOptions.find(option => option.code === filterCode) || null;
    }).filter(Boolean) as Array<{name: string, code: number}>; // Have to use cast here, ther compiler doesnt acknowledge the boolean filter 
};