import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { setInspectionsFilters, setInspectionsPage } from "../../store/slices/pscSlice";
import { PSCTableHeader } from "./PSCTableHeader";
import { pscInspectionsColumns, pscGlobalFilterFields } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";

export const PSCInspectionsTable = () => {
    const dispatch = useAppDispatch();

    const {
        filteredInspections,
        loading,
        globalFilterValue,
        inspectionsFilters,
        inspectionsPage,
    } = useAppSelector(selectPSC);

    const onPage = useCallback((event: DataTableStateEvent) => {
        dispatch(setInspectionsPage({ row: event.rows, first: event.first}))
    }, []);

    const onFilter = useCallback((event: DataTableStateEvent) => {
        dispatch(setInspectionsFilters(event.filters));
    }, []);

    const renderHeader = useMemo(() => (
        <PSCTableHeader />
    ), [globalFilterValue]);

    const columns = useMemo(() => (
        pscInspectionsColumns()
    ), [filteredInspections]);

    const rowOptions = useMemo(() => 
        [5, 10, 25, 50]
    , []);

    const globalFilterFields = useMemo(() => 
        pscGlobalFilterFields
    , []);
    
    return (
        <DataTable 
            value={filteredInspections}
            loading={loading}
            filters={inspectionsFilters}
            onFilter={onFilter}
            globalFilter={globalFilterValue}
            globalFilterFields={globalFilterFields}
            globalFilterMatchMode={FilterMatchMode.CONTAINS}
            data-cy="psc-inspections-table"
            scrollHeight="55vh"
            filterDisplay="row"
            sortField="date"
            sortOrder={-1}
            paginator
            scrollable
            removableSort
            stripedRows
            first={inspectionsPage.first}
            rows={inspectionsPage.row}
            rowsPerPageOptions={rowOptions}
            onPage={onPage}
            header={renderHeader}
        >
            {columns}
        </DataTable>
    )
};