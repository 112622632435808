import { applyGlobalFilter } from "../../components/Shared/GlobalFilter";
import {
    getPSCDeficienciesData,
    getPSCInspectionsData
} from "../../services/PSCService";
import {
    selectGlobalFilters,
    selectSelectedMOUs
} from "../selectors/filtersSelectors";
import { selectFleetDataItems } from "../selectors/fleetSelectors";
import { selectPSC } from "../selectors/pscSelector";
import { selectUserToken } from "../selectors/userSelectors";
import {
    setSummaryData,
    setInspectionsData,
    setDeficienciesData,
    setError,
    setLoading,
    setTimePeriod,
    setFilteredInspections,
    setFilteredDeficiencies,
    setGlobalFilteredIMOs,
    setDistinctMOUs
} from "../slices/pscSlice";
import { AppThunk } from "../store";
import {
    filterInspectionData,
    mapPSCDeficienciesData,
    mapPSCSummaryData,
    mapPSCInspectionData,
    fillPSCSummaryData
} from "../../utils/PSCDataHelper";
import { uniq } from "lodash";

export const fetchPSCInspections =
    (): AppThunk => async (dispatch, getState) => {
        const userToken = selectUserToken(getState());

        if (userToken) {
            try {
                const response = await getPSCInspectionsData(userToken);

                if (response?.length) {
                    dispatch(setInspectionsData(response));
                } else {
                    throw Error("no data");
                }
            } catch (e) {
                console.error(e);
                dispatch(setError(true));
            }
        }
    };

export const fetchPSCDeficiencies =
    (): AppThunk => async (dispatch, getState) => {
        const userToken = selectUserToken(getState());

        if (userToken) {
            try {
                const response = await getPSCDeficienciesData(userToken);

                if (response?.length) {
                    dispatch(setDeficienciesData(response));
                } else {
                    throw Error("no data");
                }
            } catch (e) {
                console.error(e);
                dispatch(setError(true));
            }
        }
    };

export const setupPSCVesselData = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const { inspectionsData, deficienciesData } = selectPSC(state);
    const fleetDataItems = selectFleetDataItems(state);

    const mappedInspectionsData = mapPSCInspectionData(
        inspectionsData,
        fleetDataItems
    );
    const mappedDeficienciesData = mapPSCDeficienciesData(
        deficienciesData,
        mappedInspectionsData
    );
    const mappedSummaryData = mapPSCSummaryData(mappedInspectionsData);
    const filledSummaryData = fillPSCSummaryData(
        mappedSummaryData,
        fleetDataItems
    );

    dispatch(setInspectionsData(mappedInspectionsData));
    dispatch(setDeficienciesData(mappedDeficienciesData));
    dispatch(setSummaryData(filledSummaryData));

    const inspectionMOUs: string[] = [];

    mappedInspectionsData.forEach((inspection) => {
        if (inspection.mou) {
            inspectionMOUs.push(inspection.mou);
        }
    });

    dispatch(setDistinctMOUs(uniq(inspectionMOUs)));
    dispatch(setupFilteredPSC());
    dispatch(setLoading(false));
};

export const setupFilteredPSC = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const fleetDataItems = selectFleetDataItems(state);
    const globalFilters = selectGlobalFilters(state);

    if (fleetDataItems.length) {
        const filteredData = fleetDataItems.filter((item) =>
            applyGlobalFilter(
                globalFilters,
                item.vessel.vesselName,
                item.vessel.type,
                item.vessel.technicalManager,
                item.vessel.shipAge
            )
        );

        const imos = filteredData.map((item) => Number(item.vessel.imo));
        dispatch(setGlobalFilteredIMOs(imos));
        dispatch(setupPSCSummary());
    }
};

export const setupPSCSummary = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const {
        inspectionsData,
        deficienciesData,
        globalFilteredIMOs,
        distinctMOUs,
        timePeriod
    } = selectPSC(state);
    const selectedMOUs = selectSelectedMOUs(state);
    const fleetDataItems = selectFleetDataItems(state);

    const mous = selectedMOUs.length ? selectedMOUs : distinctMOUs;

    const filteredInspections = filterInspectionData(
        inspectionsData,
        globalFilteredIMOs,
        mous,
        timePeriod
    );
    const filteredDeficiencies = filterInspectionData(
        deficienciesData,
        globalFilteredIMOs,
        mous,
        timePeriod
    );
    const mappedSummaryData = mapPSCSummaryData(filteredInspections);

    dispatch(setTimePeriod(timePeriod));
    dispatch(setFilteredInspections(filteredInspections));
    dispatch(setFilteredDeficiencies(filteredDeficiencies));

    const filteredFleet = fleetDataItems.filter((fleetDataItem) =>
        globalFilteredIMOs.includes(Number(fleetDataItem.vessel.imo))
    );
    const filledSummaryData = fillPSCSummaryData(
        mappedSummaryData,
        filteredFleet
    );
    dispatch(setSummaryData(filledSummaryData));
};
