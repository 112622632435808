import { getAgeRangeLabels } from "../components/Filter/GlobalAgeRangeBands";
import { GlobalFilterValue } from "../types/GlobalFilterValue";

export enum GraphType {
    GRADE,
    SUBGRADE,
    CONDITION_GRADE,
    MANAGEMENT_GRADE
}

export interface BuildGradeGraphTitleProps {
    mode: GraphType,
    globalFilterState: GlobalFilterValue,
    marketDataToggleSelected: boolean,
    t: any
}

export const fitlersEmpty = (
    globalFilterState: GlobalFilterValue,
): boolean => {
    return (
        globalFilterState.vesselAgeBands?.length === 0
        && globalFilterState.vesselTypes?.length === 0
        && globalFilterState.vessels?.length === 0
    )
};

export const buildVesselSelectionString = (
    t: any,
    globalFilterState: GlobalFilterValue,
    vessels?: Array<string>,
): string => {
    if (vessels?.length === 0 && fitlersEmpty(globalFilterState)) {
        return ``;
    } else if (vessels?.length === 0 && !fitlersEmpty(globalFilterState)) {
        return ` ${t("gradeGraphTitle.myVesselsOf")}`;
    }
    else if (vessels?.length === 1) {
        return ` ${vessels[0]}`
    } else {
        return ` ${vessels?.length} ${t("gradeGraphTitle.ofMyVessels")}`;
    }
};

export const buildVesselTypeSelectionString = (
    t: any,
    vesselTypes?: Array<string>,
): string => {
    if (vesselTypes?.length === 0) {
        return ``;
    } else if (vesselTypes?.length === 1) {
        return ` ${t("gradeGraphTitle.type")} ${vesselTypes[0]}`;
    } else {
        return ` ${vesselTypes?.length} ${t("gradeGraphTitle.types")}`;
    }
};

export const buildVesselAgeBandsSelectionString = (
    t: any,
    vesselAgeBands?: Array<string>,
): string => {
    if (vesselAgeBands?.length === 0) {
        return "";
    } else if (vesselAgeBands?.length === 1) {
        return ` ${t("gradeGraphTitle.age")} ${vesselAgeBands[0]} ${t("gradeGraphTitle.years")}`;
    } else {
        return ` ${vesselAgeBands?.length} ${t("gradeGraphTitle.ageRanges")}`;
    }
};

export const buildComparisionString = (mode: GraphType, t: any) => {
    if (mode === GraphType.GRADE) {
        return t("gradeGraphTitle.comparingTheIdwalInspectionGrade");
    }

    if (mode === GraphType.CONDITION_GRADE) {
        return t("gradeGraphTitle.comparingTheConditionGrade");
    }

    if (mode === GraphType.MANAGEMENT_GRADE) {
        return t("gradeGraphTitle.comparingTheManagementGrade");
    }

    return t("gradeGraphTitle.subGradeDeviationOf"); 
}

const addAverageModifier = (mode: GraphType, t: any) => 
  (mode === GraphType.SUBGRADE ? ` ${t("gradeGraphTitle.average")}` : "");

const vsModifier = (mode: GraphType, t: any) => {
    if (mode === GraphType.GRADE) {
        return t("gradeGraphTitle.vs")
    }

    return t("gradeGraphTitle.from")
}

const buildEquivalentVesselsString = (marketDataToggleSelected: boolean, mode: GraphType, t: any) => {
    return (
        marketDataToggleSelected === true 
            ? ` ${vsModifier(mode, t)} ${t("gradeGraphTitle.marketEquivalentVessels")}` + addAverageModifier(mode, t)
            : ` ${vsModifier(mode, t)} ${t("gradeGraphTitle.myFleetEquivalentVessels")}` + addAverageModifier(mode, t)
    ) 
}

const buildEmptyStateEquivalentVesselsString = (marketDataToggleSelected: boolean, mode: GraphType, t: any) => {
    return (
        marketDataToggleSelected === true 
            ? ` ${vsModifier(mode, t)} ${t("gradeGraphTitle.marketVessels")}` + addAverageModifier(mode, t)
            : ` ${vsModifier(mode, t)} ${t("gradeGraphTitle.fleetVessels")}` + addAverageModifier(mode, t)
    ) 
}

export const buildGradeGraphTitle = ({
    mode,
    globalFilterState,
    marketDataToggleSelected,
    t
}: BuildGradeGraphTitleProps): string => {
    const ageRangeLabels = globalFilterState?.vesselAgeBands && getAgeRangeLabels(
        globalFilterState.vesselAgeBands, 
        t
    )
    const right = fitlersEmpty(globalFilterState) 
        ? ` ${t("gradeGraphTitle.allMyVessels")}` + addAverageModifier(mode, t) + buildEmptyStateEquivalentVesselsString(marketDataToggleSelected, mode, t)
        : buildEquivalentVesselsString(marketDataToggleSelected, mode, t)

    const left = buildComparisionString(mode, t)
        + buildVesselSelectionString(t, globalFilterState, globalFilterState.vessels)
        + (
            (globalFilterState.vessels?.length && globalFilterState.vessels.length > 0) && 
            (
                globalFilterState.vesselTypes?.length && globalFilterState.vesselTypes.length > 0 ||
                globalFilterState.vesselAgeBands?.length && globalFilterState.vesselAgeBands.length > 0
            ) ? "," : ""
        )
        + buildVesselTypeSelectionString(t, globalFilterState.vesselTypes)
        + (
            (globalFilterState.vesselTypes?.length && globalFilterState.vesselTypes.length > 0) && 
            (globalFilterState.vesselAgeBands?.length && globalFilterState.vesselAgeBands.length > 0) ? "," : ""
        )
        + buildVesselAgeBandsSelectionString(t, ageRangeLabels)
        + (
            (!fitlersEmpty(globalFilterState) &&
              (!globalFilterState.vessels?.length ||
                (globalFilterState.vessels?.length &&
                  globalFilterState.vessels?.length > 1))
            )
            ? addAverageModifier(mode, t)
            : ""
          )
    return left + right;
};
