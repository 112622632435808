import { DefectGradingArea } from "../types/DefectGradingArea";
import { DefectDataItem } from "../types/DefectsDataItem";
import { DefectGradingAreaMapping, GENERIC_DEFECT } from "../config/DefectGradingArea";
import { sortGradingAreas } from "../config/GradingAreaOrder";
import { DEFECT_ESTIMATE_COST_RANGE } from "../types/DefectEstimateCostRange";
import { FleetDataItem } from "../types/FleetDataItem";
import { DefectStatus } from "../types/DefectStatus";
import { getTranslationObject } from "../services/Translation";
import { SharedDefectItem } from "../types/SharedDefectItem";
import { formatDefectId } from "@idwal/idwal-utilities/lib/helpers";

const t = getTranslationObject();

export function addCustomFieldDefectsFiltersToData(data: DefectDataItem[]) {
    return data.map((i) => {
    const x = {...i};
    if (x.imo) {
      x.nameFilterField = x.vesselName + " " + x.imo;
    }
    else {
      x.nameFilterField = x.vesselName + " " + x.vessel.imo;
    }

    if (x.images && x.images.length > 0) {
        x.containsImages = true;
    }
    else {
        x.containsImages = false;
    }
    x.gradingAreaField = getGradingAreaFieldValue(x.gradingArea);
    x.estimateDefectCostField = getDefectEstimateCostField(x.estimateDefectCostRangeId, x.estimateDefectCost);
    x.formattedDefectId = formatDefectId(x.defectId);

    return x;
  });
}

export const getGradingAreaFieldValue = (grades: string): string => {
  if (
    grades === null ||
    grades === undefined ||
    grades === "" ||
    grades === GENERIC_DEFECT
  ) {
    return DefectGradingAreaMapping[GENERIC_DEFECT];
  }

  return grades.split(";")
    .filter ((grade) => {
      return grade !== GENERIC_DEFECT;
    })
    .reduce((acc: string, curr: string, i: number): string => {
      if (i !== 0 ) {
        acc = acc + ", ";
      }
      acc = acc + DefectGradingAreaMapping[curr as keyof DefectGradingArea];
      return acc;
    } , "")
};

export const getDefectEstimateCostField = (estimateDefectCostRangeId: number | null | undefined, estimateDefectCost: number | null |  undefined) => {
  if (estimateDefectCostRangeId === null || estimateDefectCostRangeId === undefined) {
    return null;
  }

  if (estimateDefectCostRangeId === DEFECT_ESTIMATE_COST_RANGE["custom"]) {
    return (estimateDefectCost !== null && estimateDefectCost !== undefined && estimateDefectCost !== 0)  ? "$" + estimateDefectCost : null;
  }

  return DEFECT_ESTIMATE_COST_RANGE[estimateDefectCostRangeId];
}

export const getGradingAreaFilterValues = (data: DefectDataItem[] | SharedDefectItem[]) => {
  const gradingAreas = data.flatMap((e: DefectDataItem | SharedDefectItem) => e.gradingArea);
  
  const gradingAreaFitlerValues = gradingAreas.flatMap((grade) => {
    if (grade == null) {
      return [];
    }

    let gradeArray = grade.split(";");

    if (gradeArray.length > 1 && gradeArray.includes(GENERIC_DEFECT)) {
      gradeArray = gradeArray.filter((grade) => {
        return grade !== GENERIC_DEFECT;
      });
    }

    let gradeValues = gradeArray.map((f) => {
      if (f === "") {
        return DefectGradingAreaMapping[GENERIC_DEFECT]
      }

      return DefectGradingAreaMapping[f];
    });

    return gradeValues;
  });

  const distinctGradingAreaFilterValues = Array.from(new Set([...gradingAreaFitlerValues]));

  return sortGradingAreas(distinctGradingAreaFilterValues);
}

export function columnWidth(col: string) {
    let minWidth: string;
    let maxWidth: string | undefined;

    switch (col) {
        case 'description':
        case 'recommendations':
            minWidth = '16rem';
            break;
        case 'actions':
            minWidth = '7rem';
            break;
        case 'notes':
        case 'gradingArea':
            minWidth = '14rem';
            maxWidth = '16rem'; // Set maxWidth to 16rem for gradingArea
            break;
        default:
            minWidth = '12rem';
            break;
    }

    return {minWidth, maxWidth};
}

/**
 * This code merges the defect data with the data from the fleet table to give access to inspection/vessel data
 * @param response 
 * @param fleetTableData 
 * @returns 
 */
export function mergeGradesWithDefects(response: DefectDataItem[], fleetTableData: FleetDataItem[] | undefined): DefectDataItem[] | [] {   
  if (!response || !fleetTableData) {
    return [];
  }

  const defectsList: DefectDataItem[] = [];

  for (const item of response) {
    const defect = {...item};
    const shipData = fleetTableData.find(data => data.inspection?.guid === defect.inspectionId);

    if (shipData?.inspection && shipData?.vessel) {
      defect.inspection = shipData.inspection;
      defect.vessel = shipData.vessel;
      if (shipData.inspection.lastInspected) {
        defect.inspectionDate = new Date(shipData.inspection.lastInspected).toLocaleDateString("en-GB");
      }
      defect.technicalManager = shipData.vessel.technicalManager;
      defect.type = shipData.vessel.type;
      defect.subType = shipData.vessel.subtype;
      defect.idwalGrade = shipData.inspection.idwalGrade;
      defect.vesselName = shipData.vessel.vesselName;
      defect.aiGrades = shipData.aiGrades;
      defectsList.push(defect);
    }
  }

  return defectsList;
}

/**
 * Create the data for the table with all columns forwarded and with fleet table data
 * @param response 
 * @param search 
 */
export function constructDefectData(response: DefectDataItem[], fleetData: FleetDataItem[] | undefined) {
    let defectsDataSet = mergeGradesWithDefects(response, fleetData);

    defectsDataSet = addCustomFieldDefectsFiltersToData(defectsDataSet);
    defectsDataSet = sortDefectsByPriorityCostAndDate(defectsDataSet)
    
    return defectsDataSet
}

/**
 * Sorts the defect data by priority, cost, and inspection date
 * @param data Defect data array
 * @returns Sorted defect data array
 */
export function sortDefectsByPriorityCostAndDate(data: DefectDataItem[] | SharedDefectItem[]): any {
  return data.sort((a, b) => {
      if (a.priority !== b.priority) {
          return a.priority - b.priority;
      }

      const costA = getDefectEstimateCostField(a.estimateDefectCostRangeId, a.estimateDefectCost);
      const costB = getDefectEstimateCostField(b.estimateDefectCostRangeId, b.estimateDefectCost);

      const parsedCostA = parseEstimateDefectCostField(costA);
      const parsedCostB = parseEstimateDefectCostField(costB);

      if (parsedCostA && parsedCostB) {
          // Cost A has a + but B does not, so A is higher than B
          if (costA?.includes("+") && !costB?.includes("+")) {
              return -1;
          }

          // Cost B has a + but A does not, so its higher than A
          if (!costA?.includes("+") && costB?.includes("+")) {
              return 1;
          }
      }

      if (parsedCostA !== parsedCostB) {
          return parsedCostB - parsedCostA;
      }

      const dateA = a.inspectionDate ? new Date(a.inspectionDate).getTime() : Number.MAX_SAFE_INTEGER;
      const dateB = b.inspectionDate ? new Date(b.inspectionDate).getTime() : Number.MAX_SAFE_INTEGER;

      return dateA - dateB;
  });
}

/**
 * Returns a number from the estimated cost field range/value
 * @param costField Estimated cost field
 * @returns Parsed estimated defect cost
 */
export function parseEstimateDefectCostField(costField: string | null): number {
  if (costField === null || costField === undefined || costField === "0") {
    return 0;
  }

  if (costField.startsWith("<$") || costField.startsWith("+$")) {
    return parseDefectCostNumber(costField.substring(2));
  }

  if (costField.includes("-")) {
    const range = costField.split("-").map(parseDefectCostNumber);
    return Math.max(...range);
  }

  return parseDefectCostNumber(costField);
}

/**
 * Removes any symbols that may be in a cost ("+, $, comma, etc.")
 * @param value Cost value
 * @returns Parsed defect cost number
 */
export function parseDefectCostNumber(value: string): number {
  return parseFloat(value.replace(/[$,]/g, ""));
}

/**
 * Gets text status for defect stauts from number
 * @param status 
 * @returns 
 */
export const getDefectStatusValue = (status: number | string) => {
    if (status.toString() === DefectStatus.closed.toString()) {
        return t("defectsTable.defectStatusClosed")
    }
    if (status.toString() === DefectStatus.open.toString()) {
        return t("defectsTable.defectStatusOpen")
    }
    if (status.toString() === DefectStatus.rectified.toString()) {
        return t("defectsTable.defectStatusRectified")
    }
    if (status.toString() === DefectStatus.inProgress.toString()) {
        return t("defectsTable.defectStatusInProgress")
    }

    return t("defectsTable.defectStatusOpen")
}

export const mapDefectsToShare = (filteredDefects: DefectDataItem[], filteredData: DefectDataItem[] | undefined, selectedVessel: string) => {
    if (selectedVessel) {
        return filteredDefects.reduce((defectsByVessel: any[], defect: DefectDataItem) => {
            if (defect.vesselName === selectedVessel) {
                defectsByVessel.push({ 
                    defectId: defect.defectId,
                    inspectionId: defect.inspectionId,
                    inspectionNumber: defect.inspectionNumber,
                    inspectionType: defect.inspection?.inspectionType,
                    vesselName: defect.vesselName,
                    organizationName: defect.inspection.organization?.name,
                });
            }
            return defectsByVessel;
        }, []);
    } else if (filteredData?.length) {
        return filteredData.map((defect: DefectDataItem) => ({ 
            defectId: defect.defectId,
            inspectionId: defect.inspectionId,
            inspectionNumber: defect.inspectionNumber,
            inspectionType: defect.inspection?.inspectionType,
            vesselName: defect.vesselName,
            organizationName: defect.inspection.organization?.name
        }));
    }

    return [];
}
