import {sendGAEvent} from "../../AnalyticsService";
import {REPORT_VIEWED, VESSEL_VIEW} from "../../constants/analytics";
import {CUSTOMER_PORTAL_ROUTE} from "../Shared/BodyTemplates";
import {openUrl} from "../../services/WindowOpenService";

export const ReportViewClicked = (inspectionGuid) => {
    sendGAEvent(VESSEL_VIEW, REPORT_VIEWED);
    const reportLink = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${inspectionGuid}`
    openUrl(reportLink);
}
