import { Column } from "primereact/column"
import { PSCVesselBody } from "../components/PSC/PSCVesselBody";
import { FilterMatchMode } from "primereact/api";
import { PSCInspectionsBody } from "../components/PSC/PSCInspectionsBody";
import { PSCDeficienciesBody } from "../components/PSC/PSCDeficienciesBody";
import { PSCDetentionsBody } from "../components/PSC/PSCDetentionsBody";
import { PSCDateBody } from "../components/PSC/PSCDateBody";
import { PSCDeficiency, PSCInspection, PSCSummary } from "../types/psc";
import { PSCInspectionTypeBody } from "../components/PSC/PSCInspectionTypeBody";
import { PSCDetainedFilter } from "../components/PSC/PSCDetainedFilter";
import { PSCInspectionTypeFilter } from "../components/PSC/PSCInspectionTypeFilter";
import { PSCVesselTypeFilter } from "../components/PSC/PSCVesselTypeFilter";
import { PSCMOUFilter } from "../components/PSC/PSCMOUFilter";
import { PSCDurationBody } from "../components/PSC/PSCDurationBody";
import { PSCDetainedBody } from "../components/PSC/PSCDetainedBody";
import { PSCInspectionDeficienciesBody } from "../components/PSC/PSCInspectionDeficienciesBody";
import { PSCLocationBody } from "../components/PSC/PSCLocationBody";
import { PSCDefectCodeBody } from "../components/PSC/PSCDefectCodeBody";
import { PSCNatureOfDeficiencyFilter } from "../components/PSC/PSCNatureOfDeficiencyFilter";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { PSCRatioBody } from "../components/PSC/PSCRatioBody";
import { PSCReleasedBody } from "../components/PSC/PSCReleasedBody";

export const pscGlobalFilterFields = ["vessel_imo", "vessel_name"];

export const pscInspectionFilters = ({
    vessel_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    inspection_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    mou: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    location: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    detained: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    days_detained: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    vessel_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
})

export const pscDeficienciesFilters = ({
    vessel_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    main_defect_text: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    defective_item_code: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    defect_text: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    inspection_id: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    inspection_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    nature_of_defect_decode: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    mou: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    location: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    detained: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
    },
    vessel_type: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
});

export const pscPerformanceHeaders = () => (
    <ColumnGroup>
        <Row>
            <Column
                key="vessel_name"
                field="vessel_name"
                header="Vessel"
                frozen
                sortable
                headerClassName="table-right-border table-large-column"
                rowSpan={2}
            />
            <Column
                key="num_inspections"
                field="num_inspections"
                header="Inspections"
                headerClassName="table-small-column"
                sortable
                rowSpan={2}
            />
            <Column
                key="num_deficiencies"
                field="num_deficiencies"
                header="Deficiencies"
                headerClassName="table-small-column"
                sortable
                rowSpan={2}
            />
            <Column
                key="num_detentions"
                field="num_detentions"
                header="Detentions"
                sortable
                headerClassName="table-right-border table-small-column"
                rowSpan={2}
            />
            <Column
                key="deficiency_per_inspection"
                field="deficiency_per_inspection"
                header="Deficiency per inspection (DPI)"
                sortable
                headerClassName="table-small-column"
                rowSpan={2}
            />
            <Column
                key="detention_rate"
                field="detention_rate"
                header="Detention rate (DER)"
                sortable
                headerClassName="table-right-border table-small-column"
                rowSpan={2}
            />
            <Column
                header="Latest inspection"
                headerClassName="table-right-border table-no-bottom-border"
                colSpan={4}
            />
            <Column
                key="vessel_type"
                field="vessel_type"
                header="Vessel type"
                headerClassName="table-small-column"
                sortable
                rowSpan={2}
            />
        </Row>
        <Row>
            <Column
                key="date"
                field="date"
                header="Inspected"
                sortable
                headerClassName="table-small-column"
            />
            <Column
                key="detained"
                field="detained"
                header="Detained"
                headerClassName="table-small-column"
                sortable
            />
            <Column
                key="days_detained"
                field="days_detained"
                header="Duration"
                headerClassName="table-small-column"
                sortable
            />
            <Column
                key="released"
                field="released"
                header="Released"
                headerClassName="table-small-column table-right-border"
                sortable
            />
        </Row>
    </ColumnGroup>
)

export const pscPerformanceColumns = () => [
    <Column
        key="vessel_name"
        field="vessel_name"
        frozen
        body={PSCVesselBody}
        className="table-right-border"
    />,
    <Column
        key="num_inspections"
        field="num_inspections"
        body={PSCInspectionsBody}
    />,
    <Column
        key="num_deficiencies"
        field="num_deficiencies"
        body={PSCDeficienciesBody}
    />,
    <Column
        key="num_detentions"
        field="num_detentions"
        body={PSCDetentionsBody}
        className="table-right-border"
    />,
    <Column
        key="deficiency_per_inspection"
        field="deficiency_per_inspection"
        className="text-center"
    />,
    <Column
        key="detention_rate"
        field="detention_rate"
        body={(item: PSCSummary) => PSCRatioBody(item.detention_rate)}
        className="text-center table-right-border"
    />,
    <Column
        key="date"
        field="date"
        body={(item: PSCSummary) => PSCDateBody(item.date)}
    />,
    <Column
        key="detained"
        field="detained"
        body={PSCDetainedBody}
    />,
    <Column
        key="days_detained"
        field="days_detained"
        body={PSCDurationBody}
    />,
    <Column
        key="released"
        field="released"
        body={PSCReleasedBody}
        className="table-right-border"
    />,
    <Column
        key="vessel_type"
        field="vessel_type"
    />,
];

export const pscInspectionsColumns = () => [
    <Column
        key="vessel_name"
        field="vessel_name"
        header="Vessel"
        frozen
        sortable
        filter
        filterPlaceholder="Search by vessel"
        className="table-large-column"
        body={PSCVesselBody}
    />,
    <Column
        key="inspection_type"
        field="inspection_type"
        header="Inspection type"
        sortable
        filter
        filterElement={PSCInspectionTypeFilter}
        showFilterMenu={false}
        className="table-large-column"
        body={PSCInspectionTypeBody}
    />, 
    <Column
        key="date"
        field="date"
        header="Inspected"
        sortable
        className="table-small-column"
        body={(item: PSCInspection) => PSCDateBody(item.date)}
    />,
    <Column
        key="number_of_deficiencies"
        field="number_of_deficiencies"
        header="Deficiencies"
        className="table-small-column"
        sortable
        body={PSCInspectionDeficienciesBody}
    />,
    <Column
        key="mou"
        field="mou"
        header="PSC organisation"
        className="table-large-column"
        sortable
        filter
        filterElement={PSCMOUFilter}
        showFilterMenu={false}
    />,
    <Column
        key="location"
        field="location"
        header="Port of inspection"
        className="table-large-column"
        sortable
        filter
        filterPlaceholder="Search by port"
        body={PSCLocationBody}
    />,
    <Column
        key="detained"
        field="detained"
        header="Detained"
        className="table-small-column"
        sortable
        body={PSCDetainedBody}
        filter
        filterElement={PSCDetainedFilter}
        showFilterMenu={false}
    />,
    <Column
        key="days_detained"
        field="days_detained"
        header="Duration"
        className="table-small-column"
        sortable
        filter
        filterPlaceholder="Search by duration"
        body={PSCDurationBody}
        showFilterMenu={false}
    />,
    <Column
        key="released"
        field="released"
        header="Released"
        className="table-small-column"
        sortable
        body={PSCReleasedBody}
    />,
    <Column
        key="vessel_type"
        field="vessel_type"
        header="Vessel type"
        className="table-small-column"
        sortable
        filter
        filterElement={PSCVesselTypeFilter}
        showFilterMenu={false}
    />,
];

export const pscDeficienciesColumns = () => [
    <Column
        key="vessel_name"
        field="vessel_name"
        header="Vessel"
        frozen
        sortable
        filter
        filterPlaceholder="Search by vessel"
        className="table-large-column"
        body={PSCVesselBody}
    />,
    <Column
        key="main_defect_text"
        field="main_defect_text"
        header="Deficiency area"
        sortable
        filter
        filterPlaceholder="Search by deficiency code"
        className="table-large-column"
    />,
    <Column
        key="defective_item_code"
        field="defective_item_code"
        header="Defect code"
        sortable
        filter
        filterPlaceholder="Search by defect code"
        className="table-large-column"
        body={PSCDefectCodeBody}
    />,
    <Column
        key="defect_text"
        field="defect_text"
        header="Defective item"
        sortable
        filter
        filterPlaceholder="Search by defective item"
        className="table-large-column"
    />,
    <Column
        key="nature_of_defect_decode"
        field="nature_of_defect_decode"
        header="Nature of deficiency"
        sortable
        filter
        className="table-large-column"
        filterElement={PSCNatureOfDeficiencyFilter}
    />,
    <Column
        key="date"
        field="date"
        header="Inspected"
        sortable
        className="table-small-column"
        body={(item: PSCDeficiency) => PSCDateBody(item.date)}
    />,
    <Column
        key="inspection_type"
        field="inspection_type"
        header="Inspection type"
        sortable
        filter
        filterElement={PSCInspectionTypeFilter}
        showFilterMenu={false}
        className="table-large-column"
        body={PSCInspectionTypeBody}
    />, 
    <Column
        key="mou"
        field="mou"
        header="PSC organisation"
        className="table-large-column"
        sortable
        filter
        filterElement={PSCMOUFilter}
        showFilterMenu={false}
    />,
    <Column
        key="location"
        field="location"
        header="Port of inspection"
        className="table-large-column"
        sortable
        filter
        filterPlaceholder="Search by port"
        body={PSCLocationBody}
    />,
    <Column
        key="vessel_type"
        field="vessel_type"
        header="Vessel type"
        className="table-small-column"
        sortable
        filter
        filterElement={PSCVesselTypeFilter}
        showFilterMenu={false}
    />,
];
