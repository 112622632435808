import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../../hooks/storeHooks";
import { selectVesselByImo } from "../../store/thunks/vesselViewThunks";
import { vesselBodyTemplate } from "@idwal/idwal-react-components";

export const PSCVesselBody = <T extends { vessel_imo: number, vessel_name: string }>(item: T) => {
    const dispatch = useAppDispatch();

    const vesselBodyTemplateData = useMemo(() => ({
        imo: item.vessel_imo,
        vesselName: item.vessel_name,
    }), [item]);

    const selectVessel = useCallback(() => {
        dispatch(selectVesselByImo(item.vessel_imo.toString()));
    }, []);

    return vesselBodyTemplate(vesselBodyTemplateData, selectVessel);
}