import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TablePageState } from "../../types/Table";
import { ScreeningItem } from "../../types/screening";
import { DataTableFilterMeta } from "primereact/datatable";
import { screeningFilters } from "../../utils/ScreeningTableHelper";

export interface ScreeningState {
    data: ScreeningItem[];
    prospects: number[];
    loading: boolean;
    error: boolean;
    page: TablePageState;
    filters: DataTableFilterMeta;
    globalFilterValue: string;
}

export const initialState: ScreeningState = {
    data: [],
    prospects: [],
    loading: true,
    error: false,
    page: { row: 10, first: 0 },
    filters: screeningFilters,
    globalFilterValue: "",
};

export const screeningSlice = createSlice({
    name: "screening",
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<ScreeningItem[]>) => {
            state.data = action.payload;
        },
        setProspects: (state, action: PayloadAction<number[]>) => {
            state.prospects = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setPage: (state, action: PayloadAction<TablePageState>) => {
            state.page = action.payload;
        },
        setFilters: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.filters = action.payload;
        },
        setGlobalFilterValue: (state, action: PayloadAction<string>) => {
            state.globalFilterValue = action.payload;
        },
        addData: (state, action: PayloadAction<ScreeningItem>) => {
            state.data = state.data.concat([action.payload]);
        },
        removeData: (state, action: PayloadAction<ScreeningItem>) => {
            state.data = state.data.filter(
                (item) => item.vessel_imo !== action.payload.vessel_imo
            );
        },
        resetFilters: (state) => {
            state.globalFilterValue = "";
            state.filters = screeningFilters;
        },
    }
});

export const {
    setData,
    setProspects,
    setLoading,
    setError,
    setPage,
    setFilters,
    setGlobalFilterValue,
    addData,
    removeData,
    resetFilters,
} = screeningSlice.actions;

export default screeningSlice.reducer;
