export enum Capability {
    CAP_FLEETAPP_CLOSE_DEFECTS = 'CAP_FLEETAPP_CLOSE_DEFECTS',
    CAP_FLEETAPP_VIEW_DEFECTS = 'CAP_FLEETAPP_VIEW_DEFECTS',
    CAP_FLEETAPP_VIEW_FLEETS = 'CAP_FLEETAPP_VIEW_FLEETS',
    CAP_FLEETAPP_VIEW_GRADES = 'CAP_FLEETAPP_VIEW_GRADES',
    CAP_FLEETAPP_VIEW_SUBGRADES = 'CAP_FLEETAPP_VIEW_SUBGRADES',
    CAP_FLEETAPP_VIEW_RECTIFICATIONS = 'CAP_FLEETAPP_VIEW_RECTIFICATIONS',
    CAP_FLEETAPP_VIEW_PSC = 'CAP_FLEETAPP_VIEW_PSC',
    CAP_FLEETAPP_VIEW_SCREENING = 'CAP_FLEETAPP_VIEW_SCREENING',
    CAP_FLEETAPP_NOTIFICATIONS = 'CAP_FLEETAPP_NOTIFICATIONS',
    CAP_FLEETAPP_SHARE_DAP = 'CAP_FLEETAPP_SHARE_DAP',
    CAP_FLEETAPP_EXPORT_DAP = 'CAP_FLEETAPP_EXPORT_DAP',
    CAP_FLEETAPP_ADVANCED_BENCHMARKING = 'CAP_FLEETAPP_ADVANCED_BENCHMARKING',
}
