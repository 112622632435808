import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectPSCInspectionData } from "../../store/selectors/pscSelector";
import { uniq } from "lodash";

export const PSCVesselTypeFilter = (options: ColumnFilterElementTemplateOptions) => {
    const inspectionData = useAppSelector(selectPSCInspectionData);

    const vesselTypeOptions = useMemo(() => {
        const vesselTypes: string[] = [];

        inspectionData.forEach((inspection) => {
            if (inspection.vessel_type) {
                vesselTypes.push(inspection.vessel_type)
            }
        })

        return uniq(vesselTypes).map((vesselType) => ({ label: vesselType, value: vesselType}));
    }, [inspectionData]);

    const onChange = useCallback((e: MultiSelectChangeEvent) => {
        options.filterApplyCallback(e.value)
    }, [options])

    return (
        <MultiSelect
            value={options.value}
            options={vesselTypeOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};

