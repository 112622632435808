import { useTranslation } from "react-i18next";
import { ScreeningItem } from "../../types/screening";
import { useMemo } from "react";
import dayjs from "dayjs";

export const ScreeningAgeBody = (item: ScreeningItem) => {
    const { t } = useTranslation("locale");

    const date = useMemo(
        () => dayjs(item.age_date).format("DD/MM/YYYY"),
        [item]
    );

    return (
        <div className="justify-content-start">
            <div>
                <p>{`${Number(item.age_of_vessel).toFixed(1)} ${t("screening.yearsOld")}`}</p>
                <small>{date}</small>
            </div>
        </div>
    );
};
