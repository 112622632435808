export const openUrl = (url: string) => {

    if (process.env.REACT_APP_OPEN_IN_WINDOW == 'true') {
        window.location.href = url;
        return;
    }

    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}