import {ReactElement, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {errorToast, successToast} from "@idwal/idwal-react-components";
import {Column} from "primereact/column";
import {DataTable, DataTableStateEvent} from "primereact/datatable";
import {MultiSelectChangeEvent} from "primereact/multiselect";
import {SharedTableColumnConfig} from "../../types/SharedTableColumnConfig";
import {columnWidth} from "../../utils/DefectsTableDataHelper";
import {notesEditorTemplate, notesPlaceholderTemplate} from "./BodyTemplates";
import {
    getDefaultDefectsColumnsConfig,
    getStaticDefectsColumnsConfig,
} from "./Filters/FilterTemplates";
import DisplayModal, {DisplayModalContent, DisplayModalMethods} from "../Shared/DisplayModal";
import {ConfirmPopup} from "primereact/confirmpopup";
import LOCAL_TABLE_CONFIG_KEY from '../../types/TableLocalConfigKeys';
import {ExportButton, exportCsvFileName} from "./ExportButton";
import {customCsvExport} from "../../utils/ExportHelper";
import {loadSavedColumnConfigurationsV2, onColumnReorder, onColumnToggle, updateStoredTableConfigState} from "../Shared/TableFunctions";
import {DefaultHeader} from "../Shared/DefaultHeader";
import {registerCustomFilters} from "./Filters/FilterTemplates";
import {columnTemplates, filterTemplates, keywordSearchFilterFields} from "./Constants";
import { ShareDefectButton} from "./ShareDefectButton";
import { HeaderType} from "../../types/HeaderType";
import { CloseDefectsDialog } from "../CloseDefectsDialog/CloseDefectsDialog";
import { USER_APP_STATE_DEFECTS_TABLE_KEY } from "../../utils/Constants";
import { onCustomRestoreState } from "../../utils/TableUtils";
import { DefectTabs } from "../../types/DefectTabs";
import { updateDefect } from "../../services/DefectService";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { updateDefectItem } from "../../store/thunks/defectThunks";
import { selectActiveTab, selectFilteredDefects } from "../../store/selectors/defectSelectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { selectAiGradingActive, selectUserDetails } from "../../store/selectors/userSelectors";
import { selectDataTableFilterData, selectKeywordSearch } from "../../store/selectors/filtersSelectors";
import { setDefectsTableFilterData } from "../../store/slices/filtersSlice";
import { selectVesselByImo } from "../../store/thunks/vesselViewThunks";
import { UserDetails } from "../../types/UserDetails";
import { setUserDetails } from "../../store/slices/userSlice";

type DefectsTableProps = { 
    isLoading: boolean;
}

export const DefectsTable = (props: DefectsTableProps): ReactElement => {
    const { isLoading } = props;

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const { defectMapping } = useFlags();
    
    const filteredDefects = useAppSelector(selectFilteredDefects);
    const activeTab = useAppSelector(selectActiveTab);
    const userDetails = useAppSelector(selectUserDetails);
    const keywordSearch = useAppSelector(selectKeywordSearch);
    const dataTableFilterData = useAppSelector(selectDataTableFilterData);
    const aiGradingActive = useAppSelector(selectAiGradingActive);

    const displayModal = useRef<DisplayModalMethods>(null);
    const staticColumns = getStaticDefectsColumnsConfig(defectMapping);
    const defectTable = useRef(null);

    const [filteredData, setFilteredData] = useState<DefectDataItem[]>(filteredDefects);
    const [columns, setColumns] = useState<SharedTableColumnConfig[]>(getDefaultDefectsColumnsConfig());
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    registerCustomFilters();

    // Setup filters from local storage
    useEffect(() => {
        loadSavedColumnConfigurationsV2(
            columns,
            setColumns,
            setSelectedColumns,
            userDetails,
            userDetails?.appState?.defectsTable,
            USER_APP_STATE_DEFECTS_TABLE_KEY,
            LOCAL_TABLE_CONFIG_KEY.DEFECT_COLUMNS
        );
        setFirst(0);
    }, []);

    const handleColumnUpdate = async (defectId: number, columnName: string, value: any) => {
        const updated = await dispatch(updateDefectItem(defectId, columnName, value));

        if (updated) {
            setFilteredData(filteredData.map((defectDataItem) => {
                if (defectDataItem.defectId === defectId) {
                    return {...defectDataItem, [columnName]: value};
                }

                return defectDataItem;
            }))

            successToast(t("defectRectification.updateStatusMessage"));
        }
    }

    const onPage = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const onValueChange = (value: DefectDataItem[]) => setFilteredData(value);

    const handleReadMoreClick = (content: DisplayModalContent) => {
        if (displayModal.current) {
            displayModal.current.showContent(content);
        }
    }

    const onFilter = (event: DataTableStateEvent) => {
        dispatch(setDefectsTableFilterData(event.filters));
    }

    const selectVessel = (rowData: any) => {
        dispatch(selectVesselByImo(rowData.vessel.imo));
    }

    const header = () => <DefaultHeader
        onColumnToggle={
            (e: MultiSelectChangeEvent) => onColumnToggle(
                e,
                LOCAL_TABLE_CONFIG_KEY.DEFECT_COLUMNS,
                columns,
                setSelectedColumns,
                userDetails,
                USER_APP_STATE_DEFECTS_TABLE_KEY)}
        columns={columns}
        disabled={isLoading}
        selectedColumns={selectedColumns}
        filters={dataTableFilterData.defects}
        onColumnReorder={
            (orderedColumns: any) => onColumnReorder(
                orderedColumns,
                LOCAL_TABLE_CONFIG_KEY.DEFECT_COLUMNS,
                LOCAL_TABLE_CONFIG_KEY.DEFECT_ORDERED_COLUMNS,
                columns,
                selectedColumns,
                setColumns,
                setSelectedColumns,
                userDetails,
                USER_APP_STATE_DEFECTS_TABLE_KEY)}
        headerType={HeaderType.DEFECTS}
        />

    const onCellEditComplete = async (e: any) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        if (field === 'notes') {
            if (newValue === undefined || newValue === null) {
                newValue = ""
            }
            if (newValue?.length <= 500) {
                handleNotesUpdate(rowData, newValue);
            } else {
                event.preventDefault();
            }
        }
    };

    const handleNotesUpdate = async (rowData: any, note: string) => {
        const { defectId, inspectionId } = rowData;
        try {
            await updateDefect(defectId, { Notes: note, inspectionId });
            handleColumnUpdate(defectId, 'notes', note);
        } catch(e) {
            console.error(e);
            errorToast(t("fleetTable.notesErrorMessage"));
        }
    };

    const renderColumnComponent = (columnConfig: SharedTableColumnConfig[]) => {
        const result = columnConfig.map((col) => {
            const columnStyle = {
                ...columnWidth(col.field),
                WhiteSpace: "normal"
            };
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    dataType={col.dataType}
                    header={col.header}
                    filterMatchMode={col.filterMatchMode}
                    frozen={col.frozen}
                    className={(col.showFilterMenu ?? false) ? "has-filter-menu" : ""}
                    headerClassName={`${col.field.split(".").slice(-1).pop()}-header`}
                    sortable={col.sortable ?? false}
                    showFilterMenu={col.showFilterMenu ?? false}
                    showFilterMatchModes={col.showFilterMatchModes ?? false}
                    filter={col.filter ?? false}
                    filterField={col.filterField}
                    filterFunction={col.filterFunction}
                    sortFunction={col?.sortFunction}
                    body={
                        columnTemplates(
                            aiGradingActive, 
                            handleColumnUpdate,
                            handleReadMoreClick, 
                            selectVessel
                        ).find((e: any) => e.column === col.field)?.bodyTemplate ?? null
                    }
                    bodyStyle={{ textAlign: col.textAlign ?? "left", justifyContent: col.justifyContent ?? "start" }}
                    style={columnStyle}
                    filterElement={filterTemplates(filteredData).find(e => e.column === col.field)?.filterTemplate ?? null}
                    filterPlaceholder={col.filterPlaceholder ?? ""}
                    editor={col.field === "notes" ? (rowData) => notesEditorTemplate(rowData) : ""}
                    onBeforeCellEditShow={notesPlaceholderTemplate}
                    onCellEditComplete={onCellEditComplete}
                    exportable={col.exportable ?? false}
                />
            )
        });
        return result;
    }

    const updateUserDetails = (userDetails: UserDetails) => {
        dispatch(setUserDetails(userDetails));
    }

    const customSaveState = (state: any) => {
        if (activeTab === DefectTabs.defects) {
            updateStoredTableConfigState(
                USER_APP_STATE_DEFECTS_TABLE_KEY,
                columns,
                selectedColumns,
                userDetails,
                updateUserDetails,
                userDetails?.appState?.defectsTable,
                state,
                LOCAL_TABLE_CONFIG_KEY.DEFECTS
            );
        }
    }

    const customeRestoreState = () => {
        return onCustomRestoreState(LOCAL_TABLE_CONFIG_KEY.DEFECTS, keywordSearch.defects, userDetails, USER_APP_STATE_DEFECTS_TABLE_KEY);
    }

    return (
      <>
        <DisplayModal ref={displayModal} />
        <ConfirmPopup />
        <div className="defects_table" data-cy="defects-table-container">
          <p></p>
          <DataTable
            ref={defectTable}
            paginator
            first={first}
            onPage={onPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            scrollHeight="55vh"
            rows={rows}
            globalFilterFields={keywordSearchFilterFields}
            scrollable
            data-cy="defects-table"
            filterDisplay="row"
            header={header}
            value={filteredDefects}
            onValueChange={onValueChange}
            responsiveLayout="scroll"
            exportFunction={customCsvExport}
            exportFilename={exportCsvFileName}
            onFilter={onFilter}
            stateStorage="custom"
            customSaveState={(state) => customSaveState(state)}
            customRestoreState={customeRestoreState}
            filters={dataTableFilterData.defects}
            loading={isLoading}
            emptyMessage={t("defectsTable.noDataMessage")}
            removableSort
            editMode="cell"
            stripedRows
            resizableColumns
            columnResizeMode="expand"
          >
            {renderColumnComponent(staticColumns)}
            {renderColumnComponent(selectedColumns)}
          </DataTable>

        </div>

        <div className="defect-btn-container">
            <ExportButton
              dataTableRef={defectTable}
              filteredData={filteredData}
            />
            <ShareDefectButton filteredData={filteredData} /> 
        </div>
        <CloseDefectsDialog filteredData={filteredData} />
      </>
    );
};
