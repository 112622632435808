export const pathMapper = (path: string, t: any) => {
    let mappedPath = "";
    switch (path) {
        case "grade":
            mappedPath = t("pageHeader.grade");
            break;
        case "subgrades":
            mappedPath = t("pageHeader.subgrades");
            break;
        case "defects":
            mappedPath = t("pageHeader.defects");
            break;
        case "rectifications":
            mappedPath = t("pageHeader.rectifications");
            break;
        case "psc":
            mappedPath = t("pageHeader.psc");
            break;
        case "screening":
            mappedPath = t("pageHeader.screening");
            break;
        default:
            mappedPath = path;
    }

    return mappedPath;
}