import { useTranslation } from "react-i18next";
import { MenuButton } from "../MenuButton/MenuButton";
import { sendGAEvent } from "../../AnalyticsService";
import { 
    DEFECTS_SUMMARY,
    DEFECTS_SUMMARY_SHARE_DEFECTS_ACTION,
    DEFECTS_SUMMARY_CLOSE_DEFECTS_ACTION,
    DEFECTS_SUMMARY_VIEW_GRADING_ACTION,
    DEFECTS_SUMMARY_VIEW_INSIGHTS_ACTION,
    DEFECTS_SUMMARY_VIEW_REPORT_ACTION,
    DEFECTS_EXPORT_DEFECT_ACTION_PLAN_CLICK,
    DEFECTS_EXPORT_DEFECT_ACTION_PLAN_IMAGES_CLICK,
} from "../../constants/analytics";
import { MenuItem } from "primereact/menuitem";
import { DefectTabs } from "../../types/DefectTabs";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import { DefectInsightFilterBy } from "../../types/DefectInsightsData";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { setActiveTab, setInsightsFilterBy, setSelectedVessel, setShowCloseDefectsDialog, setShowShareDefectsDialog } from "../../store/slices/defectSlice";
import { triggerExportDap } from "../../services/DefectService";
import { selectFilteredDefects } from "../../store/selectors/defectSelectors";
import {openUrl} from "../../services/WindowOpenService";

const CUSTOMER_PORTAL_ROUTE = "Inspection";

type DefectSummaryActionMenuProps = {
    rowData: DefectSummaryDataItem;
}

export const DefectSummaryActionMenu = (props: DefectSummaryActionMenuProps) => {
    const { rowData } = props;
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const filteredDefects = useAppSelector(selectFilteredDefects);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_SHARE_DAP,
        Capability.CAP_FLEETAPP_EXPORT_DAP,
    ]);

    const onFilterByVessel = (tab: DefectTabs) => {
        dispatch(setSelectedVessel(rowData.vesselName));

        window.dispatchEvent(new Event("resize"));
        dispatch(setActiveTab(tab));
    }

    const exportDap = async (images: boolean) => {
        sendGAEvent(DEFECTS_SUMMARY, images ? DEFECTS_EXPORT_DEFECT_ACTION_PLAN_IMAGES_CLICK : DEFECTS_EXPORT_DEFECT_ACTION_PLAN_CLICK);

        const filteredData = filteredDefects.filter((defect) => defect.vesselName === rowData.vesselName);

        await triggerExportDap(filteredData, images);
    }

    let menuItems: MenuItem[] = [
        {
            label: t("menuButton.closeRectifiedDefects"),
            icon: 'pi pi-check-circle',
            command: () => {
                dispatch(setSelectedVessel(rowData.vesselName));
                dispatch(setShowCloseDefectsDialog(true));
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_CLOSE_DEFECTS_ACTION);
            }
        },
        {
            label: t("menuButton.viewByGradingArea"),
            icon: 'pi pi-chart-bar',
            command: () => {
                onFilterByVessel(DefectTabs.gradingArea);
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_VIEW_GRADING_ACTION);
            }
        },
        {
            label: t("menuButton.viewInsights"),
            icon: 'pi pi-chart-line',
            command: () => {
                onFilterByVessel(DefectTabs.insights);
                dispatch(setInsightsFilterBy(DefectInsightFilterBy.VESSELS));
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_VIEW_INSIGHTS_ACTION);
            }
        }
    ];

    if (capabilities[Capability.CAP_FLEETAPP_SHARE_DAP]) {
        menuItems.unshift({
            label: t("menuButton.shareDefects"),
            icon: 'pi pi-share-alt',
            command: () => {
                dispatch(setSelectedVessel(rowData.vesselName));
                dispatch(setShowShareDefectsDialog(true));
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_SHARE_DEFECTS_ACTION);
            }
        });
    }

    if (rowData?.inspectionGuid) {
        const reportUrl = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${rowData.inspectionGuid}`

        menuItems.push({
            label: t("menuButton.viewReport"),
            icon: <img className="pr-2" alt="view_report" src="./view-report-menu.svg" />,
            command: () => {
                sendGAEvent(DEFECTS_SUMMARY, DEFECTS_SUMMARY_VIEW_REPORT_ACTION);
                openUrl(reportUrl);
            }
        });
    }

    if (capabilities[Capability.CAP_FLEETAPP_EXPORT_DAP]) {
        menuItems.push({
            label: t("defectsTable.exportDap"),
            icon: <img className="pr-2" alt="export_dap" src="./export-dap.svg" />,
            command: () => {
                exportDap(false);
            }
        });

        menuItems.push({
            label: t("defectsTable.exportDapImages"),
            icon: <img className="pr-2" alt="export_dap_images" src="./export-dap-images.svg" />,
            command: () => {
                exportDap(true);
            }
        });
    }


    return (
        <div className="flex align-items-center justify-content-center">
            <MenuButton menu={menuItems}/>
        </div>
    )
}
