import { ScreeningItem } from "../../types/screening";

export const ScreeningBuilderBody = (item: ScreeningItem) => {
    return (
        <div className="justify-content-start">
            <div>
                <p>{item.builder}</p>
                <small>{item.build_country}</small>
            </div>
        </div>
    );
};
