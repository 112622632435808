import { FilterService } from "primereact/api";
import { getFleetData, getInspectionAndGradeData, getInternalGradeData } from "../../services/FleetDataService";
import { getTranslationObject } from "../../services/Translation";
import { getDistinctItems, toVesselTypeWithCount } from "../../utils/FleetHelper";
import { addCustomFieldFleetFiltersToData, mutateFleetDataWithInspections } from "../../utils/FleetTableDataHelper";
import { getDefaultGroupName, getDefectsForGroup, getFleetItemsForGroup, getSubGradesForGroup } from "../../utils/GroupDropDownHelper";
import { getUserSessionStorageItem, storeUserSessionStorageItem } from "../../utils/UserSessionStorage";
import { selectFleet, selectFleetDataItems, selectInspectionAndGradeData, selectShowStatsTilesPanel } from "../selectors/fleetSelectors";
import { selectSelectedGroupName, selectUser, selectUserDetails } from "../selectors/userSelectors";
import { setData, setDistinctCountriesOfBuild, setDistinctFlags, setDistinctTechnicalManagers, setDistinctVessels, setDistinctVesselsWithCount, setDistinctVesselTypes, setError, setFleetDataItems, setInspectionAndGradeData, setInternalGradeData, setInspectionAndGradeDataError, setInspectionAndGradeDataLoading, setLoading, setMarketDataFilteringOptions, setShowStatsTilesPanel, setStatTileConfigs } from "../slices/fleetSlice";
import { setAiGradingAccess, setAiGradingActive, setSelectedGroupName, setUserDetails } from "../slices/userSlice";
import { AppThunk } from "../store";
import { getStoredAdvancedFiltersState, setupAdvancedFilters } from "./advancedFiltersThunks";
import { setupFilteredDefects } from "./defectThunks";
import { loadGlobalFilterStateFromLocalStorage } from "./filtersThunks";
import { cloneDeep } from "lodash";
import { FleetTableFields } from "../../components/FleetTable/FleetTableFields";
import { customRankFilter } from "../../components/FleetTable/FilterTemplates";
import { AIGradingAccess } from "../../types/AIGradingAccess";
import { selectDefects } from "../selectors/defectSelectors";
import { selectFleetGraph } from "../selectors/fleetGraphSelectors";
import { calculateFleetStatistics } from "../../utils/calculateStatistics";
import { getInspectionsCompletedInContractYear } from "../../utils/StatsHelper";
import { MAX_VISIBLE_TILES } from "../../utils/Constants";
import { setGraphContextFilter } from "../slices/filtersSlice";

export const fetchFleetData = (refetch?: boolean): AppThunk<Promise<boolean>> => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
        const response = await getFleetData();

        if (response) {
            dispatch(setData(response));
            dispatch(setFleetDataItems(response.fleetDataItems));
            dispatch(setUserDetails(response.userDetails));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setError(true));
    }

    if (refetch) {
        dispatch(setLoading(false));
    }

    return true;
};

export const fetchInspectionAndGradeData = (): AppThunk<Promise<boolean>> => async (dispatch) => {
    dispatch(setInspectionAndGradeDataLoading(true));
    dispatch(setInspectionAndGradeDataError(false));

    try {
        const response = await getInspectionAndGradeData();

        if (response) {
            dispatch(setInspectionAndGradeData(response));

            const imos = response.fleetDataItems.map(item => item.vessel.imo);
            const joinedImos = imos.join(',');

            const internalGrades = await getInternalGradeData(joinedImos);

            dispatch(setInternalGradeData(internalGrades));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setInspectionAndGradeDataError(true));
    } finally {
        dispatch(setInspectionAndGradeDataLoading(false));
    }

    return true;
};

export const toggleStatsTiles = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const showStatsTilesPanel = selectShowStatsTilesPanel(state);
    const userDetails = selectUserDetails(state);

    dispatch(setShowStatsTilesPanel(!showStatsTilesPanel));

    if (userDetails?.userName) {
        const userKey = userDetails.userName;
        const key = "toggleStatsTiles";
        storeUserSessionStorageItem(userKey, key, !showStatsTilesPanel);
    }
}

export const setupFleetDataWithInspectionsBySelectedGroup = (selectedGroupName: string): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const { userDetails, aiGradingActive } = selectUser(state);
    const { data, inspectionAndGradeData, internalGradeData } = selectFleet(state);

    dispatch(setSelectedGroupName(selectedGroupName));

    const t = getTranslationObject();

    if (userDetails?.groupDetails && inspectionAndGradeData) {
        let fleetData = cloneDeep(data.fleetDataItems);
        let inspectionGradeItems = cloneDeep(inspectionAndGradeData.fleetDataItems);
        let internalGrades = cloneDeep(internalGradeData);

        const numberOfGroups = Object.keys(userDetails.groupDetails).length;

        let fleetItems = getFleetItemsForGroup(selectedGroupName, numberOfGroups, fleetData);

        mutateFleetDataWithInspections(fleetItems, inspectionGradeItems, internalGrades, aiGradingActive);

        fleetItems.forEach(item => item.translationFunction = t)

        addCustomFieldFleetFiltersToData(fleetItems);

        dispatch(setFleetDataItems(fleetItems));
    }

    return true;
}

export const setupDistinctFleetData = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const fleetDataItems = selectFleetDataItems(state);

    if (fleetDataItems.length) {       
        const vessels: string[] = [];
        const types: string[] = [];
        const managers: string[] = [];
        const countries: string[] = [];
        const flags: string[] = [];

        fleetDataItems.forEach((fleetDataItem) => {
            vessels.push(fleetDataItem.vessel.vesselName);
            types.push(fleetDataItem.vessel.type);
            managers.push(fleetDataItem.vessel.technicalManager);
            countries.push(fleetDataItem.vessel.countryOfBuild);
            flags.push(fleetDataItem.vessel.flag);
            vessels.push(fleetDataItem.vessel.vesselName);
        })

        dispatch(setDistinctVessels(getDistinctItems(vessels)));
        dispatch(setDistinctVesselTypes(getDistinctItems(types)));
        dispatch(setDistinctTechnicalManagers(getDistinctItems(managers)));
        dispatch(setDistinctCountriesOfBuild(getDistinctItems(countries)));
        dispatch(setDistinctFlags(getDistinctItems(flags)));


        const distinctVesselsWithCount = Array.from(
            new Set(toVesselTypeWithCount(fleetDataItems))).filter(Boolean).sort((a, b) => a.vesselType.localeCompare(b.vesselType));
        dispatch(setDistinctVesselsWithCount(distinctVesselsWithCount));
    }
}

export const setupFleetData = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { data, inspectionAndGradeData } = selectFleet(state);
    const { userDetails } = data;

    FilterService.register(`custom_${FleetTableFields.RANK}`, (value: any, filter: any) => 
        customRankFilter(value, filter, data.fleetDataItems.filter(x => x.gradeFilterField).length));


    if (userDetails && inspectionAndGradeData) {
        if (userDetails.groupDetails) {
            const firstGroupKey = Object.keys(userDetails.groupDetails)[0]; // Replace when we can handle multiple group configs
            dispatch(setAiGradingAccess(userDetails.groupDetails[firstGroupKey]?.aiGradingAccess ?? AIGradingAccess.OFF));

            dispatch(setAiGradingActive(localStorage.getItem("ai-grading-active") === "true"));
        }

        const graphContextFilter = getUserSessionStorageItem("user", "graphContextFilter");
        dispatch(setGraphContextFilter(graphContextFilter ? Number(graphContextFilter) : 0));

        const selectedGroupName = getDefaultGroupName(userDetails.groupDetails);

        await dispatch(setupFleetDataWithInspectionsBySelectedGroup(selectedGroupName));

        dispatch(loadGlobalFilterStateFromLocalStorage());
        dispatch(setupDistinctFleetData());

        const marketDataFilteringOptionsResponse = userDetails.filterData;
        dispatch(setMarketDataFilteringOptions(marketDataFilteringOptionsResponse));
        dispatch(setupAdvancedFilters(inspectionAndGradeData, marketDataFilteringOptionsResponse));
        dispatch(getStoredAdvancedFiltersState());

        dispatch(setupFilteredDefects(true));
        dispatch(setupFleetStatsData());
    }

    dispatch(setLoading(false));
}

export const setupFleetStatsData = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const selectedGroupName = selectSelectedGroupName(state);
    const inspectionAndGradeData = selectInspectionAndGradeData(state);
    const userDetails = selectUserDetails(state);
    const defects = selectDefects(state);
    const { subgradeData, subgradeBackingData } = selectFleetGraph(state);

    if (inspectionAndGradeData && userDetails && defects && subgradeData && subgradeBackingData) {
        const fleetDataItems = getFleetItemsForGroup(selectedGroupName, inspectionAndGradeData.groupCount, inspectionAndGradeData.fleetDataItems);
        const groupSubGrades = getSubGradesForGroup(selectedGroupName,subgradeData,inspectionAndGradeData);
        const defectDataItems = getDefectsForGroup(selectedGroupName,defects,inspectionAndGradeData);
    
        const fleetStatistics = calculateFleetStatistics(
            fleetDataItems,
            groupSubGrades,
            subgradeBackingData,
            defectDataItems,
        );

        const inspectionsCompletedInContractYear = await getInspectionsCompletedInContractYear()
        if (inspectionsCompletedInContractYear) {
            fleetStatistics.unshift(inspectionsCompletedInContractYear);
        }
    
        dispatch(setStatTileConfigs(fleetStatistics.slice(0, MAX_VISIBLE_TILES)));
    }
}