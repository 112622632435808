import { useLocation } from "react-router-dom";
import { GlobalFilter } from "./GlobalFilter";
import { useCapabilities } from '../../hooks/useCapabilities';
import { Capability } from '../../types/Capability';

const hiddenPaths = ['/rectification', '/rectifications', '/screening'];

export const FleetFilter = () => {
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING
    ]);

    const { pathname } = useLocation();

    const isHiddenPath = hiddenPaths.some((path) => pathname.endsWith(path));

    if (isHiddenPath || pathname === "/") {
        return null;
    }

    if (!capabilities[Capability.CAP_FLEETAPP_VIEW_PSC] && pathname == "/psc") {
        return null;
    }

    if (!capabilities[Capability.CAP_FLEETAPP_VIEW_SCREENING] && pathname == "/screening") {
        return null;
    }

    return <GlobalFilter />
}
