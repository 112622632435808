import { InputText } from "primereact/inputtext";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { resetFilters, setGlobalFilterValue, setTimePeriod } from "../../store/slices/pscSlice";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { PSCTimePeriod } from "../../types/psc";
import { setupFilteredPSC, setupPSCSummary } from "../../store/thunks/pscThunks";
import { PSCPerformanceAggregates } from "./PSCPerformanceAggregates";

export const PSCTableHeader = () => {   
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const { globalFilterValue, timePeriod } = useAppSelector(selectPSC)

    const onGlobalFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setGlobalFilterValue(event.target.value));
    }, []);

    const onClickReset = useCallback(() => {
        dispatch(resetFilters());
        dispatch(setupFilteredPSC());
    }, []);

    const onSelectTimePeriod = useCallback((event: DropdownChangeEvent) => {
        dispatch(setTimePeriod(event.value));
        dispatch(setupPSCSummary())
    }, []);

    const timePeriodOptions = useMemo(() => 
        PSCTimePeriod.map((timePeriod) => ({ label: t(`psc.${timePeriod}`), value: timePeriod }))
    , []);

    return (
        <>
            <div className="grid">
                <div className="col-12 lg:col-6 flex align-items-center mb-3">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t("fleetTable.keywordSearch")} />
                    </span>
                    <Button
                        label={t("fleetTable.resetTable")}
                        data-cy={"psc-reset-table-button"}
                        className={"p-button p-button-outlined p-button-secondary secondary-outlined-button ml-3"}
                        onClick={onClickReset}
                    />
                </div>
                <div className="col-12 lg:col-6 flex lg:justify-content-end align-items-center formgroup-inline">
                    <label className="font-weight-normal mr-2" htmlFor="#psc-time-period-dropdown">{t("psc.show")}</label>
                    <Dropdown id="psc-time-period-dropdown" value={timePeriod} onChange={onSelectTimePeriod} options={timePeriodOptions} className="w-full lg:w-23rem max-w-23rem" />
                </div>
            </div>
            <PSCPerformanceAggregates />
        </>
    );
}