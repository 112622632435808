import axios from "axios";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import { RequestInspectionInfo } from "../types/RequestInspectionInfo";

export async function requestInspection(requestInfo: RequestInspectionInfo) {

    console.info("Requesting inspection ", requestInfo);

    const message = requestInfo;

    const url : string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const token = await getCurrentSessionIdToken();

    return new Promise<boolean>((resolve, reject) => {
        axios({
            method: "POST",
            url: url + "/Fleet/request-inspection",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            data: JSON.stringify(message)
        }).then((res) => {
            console.info("Inspection request sent", res);
            resolve(res.status === 200); 
        })
        .catch((rej) => {
            console.error("Error sending inspection request", rej);
            reject(false);
        });
    });
}