import {getFleetToken} from "../../services/FleetDataService";
import {getUserCapabilities, isIdUser} from "../../services/UserService";
import {Capability} from "../../types/Capability";
import {selectUser} from "../selectors/userSelectors";
import {setHasFleetAccess, setUserCapabilities, setUserId, setUserLoaded, setUserToken} from "../slices/userSlice";
import {AppThunk} from "../store";
import {fetchAgeOfOpenDefects, fetchClosedRatios, fetchCumulativeDefects} from "./defectInsightsThunks";
import {fetchDefects} from "./defectThunks";
import {fetchFleetData, fetchInspectionAndGradeData, setupFleetData} from "./fleetThunks";
import {fetchFleetGraph, fetchSubgradeBackingData, fetchSubgradeData} from "./fleetGraphThunks";
import {fetchPreviousRecipients, fetchSharedDefects} from "./sharedDefectsThunks";
import {AmplifyUser} from "@aws-amplify/ui";
import {fetchNotifications} from "./notificationsThunks";
import {fetchPSCDeficiencies, fetchPSCInspections, setupPSCVesselData} from "./pscThunks";
import { fetchScreeningData } from "./screeningThunks";

export const initFetchData = (user: AmplifyUser): AppThunk => async (dispatch, getState) => {
    const { userLoaded } = selectUser(getState());

    if (user && !userLoaded) {
        dispatch(setUserId(user.username));


        const userCapabilities = await getUserCapabilities(user.username);

        dispatch(setUserCapabilities(userCapabilities));

        dispatch(setHasFleetAccess(isIdUser(userCapabilities)));


        await dispatch(fetchDataByCapabilities(userCapabilities));

        dispatch(setupFleetData());

        dispatch(setUserLoaded(true));
    }
};

const fetchDataByCapabilities = (userCapabilities: string[]): AppThunk<Promise<boolean>> => async (dispatch) => {
    const capabilities = Object.fromEntries(
        userCapabilities.map((capability) => [capability, userCapabilities.includes(capability)]),
    )

    const fetchActions = [];

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS]) {
        fetchActions.push(dispatch(fetchFleetData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES] || capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
        fetchActions.push(dispatch(fetchInspectionAndGradeData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS]) {
        fetchActions.push(dispatch(fetchDefects()));
        fetchActions.push(dispatch(fetchPreviousRecipients()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
        fetchActions.push(dispatch(fetchSharedDefects()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES]) {
        fetchActions.push(dispatch(fetchFleetGraph()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
        fetchActions.push(dispatch(fetchSubgradeData()));
        fetchActions.push(dispatch(fetchSubgradeBackingData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_NOTIFICATIONS]) {
        fetchActions.push(dispatch(fetchNotifications()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_SCREENING]) {
        fetchActions.push(dispatch(fetchScreeningData()));
    }

    await Promise.all(fetchActions);

    return true;
}

export const initFetchTokenData = (): AppThunk => async (dispatch, getState) => {
    const { userId, userLoaded } = selectUser(getState());

    if (userId && !userLoaded) {

        try {
            const userToken = await getFleetToken();

            if (userToken) {
                dispatch(setUserToken(userToken));
         
                dispatch(fetchClosedRatios());
                dispatch(fetchCumulativeDefects());
                dispatch(fetchAgeOfOpenDefects());

                const pscPromoises = [
                    dispatch(fetchPSCInspections()),
                    dispatch(fetchPSCDeficiencies()),
                ];

                await Promise.all(pscPromoises);

                dispatch(setupPSCVesselData());
            } 
        } catch (e) {
            console.error(e);
        }
    }
}
