import { getCurrentUserName } from "../queries/AuthToken";
import { dataRequest, postRequest } from "./DataRequest";
import { Dictionary } from "../types/Dictionary";
import { ShareableDefect } from "../types/ShareableDefect";

export const getSharedDefects = async (userId: string | undefined) => {
    return await dataRequest(`/Fleet/sharedDefects/${userId}`);
}

export const shareDefects = async (defects:any, name:string, email:string, message:string) => {
    const currentUser = await getCurrentUserName();

    const data = {
      defects: defects,
      name: name,
      email: email,
      sharerName: currentUser,
      message: message,
    };

    return await postRequest("/Defects/share", data);
}

export const sendShareReportEvents = (
    defects: Array<ShareableDefect>, 
    email: string, 
    name: string, 
    userId: string,
    userName: string,
    customerOrganization: string
) => {
    const inspectionIds = [...new Set(defects.map(defect => defect.inspectionId))];

    const inspectionIdsAndNumbers: Dictionary<{
        inspectionNumber: string,
        inspectionType: string,
        vesselName: string,
        organisationName: string
    }> = {};

    defects.forEach((defect: ShareableDefect) => {
        if (typeof defect.inspectionNumber === "string") {
            return inspectionIdsAndNumbers[defect.inspectionId] = {
                inspectionNumber: defect.inspectionNumber,
                inspectionType: defect.inspectionType?.name,
                vesselName: defect.vesselName,
                organisationName: defect.organizationName,
            }
        }
    });

    inspectionIds.forEach((inspectionId) => {
        postRequest(`/Inspection/${inspectionId}/share-report`, {
            inspectionId,
            email,
            inspectionOrganization: inspectionIdsAndNumbers[inspectionId].organisationName,
            customerOrganization,
            name,
            vessel: inspectionIdsAndNumbers[inspectionId].vesselName,
            event: "INSPECTION_SHARE_REPORT",
            context: `INSPECTION-${inspectionId.toUpperCase()}`,
            timestamp: Date.now(),
            triggered_by: userId,
            activity: "report_shared",
            inspectionNumber: inspectionIdsAndNumbers[inspectionId].inspectionNumber,
            reportType: inspectionIdsAndNumbers[inspectionId].inspectionType,
            sharedByName: userName
        })
    });
}

export const getPreviousRecipients = async () => {
  return await dataRequest(`/Defects/technicalManagers`);
}
