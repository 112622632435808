import dayjs from "dayjs";

export const PSCDateBody = (timestamp: number | string | undefined) => {
    if (!timestamp) {
        return null
    }

    const date = dayjs(Number(timestamp) * 1000).format("DD/MM/YYYY");

    return <p>{date}</p>;
}