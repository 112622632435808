import { useCallback, useEffect, useMemo, useRef } from "react";
import { LoadingStatTiles } from "../Loading/LoadingStatTiles";
import { FleetDataItem } from "../../types/FleetDataItem";
import { Panel } from "primereact/panel";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectShowStatsTilesPanel, selectStatTileConfigs } from "../../store/selectors/fleetSelectors";
import { setRequestInspectionDialog } from "../../store/slices/fleetSlice";
import { mapToStatsTiles } from "../../utils/StatsHelper";
import "./StatsTiles.css";

export const StatsTiles = () => {
    const dispatch = useAppDispatch();

    const statTileConfigs = useAppSelector(selectStatTileConfigs);
    const showStatsTilesPanel = useAppSelector(selectShowStatsTilesPanel);
    const panel = useRef<Panel>(null);

    useEffect(() => {
        if (!showStatsTilesPanel) {
            panel.current?.collapse(undefined);
        } else {
            panel.current?.expand(undefined);
        }
    }, [showStatsTilesPanel]);

    const onRequestInspectionDialog = useCallback((vessels: FleetDataItem[]) => {
        dispatch(setRequestInspectionDialog({
            show: true,
            vessels,
        }))
    }, [statTileConfigs]);

    const statTiles = useMemo(() =>
        mapToStatsTiles(statTileConfigs, onRequestInspectionDialog)
    , [statTileConfigs]);

    return (
        <Panel unstyled className="stats-tile-panel" ref={panel} toggleable headerTemplate={<></>}>
            {statTileConfigs === undefined ? (
                <div className="stats-tile-container">
                    <LoadingStatTiles />
                </div>
            ) : (
                <div className="stats-tile-container" data-cy="statistics-tiles">
                    <div className="grid flex-row">
                       {statTiles}
                    </div>
                </div>
            )}
        </Panel>
    );
}