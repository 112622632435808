import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { PSCInspectionTypes } from "../../types/psc";
import { useTranslation } from "react-i18next";

export const PSCInspectionTypeFilter = (options: ColumnFilterElementTemplateOptions) => {
    const { t } = useTranslation("locale");

    const inspectionTypeOptions = useMemo(() =>
        PSCInspectionTypes.map((inspectionType) => ({ label: t(`psc.${inspectionType}`), value: inspectionType }))
    , []);

    const onChange = useCallback((e: MultiSelectChangeEvent) => {
        options.filterApplyCallback(e.value)
    }, [options])

    return (
        <MultiSelect
            value={options.value}
            options={inspectionTypeOptions}
            onChange={onChange}
            showClear
            filter
            placeholder="Show all"
            scrollHeight="400px"
        />
    );
};

