import { useAuthenticator } from "@aws-amplify/ui-react";
import { VesselFinder, successToast } from "@idwal/idwal-react-components";
import { InstantQuotationVessel } from "@idwal/idwal-react-components/dist/types/InstantQuotationVessel";
import { Button } from "primereact/button";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getScreeningData, updateScreeningProspects } from "../../services/ScreeningService";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { addData, setLoading, setProspects } from "../../store/slices/screeningSlice";
import { SearchForVesselIcon } from "../Icons/SearchForVesselIcon";
import { selectScreeningProspects } from "../../store/selectors/screeningSelectors";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, SCREENING_ADD_VESSEL_TO_LIST_CLICK, SCREENING_SEARCH_FOR_A_VESSEL_CLICK } from "../../constants/analytics";

const URL = `${process.env.REACT_APP_IDWAL_API_ENDPOINT}/ships/search`;

export const ScreeningVesselFinder = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("locale");

    const { user } = useAuthenticator((context) => [context.user]);

    const prospects = useAppSelector(selectScreeningProspects);

    const [visible, setVisible] = useState(false);

    const onOpen = useCallback(() => {
        setVisible(true);
        sendGAEvent(ANALYTICS_BUTTON_CLICK, SCREENING_SEARCH_FOR_A_VESSEL_CLICK);
    }, []);

    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    const onSelect = useCallback(async (vessel: InstantQuotationVessel) => {
        dispatch(setLoading(true));

        const [screeningItem] = await getScreeningData([vessel.LRIMOShipNo]);

        if (screeningItem) {
            const updated = [...prospects, screeningItem.vessel_imo];
            updateScreeningProspects(updated);

            dispatch(setProspects(updated));
            dispatch(addData(screeningItem));
            sendGAEvent(ANALYTICS_BUTTON_CLICK, SCREENING_ADD_VESSEL_TO_LIST_CLICK);
            successToast(`${screeningItem.vessel_name} ${t("screening.addedToList")}`);
        }

        dispatch(setLoading(false));
    }, [prospects]);

    const token = useMemo(
        () => user.getSignInUserSession()?.getIdToken().getJwtToken() ?? "",
        [user]
    );

    return (
        <>
            <Button
                onClick={onOpen}
                label={t("screening.searchForAVessel")}
                className="w-15rem"
                icon={SearchForVesselIcon}
                data-cy="screening-search-for-a-vessel"
            />
            <VesselFinder
                onClose={onClose}
                onSelect={onSelect}
                url={URL}
                visible={visible}
                token={token}
                commonOnly={true}
            />
        </>
    );
};
