import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { setPerformancePage } from "../../store/slices/pscSlice";
import { PSCTableHeader } from "./PSCTableHeader";
import { pscPerformanceColumns, pscGlobalFilterFields, pscPerformanceHeaders } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";
import "./PSCTable.css";

export const PSCPerformanceTable = () => {
    const dispatch = useAppDispatch();

    const {
        summaryData,
        loading,
        globalFilterValue,
        performancePage,
    } = useAppSelector(selectPSC);

    const onPage = useCallback((event: DataTableStateEvent) => {
        dispatch(setPerformancePage({ row: event.rows, first: event.first}))
    }, []);

    const renderHeader = useMemo(() => (
        <PSCTableHeader />
    ), [globalFilterValue]);

    const headers = useMemo(() => (
        pscPerformanceHeaders()
    ), []);

    const columns = useMemo(() => (
        pscPerformanceColumns()
    ), []);

    const rowOptions = useMemo(() => 
        [5, 10, 25, 50]
    , []);

    const globalFilterFields = useMemo(() => 
        pscGlobalFilterFields
    , []);
    
    return (
        <DataTable 
            value={summaryData}
            loading={loading}
            globalFilter={globalFilterValue}
            globalFilterFields={globalFilterFields}
            globalFilterMatchMode={FilterMatchMode.CONTAINS}
            data-cy="psc-performance-table"
            scrollHeight="55vh"
            paginator
            scrollable
            removableSort
            stripedRows
            first={performancePage.first}
            rows={performancePage.row}
            rowsPerPageOptions={rowOptions}
            onPage={onPage}
            header={renderHeader}
            headerColumnGroup={headers}
        >
            {columns}
        </DataTable>
    )
};