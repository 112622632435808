import { getTranslationObject } from "../../../services/Translation";
import {FilterMatchMode, FilterService} from "primereact/api";
import { buildColumnConfig } from "../../Shared/TableFunctions";
import {DefectTableFields} from "../DefectTableFields";
import {customEstimateDefectCostFilter} from "./EstimatedCostFilter";
import {customInspectionDateFilter} from "../../Shared/FilterTemplates";
import {customGradingAreaFilter} from "./GradingAreaFilter";
import LOCAL_TABLE_CONFIG_KEY from "../../../types/TableLocalConfigKeys";
import {getLocalStorageOrderedColumns} from "../../../utils/TableUtils";
import { SharedDefectTableFields } from "../SharedDefectTableFields";
import {DefectStatus} from "../../../types/DefectStatus";
import {customGradeFilter} from "@idwal/idwal-react-components";
import { ColumnSortEvent } from "primereact/column";

const t = getTranslationObject();

export const defaultDefectsFiltersConfig = {
    "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
    [DefectTableFields.DEFECT_ID]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [DefectTableFields.PRIORITY]: { value: null, matchMode: FilterMatchMode.IN },
    [DefectTableFields.GRADING_AREA_FIELD]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [DefectTableFields.DESCRIPTION]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [DefectTableFields.CONTAINS_IMAGES]: { value: null, matchMode: FilterMatchMode.IN },
    [DefectTableFields.RECOMMENDATIONS]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [DefectTableFields.ESTIMATE_DEFECT_COST_FIELD]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [DefectTableFields.NAME_FILTER_FIELD]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [DefectTableFields.TYPE]: { value: null, matchMode: FilterMatchMode.IN },
    [DefectTableFields.INSPECTION_DATE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [DefectTableFields.IDWAL_GRADE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [DefectTableFields.TECHNICAL_MANAGER]: { value: null, matchMode: FilterMatchMode.IN },
    [DefectTableFields.STATUS]: { value: [DefectStatus.open,DefectStatus.rectified ,DefectStatus.inProgress], matchMode: FilterMatchMode.IN },
    [DefectTableFields.NOTES]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ...{ [DefectTableFields.RECTIFICATION]: { value: null, matchMode: FilterMatchMode.IN } },
}

export const defaultSharedDefectsFiltersConfig = {
    "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SharedDefectTableFields.DEFECT_ID]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SharedDefectTableFields.PRIORITY]: { value: null, matchMode: FilterMatchMode.IN },
    [SharedDefectTableFields.GRADING_AREA_FIELD]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SharedDefectTableFields.DESCRIPTION]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SharedDefectTableFields.CONTAINS_IMAGES]: { value: null, matchMode: FilterMatchMode.IN },
    [SharedDefectTableFields.RECOMMENDATIONS]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SharedDefectTableFields.ESTIMATE_DEFECT_COST_FIELD]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SharedDefectTableFields.NAME_FILTER_FIELD]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SharedDefectTableFields.INSPECTION_DATE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SharedDefectTableFields.STATUS]: { value: null, matchMode: FilterMatchMode.IN },
    ...{ [DefectTableFields.RECTIFICATION]: { value: null, matchMode: FilterMatchMode.IN } },
}

const sortByDate = (event: ColumnSortEvent) => {
    const { order, field, data } = event;

    // ascending
    if (order === 1) {
        return data.slice().sort((a: any, b: any) => {
            const dateA = a[field].split('/').reverse().join();
            const dateB = b[field].split('/').reverse().join();
            return dateA < dateB ? -1 : (dateA > dateB ? 1 : 0);
        })
    } else {
        return data.slice().sort((a: any, b: any) => {
            const dateA = a[field].split('/').reverse().join();
            const dateB = b[field].split('/').reverse().join();
            return dateA > dateB ? -1 : (dateA < dateB ? 1 : 0);
        })
    }
};

export const getDefaultDefectsColumnsConfig = () => {
    const columns =  [
        buildColumnConfig(
            DefectTableFields.INSPECTION_DATE,
            t("defectsTable.inspectionDate"),
            "date",
            {
                filterField: DefectTableFields.INSPECTION_DATE,
                showFilterMatchModes: true,
                filterMatchMode: FilterMatchMode.CUSTOM,
            },
            { justifyContent: "start" },
            undefined,
            undefined,
            undefined,
            sortByDate,
        ),
        buildColumnConfig(
            DefectTableFields.IMAGES,
            t("defectsTable.images"),
            "text",
            {
                filterField: DefectTableFields.CONTAINS_IMAGES,
                filterMatchMode: FilterMatchMode.IN,
            },
            { justifyContent: "center" }
        ),
        buildColumnConfig(
            DefectTableFields.RECOMMENDATIONS,
            t("defectsTable.recommendations"),
            "text",
            {
                filterField: DefectTableFields.RECOMMENDATIONS,
                showFilterMenu: true,
                showFilterMatchModes: true,
                filterPlaceholder: t("defectsTable.searchRecommendations"),
            }
        ),
        buildColumnConfig(
            DefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
            t("defectsTable.estimateDefectCost"),
            "numeric",
            {
                filterField: DefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
                showFilterMatchModes: true,
                filterMatchMode: FilterMatchMode.CUSTOM,
            },
            { justifyContent: "center", textAlign: "center" }
        ),
        buildColumnConfig(
            DefectTableFields.TYPE,
            t("defectsTable.vesselType"),
            "text",
            {
                filterField: DefectTableFields.TYPE,
                filterMatchMode: FilterMatchMode.IN,
                filterPlaceholder: t("defectsTable.searchByType"),
            }
        ),
        buildColumnConfig(
            DefectTableFields.IDWAL_GRADE,
            t("defectsTable.idwalGrade"),
            "numeric",
            {
                filterField: DefectTableFields.IDWAL_GRADE,
                showFilterMatchModes: true,
                filterMatchMode: FilterMatchMode.CUSTOM,
            },
            { justifyContent: "center" }
        ),
        buildColumnConfig(
            DefectTableFields.TECHNICAL_MANAGER,
            t("defectsTable.technicalManager"),
            "text",
            {
                filterField: DefectTableFields.TECHNICAL_MANAGER,
                filterMatchMode: FilterMatchMode.IN,
                showFilterMatchModes: false,
            }
        ),
        buildColumnConfig(
            DefectTableFields.NOTES,
            t("defectsTable.notes"),
            "text",
            {
                filterField: DefectTableFields.NOTES,
                showFilterMenu: true,
                showFilterMatchModes: true,
                filterPlaceholder: t("defectsTable.searchNotes"),
            }
        ),
    ];
    const localOrderedColumnsConfig = getLocalStorageOrderedColumns(LOCAL_TABLE_CONFIG_KEY.DEFECT_ORDERED_COLUMNS, columns);
    if (localOrderedColumnsConfig)
    {
        return localOrderedColumnsConfig;
    }

    return columns;
};

export const getStaticDefectsColumnsConfig = (defectMapping?: boolean) => {
  const columns = [
      buildColumnConfig(
          DefectTableFields.VESSEL_NAME,
          t("defectsTable.vessel"),
          "text",
          {
              filterField: DefectTableFields.NAME_FILTER_FIELD,
              showFilterMenu: true,
              showFilterMatchModes: true,
              filterPlaceholder: t("defectsTable.searchByName"),
          },
          { justifyContent: "center" },
          true,

      ),
      buildColumnConfig(
          DefectTableFields.ACTIONS,
          t("defectsTable.actions"),
          "text",
          {
              filterField: DefectTableFields.ACTIONS,
              showFilterMenu: false,
              showFilterMatchModes: false,
              filterPlaceholder: t("defectsTable.searchByDefectID"),
          },
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          false,
          false,
          false
      ),
      buildColumnConfig(DefectTableFields.PRIORITY, t("defectsTable.priority"),"numeric",
          { filterField: DefectTableFields.PRIORITY, filterPlaceholder: t("defectsTable.searchByName"), filterMatchMode: FilterMatchMode.IN },
          { justifyContent: "center" }),
      buildColumnConfig(
        DefectTableFields.DEFECT_ID,
        t("defectsTable.defectID"),
        "text",
        { 
            filterField: DefectTableFields.DEFECT_ID,
            showFilterMenu: true,
            showFilterMatchModes: true,
            filterPlaceholder: t("defectsTable.searchByDefectID"),
        },
        undefined,
        false
    ),
      buildColumnConfig(
          SharedDefectTableFields.STATUS,
          t("defectsTable.status"),
          "numeric",
          {
              filterField: SharedDefectTableFields.STATUS,
              filterMatchMode: FilterMatchMode.IN,
          },
          { justifyContent: "center" }
      ),

      buildColumnConfig(DefectTableFields.GRADING_AREA_FIELD, t("defectsTable.gradingArea"), "text",
      { filterField: DefectTableFields.GRADING_AREA_FIELD, filterMatchMode: FilterMatchMode.CUSTOM, filterPlaceholder: t("defectsTable.searchByType")}),
    buildColumnConfig(DefectTableFields.DESCRIPTION, t("defectsTable.description"), "text",
      { filterField: DefectTableFields.DESCRIPTION, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchDescription")}),
  ];

    if (defectMapping) {
        columns.splice(5, 0, 
            buildColumnConfig(DefectTableFields.TYPE_CODE, t("defectsTable.typeCode"), "text",
            { filterField: DefectTableFields.TYPE_CODE, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchByTypeCode")}),
        );
        columns.splice(6, 0,
            buildColumnConfig(DefectTableFields.SHORT_GEN_NAME, t("defectsTable.shortGenName"), "text",
            { filterField: DefectTableFields.SHORT_GEN_NAME, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchByShortGenName")}),
        );
    }
    
    return columns;
}

export const getSharedDefectsColumnsConfig = (defectMapping?: boolean) => {
    const columns = [
        buildColumnConfig(
            SharedDefectTableFields.VESSEL_NAME,
            t("defectsTable.vessel"),
            "text",
            {
                filterField: SharedDefectTableFields.NAME_FILTER_FIELD,
                showFilterMenu: true,
                showFilterMatchModes: true,
                filterPlaceholder: t("defectsTable.searchByName"),
            },
            { justifyContent: "center" },
            true
        ),
        buildColumnConfig(
            DefectTableFields.ACTIONS,
            t("defectsTable.actions"),
            "text",
            {
                filterField: DefectTableFields.ACTIONS,
                showFilterMenu: false,
                showFilterMatchModes: false,
                filterPlaceholder: t("defectsTable.searchByDefectID"),
            },
            undefined,
            false,
            undefined,
            undefined,
            undefined,
            false,
            false,
            false
        ),
        buildColumnConfig(SharedDefectTableFields.PRIORITY, t("defectsTable.priority"),"numeric",
            { filterField: SharedDefectTableFields.PRIORITY, filterPlaceholder: t("defectsTable.searchByName"), filterMatchMode: FilterMatchMode.CUSTOM },
            { justifyContent: "center" }),
        buildColumnConfig(
            SharedDefectTableFields.DEFECT_ID,
            t("defectsTable.defectID"),
            "text",
            { 
                filterField: SharedDefectTableFields.DEFECT_ID,
                showFilterMenu: true,
                showFilterMatchModes: true,
                filterPlaceholder: t("defectsTable.searchByDefectID"),
            },
            undefined,
            false,
        ),
        buildColumnConfig(
            SharedDefectTableFields.STATUS,
            t("defectsTable.status"),
            "numeric",
            {
                filterField: SharedDefectTableFields.STATUS,
                filterMatchMode: FilterMatchMode.IN,
            },
            { justifyContent: "center" }
        ),
      buildColumnConfig(SharedDefectTableFields.GRADING_AREA_FIELD, t("defectsTable.gradingArea"), "text",
        { filterField: SharedDefectTableFields.GRADING_AREA_FIELD, filterMatchMode: FilterMatchMode.CUSTOM, filterPlaceholder: t("defectsTable.searchByType")}),
      buildColumnConfig(SharedDefectTableFields.DESCRIPTION, t("defectsTable.description"), "text",
        { filterField: SharedDefectTableFields.DESCRIPTION, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchDescription")}),
        buildColumnConfig(
            SharedDefectTableFields.IMAGES,
            t("defectsTable.images"),
            "text",
            {
                filterField: SharedDefectTableFields.CONTAINS_IMAGES,
                filterMatchMode: FilterMatchMode.IN,
            },
            { justifyContent: "center", textAlign: "center" }
        ),
        buildColumnConfig(
            SharedDefectTableFields.RECOMMENDATIONS,
            t("defectsTable.recommendations"),
            "text",
            {
                filterField: SharedDefectTableFields.RECOMMENDATIONS,
                showFilterMenu: true,
                showFilterMatchModes: true,
                filterPlaceholder: t("defectsTable.searchRecommendations"),
            }
        ),
        buildColumnConfig(
            SharedDefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
            t("defectsTable.estimateDefectCost"),
            "numeric",
            {
                filterField: SharedDefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
                showFilterMatchModes: true,
                filterMatchMode: FilterMatchMode.CUSTOM,
            },
            { justifyContent: "center", textAlign: "center" }
        ),
        buildColumnConfig(
            SharedDefectTableFields.INSPECTION_DATE,
            t("defectsTable.inspectionDate"),
            "date",
            {
                filterField: SharedDefectTableFields.INSPECTION_DATE,
                showFilterMatchModes: true,
                filterMatchMode: FilterMatchMode.CUSTOM,
            },
            { justifyContent: "start" },
        )
    ];

    if (defectMapping) {
        columns.splice(5, 0, 
            buildColumnConfig(SharedDefectTableFields.TYPE_CODE, t("defectsTable.typeCode"), "text",
                { filterField: SharedDefectTableFields.TYPE_CODE, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchByTypeCode")}),
        );
        columns.splice(6, 0,
            buildColumnConfig(SharedDefectTableFields.SHORT_GEN_NAME, t("defectsTable.shortGenName"), "text",
                { filterField: SharedDefectTableFields.SHORT_GEN_NAME, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("defectsTable.searchByShortGenName")}),
        );
    }

    return columns
  }

export const registerCustomFilters = () => {
    FilterService.register(`custom_${DefectTableFields.ESTIMATE_DEFECT_COST_FIELD}`, customEstimateDefectCostFilter);
    FilterService.register(`custom_${DefectTableFields.INSPECTION_DATE}`, customInspectionDateFilter);
    FilterService.register(`custom_${DefectTableFields.IDWAL_GRADE}`, customGradeFilter);
    FilterService.register(`custom_${DefectTableFields.GRADING_AREA_FIELD}`, customGradingAreaFilter);
}
