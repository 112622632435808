import { Auth } from "aws-amplify";

export const getCurrentSessionIdToken = async (): Promise<string> => {
    const session = await Auth.currentSession();

    let token = "";
    if (session) {
        token = session.getIdToken().getJwtToken();
    }

    return token;
}