import { useTranslation } from "react-i18next";
import { MenuButton } from "../MenuButton/MenuButton";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { ScreeningItem } from "../../types/screening";
import { removeData, setProspects } from "../../store/slices/screeningSlice";
import { useMemo } from "react";
import { selectScreeningProspects } from "../../store/selectors/screeningSelectors";
import { updateScreeningProspects } from "../../services/ScreeningService";
import { openUrl } from "../../services/WindowOpenService";
import { infoToast } from "@idwal/idwal-react-components";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, SCREENING_REMOVE_VESSEL_FROM_LIST_CLICK, SCREENING_VIEW_REPORT_CLICK, SCREENING_VIEW_REPORT_NEW_TAB_CLICK } from "../../constants/analytics";

export const ScreeningActionMenu = (item: ScreeningItem) => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const prospects = useAppSelector(selectScreeningProspects);
    const screeningReportUrl = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/screening/${item.vessel_imo}`

    const menuItems = useMemo(
        () => [
            {
                label: t("screening.viewScreeningReport"),
                className: "screening-actions",
                icon: <img className="pr-2" alt="screening_alt" src="./screening-alt.svg" />,
                command: () => {
                    openUrl(screeningReportUrl);
                    sendGAEvent(ANALYTICS_BUTTON_CLICK, SCREENING_VIEW_REPORT_CLICK);
                }
            },
            {
                label: t("screening.viewReportInNewTab"),
                className: "screening-actions",
                icon: <img className="pr-2" alt="screening" src="./screening.svg" />,
                command: () => {
                    window.open(screeningReportUrl, "_blank");
                    sendGAEvent(ANALYTICS_BUTTON_CLICK, SCREENING_VIEW_REPORT_NEW_TAB_CLICK);
                }
            },
            {
                label: t("screening.removeFromList"),
                className: "screening-remove-ship",
                icon: <img className="pr-2" alt="remove_ship" src="./remove-ship.svg" />,
                command: () => {
                    const updated = prospects.filter((prospect) => prospect !== item.vessel_imo);
                    updateScreeningProspects(updated);

                    dispatch(setProspects(updated));
                    dispatch(removeData(item));
                    sendGAEvent(ANALYTICS_BUTTON_CLICK, SCREENING_REMOVE_VESSEL_FROM_LIST_CLICK);
                    infoToast(t("screening.vesselRemovedAsProspect"));
                }
            }
        ],
        [prospects]
    );

    return (
        <div className="flex align-items-center justify-content-center">
            <MenuButton menu={menuItems} />
        </div>
    );
};
