import dayjs from "dayjs";
import { groupBy, sum, round } from "lodash";
import {
    PSCDeficiency,
    PSCInspection,
    PSCSummary,
    PSCTimePeriod
} from "../types/psc";
import { FleetDataItem } from "../types/FleetDataItem";

export const filterInspectionData = <
    T extends { vessel_imo: number; mou?: string; date?: number }
>(
    data: T[],
    imos: number[],
    mous: string[],
    timePeriod?: string
) => {
    // API data already filter by 36 months so only have to filter when 12 months selected
    if (timePeriod === PSCTimePeriod[1]) {
        const timePeriodTimestamp = dayjs().subtract(12, "month").unix();

        return data.filter(
            (item) =>
                imos.includes(item.vessel_imo) &&
                item.mou &&
                mous.includes(item.mou) &&
                item.date &&
                item.date >= timePeriodTimestamp
        );
    }

    return data.filter(
        (item) =>
            imos.includes(item.vessel_imo) &&
            item.mou &&
            mous.includes(item.mou)
    );
};

export const mapPSCInspectionData = (
    inspections: PSCInspection[],
    fleetDataItems: FleetDataItem[]
) => {
    const mapped: PSCInspection[] = [];

    inspections.forEach((inspection) => {
        const match = fleetDataItems.find(
            (fleetDataItem) =>
                inspection.vessel_imo === Number(fleetDataItem.vessel.imo)
        );

        if (match) {
            mapped.push({
                ...inspection,
                vessel_name: match.vessel.vesselName,
                vessel_type: match.vessel.type
            });
        }
    });

    return mapped.sort((a, b) => b.date - a.date);
};

export const mapPSCSummaryData = (inspections: PSCInspection[]) => {
    const groupedInspections = groupBy(inspections, "vessel_imo");
    const mappedSummaryData: PSCSummary[] = [];

    Object.entries(groupedInspections).forEach(([_, inspections]) => {
        if (inspections.length) {
            const latest = inspections[0];
            const num_inspections = inspections.length;
            const num_deficiencies = sum(
                inspections.map(
                    (inspection) => inspection.number_of_deficiencies
                )
            );
            const deficiency_per_inspection = round(
                num_deficiencies / num_inspections,
                2
            );
            const num_detentions = inspections.filter(
                (inspection) => inspection.detained === "Y"
            ).length;
            const detention_rate = round(num_detentions / num_inspections, 2);

            mappedSummaryData.push({
                vessel_imo: latest.vessel_imo,
                vessel_name: latest.vessel_name,
                vessel_type: latest.vessel_type,
                num_inspections,
                num_deficiencies,
                deficiency_per_inspection,
                num_detentions,
                detention_rate,
                date: latest.date,
                days_detained: latest.days_detained,
                detained: latest.detained,
                released: latest.released
            });
        }
    });

    return mappedSummaryData;
};

export const mapPSCDeficienciesData = (
    data: PSCDeficiency[],
    inspections: PSCInspection[]
) => {
    const groupedDeficencies = groupBy(data, "inspection_id");
    const mappedDeficienciesData: PSCDeficiency[] = [];

    Object.entries(groupedDeficencies).forEach(
        ([inspectionId, deficiencies]) => {
            const match = inspections.find(
                (inspection) =>
                    inspection.inspection_id === Number(inspectionId)
            );

            deficiencies.forEach((deficiency) => {
                if (match) {
                    mappedDeficienciesData.push({
                        ...deficiency,
                        vessel_imo: match.vessel_imo,
                        vessel_name: match.vessel_name,
                        vessel_type: match.vessel_type,
                        inspection_type: match.inspection_type,
                        location: match.location,
                        country: match.country,
                        mou: match.mou,
                        date: match.date
                    });
                } else {
                    mappedDeficienciesData.push(deficiency);
                }
            });
        }
    );

    return mappedDeficienciesData;
};

export const fillPSCSummaryData = (
    summaries: PSCSummary[],
    fleetDataItems: FleetDataItem[]
) => {
    const blankSummaries: PSCSummary[] = [];

    fleetDataItems.forEach((fleetDataItem) => {
        if (
            !summaries.find(
                (summary) =>
                    Number(fleetDataItem.vessel.imo) === summary.vessel_imo
            )
        ) {
            blankSummaries.push({
                vessel_imo: Number(fleetDataItem.vessel.imo),
                vessel_name: fleetDataItem.vessel.vesselName,
                vessel_type: fleetDataItem.vessel.type,
                num_inspections: 0,
                num_deficiencies: 0,
                deficiency_per_inspection: 0,
                num_detentions: 0,
                detention_rate: 0,
                date: 0,
                days_detained: 0,
                detained: "N",
                released: 0
            });
        }
    });

    return summaries.concat(blankSummaries);
};

export const getSummaryAggregates = (summaries: PSCSummary[]) => {
    let inspectionsTotal = 0;
    let deficienciesTotal = 0;
    let detentionsTotal = 0;
    let deficiencyPerInspection = 0;
    let detentionRate = 0;

    summaries.forEach((summary) => {
        inspectionsTotal += summary.num_inspections;
        deficienciesTotal += summary.num_deficiencies;
        detentionsTotal += summary.num_detentions;
    });

    if (inspectionsTotal) {
        if (deficienciesTotal) {
            deficiencyPerInspection = round(
                deficienciesTotal / inspectionsTotal,
                2
            );
        }

        if (detentionsTotal) {
            detentionRate = round(detentionsTotal / inspectionsTotal, 2);
        }
    }

    return {
        inspectionsTotal,
        deficienciesTotal,
        detentionsTotal,
        deficiencyPerInspection,
        detentionRate
    };
};
