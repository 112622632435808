import { useAppDispatch } from "../../hooks/storeHooks";
import { setActiveTab, setGlobalFilterValue, setInspectionsFilters } from "../../store/slices/pscSlice";
import { PSCSummary, PSCTabs } from "../../types/psc";
import { classNames } from "primereact/utils";
import { pscInspectionFilters } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";
import { useCallback } from "react";

export const PSCDetentionsBody = (item: PSCSummary) => {
    const dispatch = useAppDispatch();
    
    const value = item.num_detentions;

    const onClick = useCallback(() => {
        dispatch(setInspectionsFilters({
            ...pscInspectionFilters,
            vessel_name: {
                value: item.vessel_name,
                matchMode: FilterMatchMode.CONTAINS,
            },
            detained: {
                value: ["Y"],
                matchMode: FilterMatchMode.IN,
            }
        }))
        dispatch(setActiveTab(PSCTabs.inspections));
        dispatch(setGlobalFilterValue(""))
    }, [item]);

    return (
        <button 
            className={classNames("psc-table-link-btn", value ? "font-semibold cursor-pointer read-more-btn underline" : null)}
            onClick={onClick}
        >
            {value}
        </button>
    );
}