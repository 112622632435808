import { FleetDataItem } from "../types/FleetDataItem";
import { DefectDataItem } from "../types/DefectsDataItem";
import { VesselSubgradeData, VesselSubgradeResponse } from "../types/VesselSubgradeResponse";
import { GROUP_DROP_DOWN_ALL_CODE, GROUP_DROP_DOWN_KEY } from "./Constants";
import { InspectionAndGradeDataResponse } from "../types/InspectionAndGradeDataResponse";
import { GroupDetails } from "../types/GroupDetails";

const getSubgradeInspection = (inspectionId: string, inspectionAndGradeResponse: InspectionAndGradeDataResponse) =>  {
    return inspectionAndGradeResponse?.fleetDataItems.filter(
        (i) => 
            i.inspection?.guid !== undefined 
            && i.inspection.guid.toUpperCase() === inspectionId
        );
}

const getCorrespondingInspection = (gradeInspectionId: string, inspectionAndGradeResponse: InspectionAndGradeDataResponse) => {
    return inspectionAndGradeResponse?.fleetDataItems.find(
        (i) =>  i.inspection?.guid !== undefined && i.inspection.guid.toUpperCase() === gradeInspectionId
    );
}

export const transformSubgradesInspectionData = (
    subgrades: {[key: string]: VesselSubgradeData }, 
    inspectionAndGradeResponse: InspectionAndGradeDataResponse
) => {
    return Object.assign(
        {}, 
        ...Object.entries(subgrades)
            .map(
                ([key, subgradeData]) => {
                    const gradeInspectionId = subgradeData.id.toUpperCase();
                    const inspection = getCorrespondingInspection(gradeInspectionId, inspectionAndGradeResponse);

                    if (!inspection) {
                        console.warn("No corresponding inspection found for subgrade ID: ", gradeInspectionId)
                    }
                    
                    return {
                        [key]: { 
                            ...subgradeData, 
                            vesselName: inspection?.vessel.vesselName, 
                            technicalManager: inspection?.vessel.technicalManager
                        }
                    }
                }
            )
        );
}

export const getSubGradesForGroup = (
    selectedGroupName: string, 
    subgradeResponse: VesselSubgradeResponse, 
    inspectionAndGradeResponse: InspectionAndGradeDataResponse
) => {
    let groupSubGrades: {[key: string]: VesselSubgradeData } = {};
    const subGradeKeys = Object.keys(subgradeResponse);

    if (selectedGroupName !== GROUP_DROP_DOWN_ALL_CODE && inspectionAndGradeResponse?.groupCount > 1) {

        subGradeKeys.forEach((key) => {
            const gradeInspectionId = subgradeResponse[key].id.toUpperCase();

            const inspection = getCorrespondingInspection(gradeInspectionId, inspectionAndGradeResponse);

            if (!inspection) {
                console.warn("No corresponding inspection found for subgrade ID: ", gradeInspectionId)
            }

            if (inspection && inspection.vessel.group === selectedGroupName) {
                groupSubGrades[key] = {} as VesselSubgradeData;
                groupSubGrades[key] = subgradeResponse[key];

            }
        });
    } else {
        groupSubGrades = subgradeResponse;
    }

    return transformSubgradesInspectionData(groupSubGrades, inspectionAndGradeResponse);
}

export const getFleetItemsForGroup = (selectedGroupName: string, numberOfGroups: number, fleetDataItems: FleetDataItem[]) => {
    let fleetItems: FleetDataItem[] = [];
    if (selectedGroupName != GROUP_DROP_DOWN_ALL_CODE && numberOfGroups > 1) {
        fleetDataItems.forEach((item) => {
            if (item.vessel.group === selectedGroupName) {
                fleetItems.push(item);
            }
        });
    } else {
        fleetItems = fleetDataItems;
    }

    return fleetItems;
}

export const getDefectsForGroup = (selectedGroupName: string, defects: DefectDataItem[], fleetData: InspectionAndGradeDataResponse) => {
    let defectDataItems: DefectDataItem[] = [];

    if (defects.length) {
        if (selectedGroupName !== GROUP_DROP_DOWN_ALL_CODE && fleetData?.groupCount > 1) {

            defects.forEach((defect) => {
    
                const tableInspection = fleetData.fleetDataItems.filter((i) => i.inspection !== undefined && i.inspection.guid === defect.inspectionId);
                if (tableInspection.length > 0 &&  tableInspection[0].vessel.group === selectedGroupName) {
                    defectDataItems.push({...defect});
                }
            });
        } else {
            defectDataItems = [...defects];
        }
    }
    
    return defectDataItems;
}

export const getGroupNameFromLocalStore = () => {
    let localStorageGroup = sessionStorage.getItem(GROUP_DROP_DOWN_KEY);
    if (localStorageGroup === null) {
        return GROUP_DROP_DOWN_ALL_CODE;
    }
    return localStorageGroup;
}

export const getDefaultGroupName = (groupDetails: GroupDetails | undefined) => {
    let selectedGroupName = "";

    const groupNames = Object.getOwnPropertyNames(groupDetails);

    if (groupNames.length) {
        if (groupNames.length === 1) {
            selectedGroupName = groupNames[0];
        } else {
            const localStorageGroup = getGroupNameFromLocalStore();

            if(localStorageGroup != GROUP_DROP_DOWN_ALL_CODE && !groupNames.includes(localStorageGroup)){
                selectedGroupName = groupNames[0];
            } else {
                selectedGroupName = localStorageGroup;
            }
        }
    }

    return selectedGroupName;
}

export const getSelectedGroupContractDetails = (
    selectedGroupName: string,
    groupDetails: GroupDetails | undefined
) => {
    const contractStartDate = groupDetails?.[selectedGroupName]?.contractStartDate;
    const contractEndDate = groupDetails?.[selectedGroupName]?.contractEndDate;

    return (
            selectedGroupName !== GROUP_DROP_DOWN_ALL_CODE &&
            contractStartDate &&
            contractEndDate
        ) 
            ? 
                {
                    contractStartDate, 
                    contractEndDate
                }
            :
                null
}