import { MouseEvent, useCallback, useMemo, useRef } from "react";
import { getGradeComponent } from "@idwal/idwal-react-components";
import { ScreeningItem } from "../../types/screening";
import { getScreeningGrade } from "../../utils/ScreeningTableHelper";
import { OverlayPanel } from "primereact/overlaypanel";

export const ScreeningGradeBody = (item: ScreeningItem) => {
    const grade = useMemo(
        () => getGradeComponent(getScreeningGrade(item.estimated_grade)),
        [item]
    );

    const ref = useRef<OverlayPanel>(null);

    const onMouseEnter = useCallback((event: MouseEvent) => {
        if (ref.current) {
            ref.current.show(event, event.currentTarget);
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        if (ref.current) {
            ref.current.hide();
        }
    }, []);

    return (
        <>
            <OverlayPanel ref={ref} className="screening-grade-info">
                <div className="flex gap-3">
                    <div className="flex flex-column justify-content-between">
                        <img
                            src={item.image_url}
                            alt={item.estimated_grade}
                            className="screening-grade-image"
                        />
                        <div>
                            <p className="screening-grade-name">{item.vessel_name}</p>
                            <small>{`IMO ${item.vessel_imo}`}</small>
                        </div>
                    </div>
                    <div className="flex flex-column p-3 screening-grade-summary">
                        <p>{item.summary_short.replaceAll("\n", "\n\n")}</p>
                    </div>
                </div>
            </OverlayPanel>
            <div
                className="flex justify-content-center screening-grade-body"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {grade}
            </div>
        </>
    );
};
