import { useTranslation } from "react-i18next";

export const PSCInspectionTypeBody = <T extends { inspection_id: string | number, inspection_type: string }>(item: T) => {
    const { t } = useTranslation("locale");

    return (
        <div className="justify-content-start">
            <div>
                <p>{t(`psc.${item.inspection_type}`)}</p>
                <small>{item.inspection_id}</small>
            </div>
        </div>
    );
}