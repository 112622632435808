import { useCallback, useMemo } from "react";
import { vesselBodyTemplate } from "@idwal/idwal-react-components";
import { ScreeningItem } from "../../types/screening";

export const ScreeningVesselBody = (item: ScreeningItem) => {
    const vesselBodyTemplateData = useMemo(() => ({
        imo: item.vessel_imo,
        vesselName: item.vessel_name,
    }), [item]);

    const selectVessel = useCallback(() => {}, []);

    return vesselBodyTemplate(vesselBodyTemplateData, selectVessel, false);
}