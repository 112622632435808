import { useTranslation } from "react-i18next";
import { PSCInspection } from "../../types/psc";

export const PSCDurationBody = (item: PSCInspection) => {
    const { t } = useTranslation("locale");

    if (item.days_detained === undefined) {
        return null;
    }

    if (item.detained === "Y" && item.days_detained === 0){
        return null;
    }

    if (item.detained === "N"){
        return <p className="psc-not-applicable-label">{t("psc.notApplicable")}</p>
    }

    return <p>{`${item.days_detained} ${item.days_detained === 1 ? t("psc.day") : t("psc.days")}`}</p>;
}