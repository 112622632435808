import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { FleetTableFields } from "./FleetTableFields";
import { buildCountryFilterTemplate, flagFilterTemplate, gradeFilterTemplate, multiSelectFilterTemplate, riskLevelFilterTemplate, shipTypeFilterTemplate, technicalManagerFilterTemplate } from "../Shared/FilterTemplates";
import {
    daysSinceInspectedFilterTemplate,
    dateFilterTemplate,
    rankFilterTemplate,
    trendingFilterTemplate
} from "./FilterTemplates";
import {
    actionBodyTemplate,
    idwalGradeBody,
    idwalTrendingTemplate,
    noteBodyTemplate,
    riskLevelBodyTemplate,
    vesselInspectionStatusTemplate,
    vesselTypeBodyTemplate,
    lastInspectedTemplate
} from "../Shared/BodyTemplates";
import { getTranslationObject } from "../../services/Translation";
import { SelectItemOptionsType } from "primereact/selectitem";
import { ID_PLATFORM_INSPECTION_STATUS_MAPPING } from "@idwal/idwal-utilities/lib/types/AsimsStatusMapping";
import { INSPECTION_STATUS } from "@idwal/idwal-utilities/lib/types/InspectionStatus";
import {
    daysSinceInspectionBodyTemplate,
    inspectionDueBodyTemplate,
    specialSurveyDateBodyTemplate
} from "@idwal/idwal-react-components";


const t = getTranslationObject();

export const keywordSearchFilterFields = (aiEnabled: boolean) => [
    FleetTableFields.NAME_FILTER_FIELD,
    FleetTableFields.INSPECTION_DATE_FILTER_FIELD,
    FleetTableFields.DATE_SINCE_FILTER_FIELD,
    FleetTableFields.VESSEL_TYPE,
    FleetTableFields.VESSEL_SUBTYPE,
    FleetTableFields.VESSEL_TECHNICAL_MANAGER,
    FleetTableFields.INSPECTION_INSPECTION_DUE,
    FleetTableFields.INSPECTION_DAYS_SINCE,
    FleetTableFields.GRADE_FILTER_FIELD,
    FleetTableFields.RANK,
    FleetTableFields.VESSEL_CLASS_SOCIETY,
    FleetTableFields.VESSEL_FLAG,
    FleetTableFields.VESSEL_DWT,
    FleetTableFields.VESSEL_BUILD_YEAR,
    ... aiEnabled ? [FleetTableFields.VESSEL_GRADES_PREDICTED_GRADE_MIN] : [],
    ... aiEnabled ? [FleetTableFields.VESSEL_GRADES_PREDICTED_GRADE_MAX] : [],
    ... aiEnabled ? [FleetTableFields.VESSEL_GRADES_AI_GRADE_FILTER_FIELD] : [],
    FleetTableFields.INSPECTION_LOCATION_FILTER_FIELD,
    FleetTableFields.INSPECTION_DATE_OF_INSPECTION_FILTER_FIELD,
    FleetTableFields.INSPECTION_CONDITION_SCORE,
    FleetTableFields.INSPECTION_MANAGEMENT_SCORE,
    FleetTableFields.VESSEL_COUNTRY_OF_BUILD,
    FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE_FILTER_FIELD,
    FleetTableFields.RISK_LEVEL,
    FleetTableFields.VESSEL_NOTES,
    FleetTableFields.TRENDING,
    FleetTableFields.ACTIONS,
    FleetTableFields.LAST_INSPECTED
];

export const columnTemplates = (aiGradingActive: boolean,inspectionRequestCallBack: any) => {
    return [
        {column: FleetTableFields.VESSEL_TYPE, bodyTemplate: vesselTypeBodyTemplate },
        {column: FleetTableFields.GRADE_FILTER_FIELD, bodyTemplate: (e: any) => idwalGradeBody(e, aiGradingActive) },
        {column: FleetTableFields.INSPECTION_DAYS_SINCE, bodyTemplate: daysSinceInspectionBodyTemplate },
        {column: FleetTableFields.INSPECTION_INSPECTION_DUE, bodyTemplate: inspectionDueBodyTemplate },
        {column: FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE, bodyTemplate: specialSurveyDateBodyTemplate },
        {column: FleetTableFields.RISK_LEVEL, bodyTemplate: riskLevelBodyTemplate },
        {column: FleetTableFields.VESSEL_NOTES, bodyTemplate: noteBodyTemplate},
        {column: FleetTableFields.VESSEL_INSPECTION_STATUS, bodyTemplate: vesselInspectionStatusTemplate},
        {column: FleetTableFields.TRENDING, bodyTemplate: idwalTrendingTemplate},
        {column: FleetTableFields.LAST_INSPECTED, bodyTemplate: lastInspectedTemplate },
        {column: FleetTableFields.ACTIONS, bodyTemplate: (e: any) => actionBodyTemplate(e, aiGradingActive, inspectionRequestCallBack ) },
    ]
}

export const filterTemplates = (shipTypes: string[], technicalManagers: string[], flags: string[], buildCountries: string[], inspectionStatusFilters: SelectItemOptionsType[]) => {
    return [
        { column: FleetTableFields.VESSEL_TYPE, filterTemplate: (options: ColumnFilterElementTemplateOptions) => shipTypeFilterTemplate(options, shipTypes) },
        { column: FleetTableFields.RISK_LEVEL, filterTemplate: (options: ColumnFilterElementTemplateOptions) => riskLevelFilterTemplate(options) },
        { column: FleetTableFields.VESSEL_TECHNICAL_MANAGER, filterTemplate: (options: ColumnFilterElementTemplateOptions) => technicalManagerFilterTemplate(options, technicalManagers) },
        { column: FleetTableFields.GRADE_FILTER_FIELD, filterTemplate: gradeFilterTemplate },
        { column: FleetTableFields.INSPECTION_CONDITION_SCORE, filterTemplate: gradeFilterTemplate },
        { column: FleetTableFields.INSPECTION_MANAGEMENT_SCORE, filterTemplate: gradeFilterTemplate },
        { column: FleetTableFields.INSPECTION_DAYS_SINCE, filterTemplate: daysSinceInspectedFilterTemplate },
        { column: FleetTableFields.INSPECTION_INSPECTION_DUE, filterTemplate: dateFilterTemplate },
        { column: FleetTableFields.RANK, filterTemplate: rankFilterTemplate },
        { column: FleetTableFields.VESSEL_FLAG, filterTemplate: (options: ColumnFilterElementTemplateOptions) => flagFilterTemplate(options, flags) },
        { column: FleetTableFields.VESSEL_COUNTRY_OF_BUILD, filterTemplate: (options: ColumnFilterElementTemplateOptions) => buildCountryFilterTemplate(options, buildCountries) },
        { column: FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE, filterTemplate: dateFilterTemplate },
        { column: FleetTableFields.VESSEL_INSPECTION_STATUS, filterTemplate: (options: ColumnFilterElementTemplateOptions) => multiSelectFilterTemplate(options, inspectionStatusFilters)},
        { column: FleetTableFields.TRENDING, filterTemplate: trendingFilterTemplate },
        { column: FleetTableFields.LAST_INSPECTED, filterTemplate: daysSinceInspectedFilterTemplate },
    ];
}

export const getStatusGroup = (status: number) => {
    return ID_PLATFORM_INSPECTION_STATUS_MAPPING[status].translationKey || "";
};

export const getStatusArray = (filterStatus: string) => {
    switch (filterStatus) {
        case t("inspectionStatuses.ordered"):
            return [
            	INSPECTION_STATUS.NEW,
              	INSPECTION_STATUS.BIDDING,
              	INSPECTION_STATUS.CONFIRMED,
            ];
        case t("inspectionStatuses.committed"):
            return [
                INSPECTION_STATUS.UPCOMING,
            ];
        case t("inspectionStatuses.inProgress"):
            return [INSPECTION_STATUS.COMMENCED];
        case t("inspectionStatuses.inspectionComplete"):
            return [INSPECTION_STATUS.INSPECTION_COMPLETED];
        case t("inspectionStatuses.fullReport"):
            return [INSPECTION_STATUS.FULL_REPORT];
        default:
            return [];
    }
};
