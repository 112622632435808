import { errorToast } from "@idwal/idwal-react-components";
import { dataRequest, putRequest } from "./DataRequest";
import { ScreeningItem } from "../types/screening";

export const getScreeningData = async (imos: (string | number)[]) => {
    try {
        return await dataRequest(`/Screening?imos=${imos.join(",")}`) as ScreeningItem[];
    } catch (e) {
        console.error(e);
    }

    return [];
};

export const getScreeningProspects = async () => {
    try {
        return await dataRequest("/Screening/prospects") as number[]
    } catch (e) {
        console.error(e);
    }

    return [];
}

export const updateScreeningProspects = async (imos: number[]) => {
    try {
        await putRequest("/Screening/prospects", imos) as string[]
    } catch (e) {
        console.error(e);
        errorToast("Failed to save screening prospect");
    }
}