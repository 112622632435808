import { useAppDispatch } from "../../hooks/storeHooks";
import { setActiveTab, setDeficienciesFilters, setGlobalFilterValue } from "../../store/slices/pscSlice";
import { PSCSummary, PSCTabs } from "../../types/psc";
import { classNames } from "primereact/utils";
import { pscDeficienciesFilters } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";
import { useCallback } from "react";

export const PSCDeficienciesBody = (item: PSCSummary) => {
    const dispatch = useAppDispatch();

    const value = item.num_deficiencies

    const onClick = useCallback(() => {
        dispatch(setDeficienciesFilters({
            ...pscDeficienciesFilters,
            vessel_name: {
                value: item.vessel_name,
                matchMode: FilterMatchMode.CONTAINS,
            }
        }))
        dispatch(setActiveTab(PSCTabs.deficiencies));
        dispatch(setGlobalFilterValue(""))
    }, [item]);

    return (
        <button 
            className={classNames("psc-table-link-btn", value ? "font-semibold cursor-pointer read-more-btn underline" : null)}
            onClick={onClick}
        >
            {value}
        </button>
    );
}