import { FleetDataItem } from "../types/FleetDataItem";
import { VesselTypeWithCount } from "../types/VesselTypeWithCount";
import {INSPECTION_STATUS_COMPLETED, INSPECTION_STATUS_FULL_REPORT, INSPECTION_STATUS_UPCOMING} from "../utils/Constants";
import { uniq } from "lodash";

export const toVesselTypeWithCount = (fleetDataItem: Array<FleetDataItem>): VesselTypeWithCount[] => {
    const groupedData: Record<string, Array<FleetDataItem>> = fleetDataItem.reduce((acc, item) => {
        const key = item.vessel.type;
    
        if (!acc[key]) {
            acc[key] = [];
        }
    
        acc[key].push(item);
    
        return acc;
    }, {} as Record<string, Array<FleetDataItem>>);

    return Object.entries(groupedData).map(
        ([type, items]) => {
            return {
                vesselType: type,
                count: items.length
            }
        }
    )
}

export const convertInspectionDates = (tableData: FleetDataItem[]) => {
    tableData.forEach((data: any) => {
        
        if (data.inspection?.hasUpcoming) {
            data.inspection.inspectionType = INSPECTION_STATUS_UPCOMING;
            data.inspection.inspectionDue = null;
            data.scheduledDate = null;
            return;
        }

        if (data.scheduledDate && !(data.scheduledDate instanceof Date)) {
            const parts = data.scheduledDate.split("/");
            const dt = new Date(parseInt(parts[2], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[0], 10));

            data.scheduledDate = dt;
        }

        if (!data.inspection) {
            return;
        }

        try {
            if (!data.inspection.isScheduled && data.scheduledDate) {
                data.inspection.inspectionDue = data.scheduledDate;
                return;
            }

            if (!data.inspection.isScheduled && !data.scheduledDate) {
                data.inspection.inspectionDue = null;
                return;
            }

            data.inspection.inspectionDue = new Date(data.inspection.inspectionDue);
        } catch (err) {
            console.error("Error when attempting to convert inspection due to a friendly format.");
            console.error(err);
        }
    });
};

export const calculateDaysSince = (tableData: FleetDataItem[], calculateFrom?: Date | null, skipDateConversion=false) => {
    tableData.forEach((data: any) => {
        try {
            if (data.inspection && (data.inspection.status === INSPECTION_STATUS_COMPLETED || data.inspection.status === INSPECTION_STATUS_FULL_REPORT)) {
                if (!calculateFrom) {
                    calculateFrom = new Date();
                }
                let dateOfInspection = data.inspection.lastInspected;

                if (!skipDateConversion) {
                    dateOfInspection = new Date(dateOfInspection);
                }

                if (calculateFrom < dateOfInspection) {
                    return;
                }

                const diffTime = Math.abs(dateOfInspection.getTime() - calculateFrom.getTime());
                data.inspection.daysSince = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                data.inspection.dateOfInspection = dateOfInspection;
            } else {
                return;
            }
        } catch (err) {
            console.error("Error when attempting to calculate days since inspection due.");
            console.error(err);
        }
    });
};

export const rankData = (data: FleetDataItem[], aiGradingActive: boolean) => {
    let rankCounter = 1;

    let gradedData: FleetDataItem[] = [];
    let noGradingData: FleetDataItem[] = [];

    data.forEach((fleetDataItem: FleetDataItem) => {
        if (fleetDataItem.inspection?.idwalGrade && fleetDataItem.inspection?.status === INSPECTION_STATUS_FULL_REPORT) {
            if (aiGradingActive && fleetDataItem.aiGrades?.evolutionGrade) {
                fleetDataItem.gradeForSorting = fleetDataItem.aiGrades.evolutionGrade;
            } else {
                fleetDataItem.gradeForSorting = fleetDataItem.inspection.idwalGrade;
            }

            gradedData.push(fleetDataItem);
            return;
        }

        if (aiGradingActive && fleetDataItem.aiGrades?.predictedGrade) {
            fleetDataItem.gradeForSorting = fleetDataItem.aiGrades.predictedGrade;
            gradedData.push(fleetDataItem);
            return;
        }

        noGradingData.push(fleetDataItem);
    });

    gradedData.sort((a: FleetDataItem, b: FleetDataItem) => {
        return b.gradeForSorting! - a.gradeForSorting!;
    });


    gradedData.forEach((item: FleetDataItem) => {
        item.rank = rankCounter;

        rankCounter = rankCounter + 1;
    });

    noGradingData.forEach((item: FleetDataItem) => {
        item.rank = undefined;
    });

    return gradedData.concat(noGradingData);
}

export const setGlobalFilterFields = (data: FleetDataItem[]) => {
    try {
        data.forEach((fleetDataItem: FleetDataItem) => {
            if (fleetDataItem.vesselGrade) {
                fleetDataItem.aiGrades!.aiGradeFilterField = fleetDataItem.vesselGrade.gradeFilterField;
            }

            if (fleetDataItem.inspection?.daysSince && fleetDataItem.inspection.dateOfInspection) {
                fleetDataItem.inspection.dateOfInspectionFilterField = fleetDataItem.inspection.dateOfInspection.toLocaleDateString("en-GB");
            }

            if (fleetDataItem.inspection?.location && fleetDataItem.inspection.isScheduled) {
                fleetDataItem.inspection.locationFilterField = `${fleetDataItem.inspection.location.name + ", " + fleetDataItem.inspection.location.country.name}`;
            }
        });
    } catch(e: any) {
        // Catching the exception here stops the above code crashing the entire table if an exception is thrown.
        console.error(e);
        console.error("Exception when attempting to set up global filter fields.");
    }
}

/**
 * Converts a date string in the DD/MM/YYY format into a javasciurpt date
 * @param props
 * @returns
 */
export function convertToDateObject(date?: any) {
    if (!date) {
        return null
    }

    // This is becasue of inconsistent typings 
    if (date instanceof Date) {
        return date
    }

    const [day, month, year] = date.split('/').map(Number);

    return new Date(year, month - 1, day); // Month is 0-indexed
}

export const getDistinctItems = (items: string[]) => 
    uniq(items).filter(Boolean).sort((a, b) => a.localeCompare(b));